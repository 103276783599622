export const KeyMap = {
    // ---- Dev Features --- //
    DEV_FEATURE_SHOULD_VALIDATE_IFRAME_HTML: 'DEV_FEATURE_SHOULD_VALIDATE_IFRAME_HTML',
    // ----- Data Source Features  -------- //
    KwImageSrc: 'KwImageSrc',
    RgBenefitBadgeText: 'RgBenefitBadgeText',
    SERPListingsConfig: 'SERPListingsConfig',
    SERPNativeAdConfig: 'SERPNativeAdConfig',
    SERPPreListingsConfig: 'SERPPreListingsConfig',
    JTSERPListingConfig: 'JTSERPListingConfig',
    BenefitList: 'BenefitList',
    LanderTitle: 'LanderTitle',
    CTAStyles: 'CTAStyles',
    CTAText: 'CTAText',
    NEW_FALLBACK_LOGIC: 'NEW_FALLBACK_LOGIC',
    // ----- Generic Features ------ //
    HideZipPage: 'HideZipPage',
    SkipReg2Page: 'SkipReg2Page',
    ShowSkipConfirmInterstitial: 'ShowSkipConfirmInterstitial',
    HideJobCategoryQuestion: 'HideJobCategoryQuestion',
    ShowJobCategoryTest: 'ShowJobCategoryTest',
    JobTypeAsKeywordIfEmpty: 'JobTypeAsKeywordIfEmpty',
    // ------ AbTest Features ------------//
    BLMailSuffixes: 'BLMailSuffixes',
    EduQuestionTest: 'EduQuestionTest',
    ListingsAsLandingFlow: 'ListingsAsLandingFlow',
    UpdatableABTChannel1: 'UpdatableABTChannel1',
    UpdatableABTChannel2: 'UpdatableABTChannel2',
    KwBenefitsLander: 'KwBenefitsLander',
    DxmlExtFlow: 'DxmlExtFlow',
    AmazonBrandFlowV2: 'AmazonBrandFlowV2',
    KwJtConfigIntegration: 'KwJtConfigIntegration',
    LeaveBehindTestV2: 'LeaveBehindTestV2',
    ShowStaticJL: 'ShowStaticJL',
    ShowTipBelowSERP: 'ShowTipBelowSERP',
    ListingEmailAlerts: 'ListingEmailAlerts',
    EmailPlacementTest: 'EmailPlacementTest',
    KwAsListingsLander: 'KwAsListingsLander',
    SummerJobsLogo: 'SummerJobsLogo',
    EnableJtKwMappingForJobCategorySurvey: 'EnableJtKwMappingForJobCategorySurvey',
    ZipAndEmailLanding: 'ZipAndEmailLanding',
    UpwardZipInEmail: 'UpwardZipInEmail',
    DisableExitInterstitial: 'DisableExitInterstitial',
    SkipToOffersNewTab: 'SkipToOffersNewTab',
    PrePopFlowV2: 'PrePopFlowV2',
    RepeatUserListingsBg: 'RepeatUserListingsBg',
    RgBenefitStrip: 'RgBenefitStrip',
    JobsZipOptimized: 'JobsZipOptimized',
    KwdDropdownLander: 'KwdDropdownLander',
    RgStaticKwImage: 'RgStaticKwImage',
    OfferPathHeaderDesignTest: 'OfferPathHeaderDesignTest',
    OfferPathNewUITest: 'OfferPathNewUITest',
    GenderOptimizeTest: 'GenderOptimizeTest',
    FulfillmentPageDesignTest: 'FulfillmentPageDesignTest',
    PIITest: 'PIITest',
    PIITestForPPR: 'PIITestForPPR',
    EduQuestion: 'EduQuestion',
    DriverOfferPathTargeting: 'DriverOfferPathTargeting',
    MultipleOfferPath: 'MultipleOfferPath',
    MultiBrandKWLogo: 'MultiBrandKWLogo',
    ListingsJobDedup: 'ListingsJobDedup',
    SMSRedirectTest: 'SMSRedirectTest',
    PhoneNoDeepValidation: 'PhoneNoDeepValidation',
    FbBgFlow: 'FbBgFlow',
    FbGoogleEmailInterstitial: 'FbGoogleEmailInterstitial',
    SkipToOfferPathSources: 'SkipToOfferPathSources',
    SkipToOfferPathChannel: 'SkipToOfferPathChannel',
    OldUserSkipToOfferPath: 'OldUserSkipToOfferPath',
    GoogleDisplaySkipToOfferPath: 'GoogleDisplaySkipToOfferPath',
    TaboolaLander: 'TaboolaLander',
    OldUserJobcaseOffer: 'OldUserJobcaseOffer',
    NotifyZipToOffers: 'NotifyZipToOffers',
    InterstitialBetweenOfferPath: 'Interstitial Between Offer Path',
    Back2ListingsMidPathRedirection: 'back2ListingsMidPathRedirection',
    AllInboxEmailLander: 'AllInboxEmailLander',
    RegThree: 'RegThree',
    GoogleDisplayKw: 'GoogleDisplayKw',
    RgKwNewFlow: 'RgKwNewFlow',
    WfhAmazon: 'WfhAmazon',
    MarketingPartners: 'MarketingPartners',
    TCPAMarketingPartners: 'TCPAMarketingPartners',
    TCPMarketingPartnersExtra: 'TCPAMarketingPartnersExtra',
    BaristaStarbucksKw: 'BaristaStarbucksKw',
    ListingsInBg: 'ListingsInBg',
    ListingsInBgHybridSurveyFlow: 'ListingsInBgHybridSurveyFlow',
    ListingsInBgHybridOffersFlow: 'ListingsInBgHybridOffersFlow',
    ListingsInBgMidpathOffers: 'ListingsInBgMidpathOffers',
    ListingsInBgPrelanderKw: 'ListingsInBgPrelanderKw',
    ListingsBgXmlTest: 'ListingsBgXmlTest',
    GoogleProgressBarTest: 'GoogleProgressBarTest',
    ListingsInBgWithZipUpwardOpt: 'ListingsInBgWithZipUpwardOpt',
    AIBEMJobCategoryTest: 'AIBEMJobCategoryTest',
    NoSkipToOffersTest: 'NoSkipToOffersTest',
    ListingsBgXmlStaticImageTest: 'ListingsBgXmlStaticImageTest',
    ListingsBgXmlTestMobileImage: 'ListingsBgXmlTestMobileImage',
    ListingsBgXmlTestTabletImage: 'ListingsBgXmlTestTabletImage',
    ListingsBgXmlTestWebImage: 'ListingsBgXmlTestWebImage',
    PushNotifier: 'PushNotifier',
    AIBEMLander: 'AIBEMLander',
    SurveyQueVaraition: 'SurveyQueVaraition',
    JobcaseCoregUI: 'JobcaseCoregUI',
    RgKeyword: 'RgKeyword',
    XMLPartTimeBG: 'XMLPartTimeBG',
    RepeatUser: 'RepeatUser',
    FBSkipToOffers: 'FBSkipToOffers',
    KWSelectorRGLandingPageTest: 'KWSelectorRGLandingPageTest',
    GoogleKWSelectorLandingPage: 'GoogleKWSelectorLandingPage',
    GoogleKWSelectorLanderMapping: 'GoogleKWSelectorLanderMapping',
    Reg2Optimize: 'Reg2Optimize',
    PrePopKWSelectorLanderTest: 'PrePopKWSelectorLanderTest',
    UpwardEmailAndRegPageOptimization: 'UpwardEmailAndRegPageOptimization',
    AllInboxSMSLander: 'AllInboxSMSLander',
    SMSLanderJTMapping: 'SMSLanderJTMapping',
    RGDropdownLander: 'RGDropdownLander',
    XMLListingBgSkipWithoutZip: 'XMLListingBgSkipWithoutZip',
    EduQuestionVar: 'EduQuestionVar',
    AWKSMultiBrandLander: 'AWKSMultiBrandLander',
    AWKSMultiBrandLanderLogos: 'AWKSMultiBrandLanderLogos',
    SeniorJobsFacebook: 'SeniorJobsFacebook',
    SeniorJobsFacebookWithoutZip: 'SeniorJobsFacebookWithoutZip',
    MultiBrandLander: 'MultiBrandLander',
    MultiBrandLanderLogos: 'MultiBrandLanderLogos',
    MultiBrandKwOptions: 'MultiBrandKwOptions',
    UpwardEmailOptInTest: 'UpwardEmailOptInTest',
    ListingsInBGWithWhiteBGTest: 'ListingsInBGWithWhiteBGTest',
    EventsToTrack: 'EventsToTrack',
    SnapchatMultiBrand: 'SnapchatMultiBrand',
    SnapchatMultiBrandLogoList: 'SnapchatMultiBrandLogoList',
    ArrowForResultsGeneration: 'ArrowForResultsGeneration',
    OnlineJobsToWorkFromHomeForGoogle: 'OnlineJobsToWorkFromHomeForGoogle',
    ForceFeedToUpdateWith: 'ForceFeedToUpdateWith',
    PushOptInPlacementTest: 'PushOptInPlacementTest',
    PushOptInPlacement: 'PushOptInPlacement',
    StarbucksKwToExclude: 'StarbucksKwToExclude',
    AmericanAirlinesRG: 'AmericanAirlinesRG',
    OvalCta: 'OvalCta',
    AmericanAirlinesRgOptions: 'AmericanAirlinesRgOptions',
    AmericanAirlinesRgIcons: 'AmericanAirlinesRgIcons',
    FbclidReplacement: 'FbclidReplacement',
    EDUPartners: 'EDUPartners',
    FulfilmentPageListings: 'FulfilmentPageListings',
    HideReg1Skip: 'HideReg1Skip',
    FulfillmentPageNewDesign: 'FulfillmentPageNewDesign',
    SkipBlack: 'SkipBlack',
    ListingsBgRegThreeV1: 'ListingsBg-RegThree-V1',
    ListingsBgRegThreeV2: 'ListingsBg-RegThree-V2',
    Reg2OptMobile: 'Reg2OptMobile',
    Reg2GreyedCTA: 'Reg2GreyedCTA',
    GoogleDisplayLander: 'GoogleDisplayLander',
    UpwardBottomAlignedInterstitial: 'UpwardBottomAlignedInterstitial',
    ExclusionZipTest: 'ExclusionZipTest',
    JobCategoryQuestionNeuvooCreative: 'JobCategoryQuestionNeuvooCreative',
    SkipControlFlow: 'SkipControlFlow',
    AmericanAirlinesMultiLogo: 'AmericanAirlinesMultiLogo',
    GroceryStoreVariant: 'GroceryStoreVariant',
    FulfillmentDesignPhaseTwo: 'FulfillmentDesignPhaseTwo',
    ProfileCTALander: 'ProfileCTALander',
    SkipToOfferNewTabReg2: 'SkipToOfferNewTabReg2',
    SkipToOfferNeTabPGMTCAndBing: 'SkipToOfferNeTabPGMTCAndBing',
    PrePopUpdatedLandingPage: 'PrePopUpdatedLandingPage',
    PrePopUpdatedReg2: 'PrePopUpdatedReg2',
    PremiumJobProgressBar: 'PremiumJobProgressBar',
    ChangeCtaForUpward: 'ChangeCtaForUpward',
    RemoveLogoFromReg2: 'RemoveLogoFromReg2',
    GovernmentLogo: 'GovernmentLogo',
    GovernmentLogoAwks: 'GovernmentLogoAwks',
    UpwardGovernment: 'UpwardGovernment',
    SurveyPathHeader: 'SurveyPathHeader',
    RegisteredUserSkipReg1: 'RegisteredUserSkipReg1',
    AmazonBrandFlow: 'AmazonBrandFlow',
    FedExBrandFlow: 'FedExBrandFlow',
    SkipRegPages: 'SkipRegPages',
    SkipPositioningTest: 'SkipPositioningTest',
    RgJtKwTest: 'RgJtKwTest',
    FacebookHalloweenJobs: 'FacebookHalloweenJobs',
    DisableBackButtonBehaviourListingsPage: 'DisableBackButtonBehaviourListingsPage',
    UpwardRevampTest: 'UpwardRevampTest',
    CtaRegTick: 'CtaRegTick',
    WarmUpQuestionTest: 'WarmUpQuestionTest',
    WarmUpTestAnswers: 'WarmUpTestAnswers',
    XmlPopUp: 'XmlPopUp',
    ListingsBGDesktopModelChange: 'ListingsBGDesktopModelChange',
    EmailOnLanderTest: 'EmailOnLanderTest',
    MotiveInteractiveTest: 'MotiveInteractiveTest',
    MotiveInteractiveTestOS: 'MotiveInteractiveTestOS',
    NeuvooWidgetTest: 'NeuvooWidgetTest',
    PrePopReg1AndReg2CollapsedFields: 'PrePopReg1AndReg2CollapsedFields',
    PuzzSurveyVariation: 'PuzzSurveyVariation',
    RestrictNotificationConfig: 'RestrictNotificationConfig',
    TaboolaAmazonLander: 'TaboolaAmazonLander',
    GoogleSSOLogin: 'GoogleSSOLogin',
    DxmlExtNewLander: 'DxmlExtNewLander',
    SourceListForTCPARegisteredUsers: 'SourceListForTCPARegisteredUsers',
    AmazonBrandedInterstitial: 'AmazonBrandedInterstitial',
    HideCheckBoxOptSkipLink: 'HideCheckBoxOptSkipLink',
    APIRepeatUserFlow: 'APIRepeatUserFlow',
    MJCEmailOptin: 'MJCEmailOptin',
    BrandLanderTest: 'BrandLanderTest',
    BrandLanderAdUnitId: 'BrandLanderAdUnitId',
    BrandCompanyNameForUpwardApi: 'BrandCompanyNameForUpwardApi',
    BrandCID: 'BrandCID',
    BrandLanderUI: 'BrandLanderUI',
    BrandLanderV2: 'BrandLanderV2',
    BrandLanderV2UI: 'BrandLanderV2UI',
    SkipLanderWhenFailed: 'SkipLanderWhenFailed',
    BrandCIDForUpwardApi: 'BrandCIDForUpwardApi',
    NeuvooWidgetCPCFloor: 'NeuvooWidgetCPCFloor',
    GenericKeywordLander: 'GenericKeywordLander',
    GenericKeywordMap: 'GenericKeywordMap',
    NeuvooWidgetAdUnit: 'NeuvooWidgetAdUnit',
    DisplayJobsLogoAwks39: 'DisplayJobsLogoAwks39',
    GovernmentLogoAwks39: 'GovernmentLogoAwks39',
    AirportLogoAwks39: 'AirportLogoAwks39',
    HardRedirectOnInit: 'HardRedirectOnInit',
    HardRedirectOnInitLink: 'HardRedirectOnInitLink',
    GoogleSSOClientId: 'GoogleSSOClientId',
    SetS2oFlag: 'SetS2oFlag',
    OlduserTCPAoptin: 'OlduserTCPAoptin',
    SERPNativeAd: 'SERPNativeAd',
    SERPNativeIframeSrc: 'SERPNativeIframeSrc',
    RemoveLogoRegScreen: 'RemoveLogoRegScreen',
    LoaderInterstitialTest: 'LoaderInterstitialTest',
    UpwardRemoveGenderField: 'UpwardRemoveGenderField',
    MergedListingsSources: 'MergedListingsSources',
    ListingsBGRemoveGenderField: 'ListingsBGRemoveGenderField',
    LeaveBehindDxmlExtInterstitial: 'LeaveBehindDxmlExtInterstitial',
    NJSEmailOptin: 'NJSEmailOptin',
    NJSPhoneOptIn: 'NJSPhoneOptIn',
    JobTitleLogoList: 'JobTitleLogoList',
    SkipOnRegHeader: 'SkipOnRegHeader',
    ControlFlowRemoveGenderField: 'ControlFlowRemoveGenderField',
    RemoveExitInterstitial: 'RemoveExitInterstitial',
    EmailPreferencePage: 'EmailPreferencePage',
    CTAVariationBlue: 'CTAVariationBlue',
    SkipOnUpwardPageOptimization: 'SkipOnUpwardPageOptimization',
    VerizonLander: 'VerizonLander',
    WarmUpWithoutZip: 'WarmUpWithoutZip',
    RegThreeUIV2: 'RegThreeUIV2',
    JobAlertSurveyPath: 'JobAlertSurveyPath',
    TaboolaKeywordSpecificLanderTrucker: 'TaboolaKeywordSpecificLanderTrucker',
    TaboolaKeywordSpecificLanderProduct: 'TaboolaKeywordSpecificLanderProduct',
    TaboolaKeywordSpecificLanderAmazon: 'TaboolaKeywordSpecificLanderAmazon',
    GoogleOnlineJobsLander: 'GoogleOnlineJobsLander',
    WarmUpHeaderTitle: 'WarmUpHeaderTitle',
    WarmUpDisplayHourlyPay: 'WarmUpDisplayHourlyPay',
    DynamicMultiBrandKWLogo: 'DynamicMultiBrandKWLogo',
    GoogleSeniorJobsLander: 'GoogleSeniorJobsLander',
    WarmUpWithinWeek: 'WarmUpWithinWeek',
    PhoneNoPlacementTest: 'PhoneNoPlacementTest',
    DisregardJobType: 'DisregardJobType',
    DisableBackButtonListings: 'DisableBackButtonListings',
    AggressiveRegPgHeading: 'AggressiveRegPgHeading',
    AggressiveHeadersList: 'AggressiveHeadersList',
    ListingsBgStaticImages: 'ListingsBgStaticImages',
    ListingsBgStaticImageTest: 'ListingsBgStaticImageTest',
    Reg3BrandedLander: 'Reg3BrandedLander',
    QueryParamChangeAdvertisers: 'QueryParamChangeAdvertisers',
};
