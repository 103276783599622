import Constants from 'common/constants';
import memoryStoreKeys from 'common/memoryStoreKeys';
import memoryStore from 'dataStore/memoryStore';
import {
    getFulfilmentMergedSourceAuIds,
    getFulfilmentPageListingsAuId,
} from 'helpers/featureValueHelpers/featureValueHelpers';
import AdsDataSource from 'datasource/adsDataSource';
import TargetingManager from 'targetingManager';
import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import JobSearchDataSource from 'datasource/jobsSearchDataSource';
import { FeatureValueHelper } from 'helpers/index';

const stringify = require('@bugsnag/safe-json-stringify');

const uuidv4 = require('uuid/v4');

export const pickAdvertisersDataForJobResults = advIds => {
    const jobSourceMetaData = memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA);

    return jobSourceMetaData.filter(jobMeta => {
        return advIds.includes(jobMeta.advid);
    });
};
export const setupAdSourceForSingleSource = source => {
    const advMockMap = Constants.ADVERTISERS.DATA;
    const adMockData = advMockMap[source.toUpperCase()];

    const urlAdUuid = uuidv4().replace(/-/g, '');
    const advId = adMockData.ID;
    const advIds = memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS) || [];

    if (!advIds.includes(advId)) {
        memoryStore.setItem(memoryStoreKeys.JOB_AD_SOURCE_IDS, [...advIds, advId]);
    }

    const JobAdSourceIds = memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP) || {};
    memoryStore.setItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP, {
        ...JobAdSourceIds,
        [advId]: urlAdUuid,
    });

    const jobAdMetaData = memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
    const jobAdMetaDataObject = {};
    [...jobAdMetaData, { advid: advId, uuid: urlAdUuid }].map(
        jobData => (jobAdMetaDataObject[jobData.advid] = jobData),
    );

    memoryStore.setItem(memoryStoreKeys.JOB_AD_META_DATA, Object.values(jobAdMetaDataObject));

    if (adMockData) {
        const jobAdAu = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU) || {};
        memoryStore.setItem(memoryStoreKeys.JOB_AD_AU, {
            ...jobAdAu,
            [advId]: {
                advId: adMockData.ID,
                advertiserName: adMockData.NAME,
                customAdInfo: { adSource: source },
                campaignName: adMockData.CAMPAIGN_NAME,
                campaignId: adMockData.CAMPAIGN_ID,
                revType: adMockData.REVENUE_TYPE,
            },
        });
    } else {
        memoryStore.setItem(memoryStoreKeys.JOB_AD_AU, {});
    }
};

export const fetchAdvertiser = async adUnitId => {
    try {
        if (!adUnitId) throw new Error('Ad Unit Id not configured for job listings');
        const targetingParams = TargetingManager.fetchTargetingParams();

        const adUnitData = await AdsDataSource.fetchAdsForAdUnit(targetingParams, adUnitId);

        if (adUnitData && adUnitData.ad && !Utils.isEmptyObj(adUnitData.ad)) {
            const { customAdInfo = {}, advId } = adUnitData.ad;

            const { forwardAdMetaData, ...rest } = customAdInfo;
            const adSource = customAdInfo.adSource;
            console.log('[Jobs][AdUnit]Debug', adUnitId, 'resolved to', adUnitData.ad);
            const bid = Utils.getObjVal(adUnitData, ['ad', 'bid']);

            if (advId) {
                return {
                    source: adSource,
                    advId,
                    ad: adUnitData.ad,
                    meta: {
                        ...(bid ? { bid: bid } : {}),
                        uuid: uuidv4().replace(/-/g, ''),
                        advid: advId,
                        ...(forwardAdMetaData ? rest : {}),
                    },
                };
            }
        } else {
            console.error(
                `Ad source could not be determined from ad unit call: ${stringify(adUnitData)}}`,
            );
        }
    } catch (err) {
        console.error(`Error while fetching AdUnit for Fulfilment Listings - `, err);
    }
};

export const setupAdSourcesForJTJobsAdvertisers = async () => {
    try {
        const adSourcesResponses = (
            await Promise.allSettled(
                FeatureValueHelper.getJTSERPListingsConfig().map(async conf => ({
                    ...(await fetchAdvertiser(conf.id)),
                    auId: conf.id,
                    position: conf.position,
                })),
            )
        ).map(item => item.value);
        const jtJobsAdvIds = [];
        const adSources = memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS);
        const adSourceAus = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU);
        const adSourceMeta = memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
        const advUuidMap = memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP);

        const dupeMap = adSources.reduce((acc, curr) => {
            acc[curr] = true;

            return acc;
        }, {});

        adSourcesResponses.forEach(resp => {
            resp && resp.advId && jtJobsAdvIds.push(resp.advId);

            if (resp && resp.advId && !dupeMap[resp.advId]) {
                const { ad, meta, advId } = resp;

                if (ad && ad.dealId) {
                    meta['dealId'] = ad.dealId;
                }
                dupeMap[advId] = true;
                adSourceAus[advId] = ad;
                adSourceMeta.push(meta);
                advUuidMap[advId] = meta.uuid;
            }
        });

        memoryStore.setItem(memoryStoreKeys.JT_JOB_AD_INFO_DATA, adSourcesResponses);
        memoryStore.setItem(memoryStoreKeys.JOB_AD_AU, adSourceAus);
        const jobAdMetaDataObject = {};
        adSourceMeta.map(jobData => (jobAdMetaDataObject[jobData.advid] = jobData));
        memoryStore.setItem(memoryStoreKeys.JOB_AD_META_DATA, Object.values(jobAdMetaDataObject));
        memoryStore.setItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP, advUuidMap);
    } catch (e) {
        console.error('[JTJobs]: Error setting up JT Job Advertisers', e);

        return Promise.resolve();
    }
};

export const fetchJTJobs = async (jt, zip) => {
    try {
        const jobsArray = [];
        const jtJobAdInfoData = memoryStore.getItem(memoryStoreKeys.JT_JOB_AD_INFO_DATA);

        const advJobMapping = {};
        jtJobAdInfoData.forEach(conf => {
            const { advId, position } = conf;

            if (!advId) return;

            if (!advJobMapping[advId]) {
                advJobMapping[advId] = [position];
            } else {
                advJobMapping[advId].push(position);
            }
        });

        const jobResultsMap = {};
        let errorMap = {};

        await Promise.allSettled(
            Object.keys(advJobMapping).map(async advId => {
                const positions = advJobMapping[advId];
                const query = JobSearchDataSource.createJobsQueryForTerm({
                    term: jt,
                    pageNume: 1,
                    pageSize: positions.length,
                    location: zip,
                });

                const jobsQuery = {
                    ...query,
                    advertisers: pickAdvertisersDataForJobResults([advId]),
                };

                try {
                    const jobsData = await JobSearchDataSource.fetchJobsDataForQueryMultiSource(
                        jobsQuery,
                    );

                    const jobs = Utils.getObjVal(jobsData, ['jobs']) || [];
                    const erros = Utils.getObjVal(jobsData, ['error']) || {};

                    jobResultsMap[advId] = jobs;
                    errorMap = { ...errorMap, ...erros };
                } catch (e) {
                    errorMap[advId] = 'Error Fetching Job';
                }
            }),
        );

        Object.keys(advJobMapping).forEach(advId => {
            let i = 0;
            const positions = advJobMapping[advId];
            const jobs = jobResultsMap[advId] || [];
            positions.forEach(pos => {
                if (jobs[i]) {
                    jobsArray[pos] = { ...jobs[i], cat: 'jt' };
                    i++;
                }
            });
        });

        return [jobsArray, errorMap];
    } catch (e) {
        console.error('[JTJobs]: Error fetching JT Jobs', e);

        return [];
    }
};

export const selectJobSourceAdvertiser = async () => {
    const adUnitId = getFulfilmentPageListingsAuId() || '';
    const auIds = getFulfilmentMergedSourceAuIds();

    const urlAdSource = Utils.getValForKeyFromCurrentUrl('source');
    let jobAdsSource = 'upward';

    if (
        [
            Constants.ADVERTISERS.TALROO,
            Constants.ADVERTISERS.UPWARD,
            Constants.ADVERTISERS.APPCAST,
            Constants.ADVERTISERS.ZIPRECRUITER,
        ].includes(urlAdSource)
    ) {
        await Promise.resolve();
        console.log('[Jobs] Got Source from URL', urlAdSource);
        jobAdsSource = urlAdSource;
        setupAdSourceForSingleSource(urlAdSource);
    } else {
        console.log('[Jobs] Firing AUs', auIds);

        const singleAuResp = await fetchAdvertiser(adUnitId);
        // Set Job Ad Source across other JobResults

        if (singleAuResp && singleAuResp.source) {
            jobAdsSource = singleAuResp.source;
            memoryStore.setItem(memoryStoreKeys.SINGLE_JOB_AD_AU, singleAuResp && singleAuResp.ad);
        } else {
            jobAdsSource = Constants.ADVERTISERS.UPWARD;
        }

        // Set Job Ad Sources for job results
        const adSourcesResponses =
            auIds && auIds.length
                ? (await Promise.allSettled(auIds.map(fetchAdvertiser))).map(item => item.value)
                : [singleAuResp];

        const adSourceIds = [];
        const adSourceAus = {};
        const adSourceMeta = [];
        const advUuidMap = {};
        const dupeMap = {};
        adSourcesResponses.forEach(resp => {
            if (resp && resp.advId && !dupeMap[resp.advId]) {
                const { ad, meta, advId } = resp;

                if (ad && ad.dealId) {
                    meta['dealId'] = ad.dealId;
                }
                dupeMap[advId] = true;
                adSourceIds.push(advId);
                adSourceAus[advId] = ad;
                adSourceMeta.push(meta);
                advUuidMap[advId] = meta.uuid;
            }
        });

        if (adSourceIds.length) {
            memoryStore.setItem(memoryStoreKeys.JOB_AD_SOURCE_IDS, adSourceIds);
            const jobAddAU = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU) || {};
            memoryStore.setItem(memoryStoreKeys.JOB_AD_AU, { ...jobAddAU, ...adSourceAus });

            const jobAdMetaData = memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA) || [];
            const jobAdMetaDataObject = {};
            [...jobAdMetaData, ...adSourceMeta].map(
                jobData => (jobAdMetaDataObject[jobData.advid] = jobData),
            );

            memoryStore.setItem(
                memoryStoreKeys.JOB_AD_META_DATA,
                Object.values(jobAdMetaDataObject),
            );
            const jobAddUuidMap =
                memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP) || {};
            memoryStore.setItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP, {
                ...jobAddUuidMap,
                ...advUuidMap,
            });
        } else {
            // Fallback ad source
            setupAdSourceForSingleSource(Constants.ADVERTISERS.UPWARD);
        }
    }

    console.log(
        '[Jobs] Payload',
        memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS),
        memoryStore.getItem(memoryStoreKeys.JOB_AD_AU),
        memoryStore.getItem(memoryStoreKeys.JOB_AD_META_DATA),
        memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP),
    );

    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_ADS_SOURCE, jobAdsSource);

    return jobAdsSource;
};
