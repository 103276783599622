import Constants from 'common/constants';
import EventManager from 'eventManager/eventManager';
import Utils from 'common/utils';

// ------   Helpers ------- //
const _getBaseAdEventInfo = ad => {
    return {
        advid: ad.advId,
        advnm: ad.advertiserName,
        cmpid: ad.campaignId,
        cmpnm: ad.campaignName,
    };
};

const _getJobInfoEventInfo = job => {
    return {
        adt: job.jobtitle || job.title || '',
        adst: job.company,
        adst2: (job.description ? job.description : '').substring(0, 200),
    };
};

// ------ Exports ------ //
export const fireAdImpressionEvent = (ad, eventType, extraData = {}) => {
    EventManager.sendEvent(
        Constants.EVENT.ADS_IMP,
        {
            ..._getBaseAdEventInfo(ad),
            ...extraData,
        },
        eventType,
    );
};

export const fireSlotImpVEvent = (ad, job, eventType, extraData = {}) => {
    const jobEventInfo = job ? _getJobInfoEventInfo(job, ad.advertiserName) : {};
    EventManager.sendEvent(
        Constants.EVENT.AD_IMP_V,
        {
            ..._getBaseAdEventInfo(ad),
            ...jobEventInfo,
            ...extraData,
        },
        eventType,
    );
};

export const fireSlotFailedEvent = (ad, errorMessage, eventType, extraData = {}) => {
    const eventData = {
        ..._getBaseAdEventInfo(ad),
        error: errorMessage,
        ...extraData,
    };

    if (ad.customAdInfo && ad.customAdInfo.cpa) {
        eventData.cpa = ad.customAdInfo.cpa;
    }
    EventManager.sendEvent(Constants.EVENT.AD_FAILED, eventData, eventType);
};

export const fireAdClickEvent = (ad, job, eventType, extraData = {}, url) => {
    const jobEventInfo = job ? _getJobInfoEventInfo(job, ad.advertiserName) : {};
    const useBid = Utils.getObjVal(ad, ['customAdInfo', 'useBid']);
    const eventData = {
        ..._getBaseAdEventInfo(ad),
        ...jobEventInfo,
        ...extraData,
    };

    if (ad.customAdInfo.cpa) {
        eventData.cpa = ad.customAdInfo.cpa;
    }
    EventManager.sendEvent(
        Constants.EVENT.AD_CLICK,
        { ...eventData, job_redirect_url: url },
        eventType,
    );
    EventManager.sendEvent(
        Constants.EVENT.LANDING_AD_CLICK,
        eventData,
        Constants.EVENT_TYPE.LANDING,
    );
    // Use customAdInfo data to Add Bid Variation below
    useBid === '1' &&
        EventManager.sendEvent(
            Constants.EVENT.REVENUE,
            {
                ervn: job ? job.bid : ad.bid,
                ...eventData,
            },
            eventType,
        );
};

export const fireAdProgEvent = (ad, job, eventType, extraData = {}) => {
    const eventData = {
        ..._getBaseAdEventInfo(ad),
        ...(job ? _getJobInfoEventInfo(job, ad.advertiserName) : {}),
        ...extraData,
    };
    EventManager.sendEvent(Constants.EVENT.AD_PROG, eventData, eventType);
};
