import React, { useState } from 'react';
import EmailRegistration from './emailRegistration';
import PhoneRegistration from './phoneRegistration';
import EventManager from 'eventManager/eventManager';
import Constants from 'common/constants';
import RouterPrefixHandler from 'common/routePrefixHandler';

export const UserInfoInterstitial = props => {
    const [currentForm, setCurrentForm] = useState('email');
    let form = currentForm;
    let currentInterstitial = null;

    if (form === 'email') {
        if (!props.emailSubbed) {
            currentInterstitial = <EmailRegistration setCurrentForm={setCurrentForm} />;
        } else {
            setCurrentForm('phone');
            form = 'phone';
        }
    }

    if (form === 'phone') {
        if (!props.phoneSubbed) {
            currentInterstitial = (
                <PhoneRegistration
                    handlePopClose={props.handlePopClose}
                    setCurrentForm={setCurrentForm}
                />
            );
        } else {
            setCurrentForm('close');
        }
    }

    const handleCrossClick = () => {
        if (currentForm === 'email') {
            setCurrentForm('phone');
            EventManager.sendEvent(
                Constants.EVENT.EMAIL_INTERSTITIAL_CLOSE,
                {},
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
        } else {
            props.handlePopClose();
            EventManager.sendEvent(
                Constants.EVENT.PHONE_INTERSTITIAL_CLOSE,
                {},
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
        }
    };

    return (
        <>
            <div className="mjc-pop-logo">
                <picture>
                    <source
                        srcSet={RouterPrefixHandler.getImagePath('/images/newJobScanner-logo.png')}
                        type="image/png"
                    />
                    <img
                        alt="newJobScanner_logo"
                        className="TopBar-topBarImg-3"
                        src={RouterPrefixHandler.getImagePath('/images/newJobScanner-logo.png')}
                    />
                </picture>
                <div>
                    <div className="close" onClick={handleCrossClick}>
                        <svg
                            height="8"
                            viewBox="0 0 8 8"
                            width="8"
                            xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fillRule="evenodd">
                                <g fill="#333" fillRule="nonzero">
                                    <g>
                                        <g>
                                            <path
                                                d="M.411 7.976c-.105 0-.21-.04-.29-.12-.16-.16-.16-.42 0-.58L7.277.12c.16-.16.42-.16.58 0 .16.16.16.42 0 .58L.702 7.857c-.08.08-.186.12-.29.12z"
                                                transform="translate(-287 -140) translate(15 130) translate(272 10)"
                                            />
                                            <path
                                                d="M7.567 7.976c-.105 0-.21-.04-.29-.12L.121.701c-.16-.16-.16-.42 0-.58.16-.161.42-.161.58 0l7.156 7.155c.16.16.16.42 0 .58-.08.08-.185.12-.29.12z"
                                                transform="translate(-287 -140) translate(15 130) translate(272 10)"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            {currentInterstitial}
        </>
    );
};
