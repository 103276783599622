import {
    getFeatureValueJsonByCurrentScope,
    getFeatureValuesByCurrentScope,
    getFeatureValues,
    getFeatureValueJson,
    getFeatureMapValuesByCurrentScope,
} from './getFeatureValues';
import { FeatureMap } from 'providers';
import Utils from 'common/utils';
import { MacrosHelper } from 'helpers';
import Constants from 'common/constants';

// ------- Helpers ------- //
const _filterInvalidSERPListings = listings =>
    listings.filter(item => {
        if (
            !Utils.isNull(item) &&
            !Utils.isNull(item.id) &&
            !Utils.isNull(item.position) &&
            !Utils.isNull(item.page)
        ) {
            return true;
        } else {
            console.error('Config: Invalid SERP listing configured', item);

            return false;
        }
    });

const _filterInvalidJTSERPListingsConf = listings =>
    listings.filter(item => {
        if (!Utils.isNull(item) && !Utils.isNull(item.id) && !Utils.isNull(item.position)) {
            return true;
        } else {
            console.error('Config: Invalid JT SERP listing configured', item);

            return false;
        }
    });

// ------- Public Exports --------- //
/**
 * @name getSERPListingsConfig
 * @description Gets Configuration for SERP MarketPlace Config
 * @returns Array of valid Listing
 */
export const getSERPListingsConfig = () =>
    _filterInvalidSERPListings(getFeatureValueJsonByCurrentScope(FeatureMap.SERPListingsConfig));

export const getSERPNativeAdListingsConfig = () =>
    _filterInvalidSERPListings(getFeatureValueJsonByCurrentScope(FeatureMap.SERPNativeAdConfig));

export const getSERPPreListingsConfig = () =>
    _filterInvalidSERPListings(getFeatureValueJsonByCurrentScope(FeatureMap.SERPPreListingsConfig));

export const getFulfilmentPageListingsAuId = () =>
    getFeatureValuesByCurrentScope(FeatureMap.FulfilmentPageListings)[0];

export const getFulfilmentMergedSourceAuIds = () =>
    getFeatureValuesByCurrentScope(FeatureMap.MergedListingsSources) || [];

export const getJTSERPListingsConfig = () =>
    _filterInvalidJTSERPListingsConf(
        getFeatureValueJsonByCurrentScope(FeatureMap.JTSERPListingConfig) || [],
    );

export const getKwImageSrc = () => getFeatureValues(FeatureMap.KwImageSrc)[0];

export const getBenefitList = () => getFeatureValueJson(FeatureMap.BenefitList)[0];

export const getLanderTitle = () => getFeatureValues(FeatureMap.LanderTitle)[0];

export const getCTAStyles = () => getFeatureValueJson(FeatureMap.CTAStyles)[0];

export const getCTAText = () => getFeatureValues(FeatureMap.CTAText)[0];

export const getUpdatableABTChannel1 = () => getFeatureValues(FeatureMap.UpdatableABTChannel1)[0];

export const getUpdatableABTChannel2 = () => getFeatureValues(FeatureMap.UpdatableABTChannel2)[0];

export const getHardRedirectOnInitLink = () =>
    getFeatureValues(FeatureMap.HardRedirectOnInitLink)[0];

export const getSERPNativeIframeSrc = () => {
    const featureValue = getFeatureMapValuesByCurrentScope(FeatureMap.SERPNativeIframeSrc);
    let decodedValue;

    try {
        decodedValue = decodeURIComponent(featureValue);
        const urlParams = Utils.getAllUrlParams(decodedValue);
        let replaceCRParams = MacrosHelper.replaceMacrosWithValues();
        const maxMacros = MacrosHelper.initializeMaxMacros(urlParams);
        replaceCRParams = {
            ...replaceCRParams,
            ...maxMacros,
        };
        const iframeSrc = Utils.matchAndReplaceParams(
            decodedValue,
            replaceCRParams,
            Constants.CR_MAX_UNIFIED_REGEX,
        );

        return iframeSrc;
    } catch (e) {
        console.error('Error decoding native ad Listings src');
    }

    return '';
};

export const getLeadBlackListMailSuffixes = () => {
    return getFeatureValues(FeatureMap.BLMailSuffixes) || [];
};

export const getQueryParamChangeAdvertisers = () =>
    getFeatureValues(FeatureMap.QueryParamChangeAdvertisers) || [];
