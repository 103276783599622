import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import RouterPrefixHandler from 'common/routePrefixHandler';
import RegCTAButtonContainer from 'components/common/regCTAButtonContainer/index';
import flavors, { getFlavor } from 'flavors.macro';
import Utils from 'common/utils';
import cn from 'classnames';
import { AbTestHelper } from 'helpers/index';

flavors();

class TopBar extends React.Component {
    renderRegCTAIcon() {
        return <RegCTAButtonContainer />;
    }

    render() {
        const { classes, className: customCssCls = '' } = this.props;
        const browserSpecificCssCls = ['safari'].includes(Utils.browserType())
            ? classes.safariTopBarFix
            : '';
        const flavor = getFlavor('layout-theme');
        const [, deviceType] = Utils.getDeviceInfo();
        const showIcon =
            AbTestHelper.emailAlertsCTA() && window.location.pathname.includes('listings');

        return (
            <Grid
                alignItems="center"
                className={`${classes.topBar} ${deviceType} ${
                    process.env.REACT_APP_HEADER_LOGO === 'landing-logo' && classes.landingTp
                } top-bar resp-top-bar ${customCssCls} ${browserSpecificCssCls}`}
                container
                direction="row"
                justify="center">
                <div
                    className={cn(classes.wrapper, {
                        [classes.listingsSizing]: showIcon,
                    })}>
                    {this.props.enableFbBgFlow ? (
                        <picture>
                            <source
                                srcSet={RouterPrefixHandler.getImagePath(
                                    `images/myJobsCorner-logo.webp`,
                                )}
                                type="image/webp"
                            />
                            <img
                                alt={`${flavor}_logo`}
                                className={classes.topBarImg}
                                src={RouterPrefixHandler.getImagePath(
                                    `images/myJobsCorner-logo.png`,
                                )}
                            />
                        </picture>
                    ) : (
                        <picture className={classes.topBarImgDiv}>
                            <source
                                srcSet={RouterPrefixHandler.getImagePath(
                                    `images/${process.env.REACT_APP_HEADER_LOGO}.webp`,
                                )}
                                type="image/webp"
                            />
                            <img
                                alt={`${flavor}_logo`}
                                className={classes.topBarImg}
                                height="36"
                                src={RouterPrefixHandler.getImagePath(
                                    `images/${process.env.REACT_APP_HEADER_LOGO}.png`,
                                )}
                                width="170"
                            />
                        </picture>
                    )}
                    {showIcon && this.renderRegCTAIcon()}
                </div>
            </Grid>
        );
    }
}

const styles = theme => ({
    topBar: {
        width: '100%',
        backgroundColor: '#244766',
        maxHeight: '60px',
        padding: '10px',
        height: '100%',
        '@media (max-width: 650px)': {
            backgroundColor: AbTestHelper.googleOnlineJobsLander()
                ? '#f9f9f9 !important'
                : '#244766',
        },
    },
    landingTp: {
        backgroundColor: 'transparent',
    },
    topBarImg: {
        maxHeight: '36px',
        height: 36,
        width: 'auto',
    },
    safariTopBarFix: {
        minHeight: '60px',
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        alignItems: 'center',
    },
    listingsSizing: {
        maxWidth: '1146px',
        [theme.breakpoints.down('1025')]: {
            maxWidth: '730px',
        },
    },
});

TopBar.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
};

export default withStyles(styles)(TopBar);
