import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';
import EventManager from 'eventManager/eventManager';
import * as Constants from 'common/constants';
import TargetingManager from 'targetingManager';
import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import AdsDataSource from 'datasource/adsDataSource';

const getKWTerm = initTerm => {
    return initTerm === 'Jobs near me' || initTerm === '' ? Constants.DEFAULT_KW : initTerm;
};

const getUrlParams = () => {
    const allUrlParams = Utils.getCurrentUrlParams();

    if (Object.keys(allUrlParams).includes('lrdr')) {
        if (allUrlParams['lrdr'].includes('landing')) {
            allUrlParams['lrdr'] = 'landing';
        }

        if (allUrlParams['lrdr'].includes('survey')) {
            allUrlParams['lrdr'] = 'survey';
        }
    }

    return allUrlParams;
};

const getJTVal = () => {
    // Resolved from URL PARAMS
    let { jobType } = Utils.getJobType();

    if (!Utils.isEmptyStr(jobType)) return jobType;

    jobType = IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL);

    if (!Utils.isEmptyStr(jobType)) return jobType;

    jobType = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

    if (!Utils.isEmptyStr(jobType)) return jobType;

    return 'Jobs near me';
};

export const NativeAd = props => {
    const { src } = props;
    const [adUnit, setAdUnit] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const classes = useStyles();
    const jtVal = getJTVal();
    let clickIframe;

    useEffect(() => {
        const allUrlParams = getUrlParams();

        if (!adUnit) {
            const targetingParams = TargetingManager.fetchTargetingParams({
                extraParams: {
                    kw: getKWTerm(jtVal),
                    ...allUrlParams,
                },
                geoParams: {
                    zip: props.term.location || '',
                },
            });
            const adUnitId = props.adUnitId;

            const fetchAdsData = async () => {
                try {
                    let fallbacks = [];

                    for (let i = -1; i < 2; i++) {
                        let adUnitData;

                        if (i < 0) {
                            adUnitData = await AdsDataSource.fetchAdsForAdUnit(
                                targetingParams,
                                adUnitId,
                            );

                            if (adUnitData && adUnitData.fallbacks && adUnitData.fallbacks.length) {
                                fallbacks = adUnitData.fallbacks;
                            }
                        } else if (i < fallbacks.length) {
                            const fb = fallbacks[i];
                            const singleAdUnitData = await AdsDataSource.fetchSingleAdForAdUnit(fb);
                            adUnitData = { ad: singleAdUnitData };
                        } else {
                            break;
                        }

                        if (adUnitData && adUnitData.ad) {
                            setAdUnit(adUnitData);
                            setIsLoading(false);
                        }
                    }
                } catch (err) {
                    console.error(`Error fetching ad unit data: ${err}`);
                    setIsLoading(false);
                }
            };

            fetchAdsData();
        }

        return () => {
            clearInterval(clickIframe);
        };
    }, []);

    const onAdClick = () => {
        if (adUnit && adUnit.ad) {
            const eventData = {
                advid: adUnit.ad.advId,
                advnm: adUnit.ad.advertiserName,
                cmpid: adUnit.ad.campaignId,
                cmpnm: adUnit.ad.campaignName,
            };
            EventManager.sendEvent(
                Constants.EVENT.AD_CLICK,
                { ...eventData, job_redirect_url: 'inaccessible' },
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
            EventManager.sendEventWhenVisible(
                Constants.EVENT.AD_PROG,
                eventData,
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
        }
    };

    const checkFocus = () => {
        if (document.activeElement === document.getElementById('nativeAd')) {
            window.focus();
            onAdClick();
        }
    };

    const onIframeLoaded = () => {
        clickIframe = window.setInterval(checkFocus, 100);

        if (adUnit && adUnit.ad) {
            const eventData = {
                advid: adUnit.ad.advId,
                advnm: adUnit.ad.advertiserName,
                cmpid: adUnit.ad.campaignId,
                cmpnm: adUnit.ad.campaignName,
            };
            EventManager.sendEvent(
                Constants.EVENT.ADS_IMP,
                eventData,
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
        }
    };

    return (
        <Grid id={'iframe-wrapper'} item xs={12}>
            {isLoading ? (
                <div className={classes.loadingWrapper}>
                    <CircularProgress />
                </div>
            ) : adUnit && adUnit.ad && src ? (
                <>
                    <div id="iframe-click"></div>
                    <iframe
                        className={classes.iframeWrapper}
                        frameBorder="0"
                        id="nativeAd"
                        marginHeight="0"
                        marginWidth="0"
                        onLoad={onIframeLoaded}
                        src={src}
                        title="nativeAd"
                    />
                </>
            ) : null}
        </Grid>
    );
};
