import Constants from 'common/constants';
import EventManager from 'eventManager/eventManager';
import { evaluateUserStatusOnEmailSub } from 'helpers/session/sessionHelpers';

export const fireLandingImp = () => {
    EventManager.sendEvent(Constants.EVENT.LANDING_PAGE_IMP, {}, Constants.EVENT_TYPE.LANDING);
};

export const fireAntiSpamPixelEvent = (route, extraData = {}) => {
    const endPointEventTypeMap = {
        [Constants.PAGE_ENDPOINTS.LANDING]: Constants.EVENT_TYPE.LANDING,
        [Constants.PAGE_ENDPOINTS.SURVEY]: Constants.EVENT_TYPE.SURVEY,
        [Constants.PAGE_ENDPOINTS.ADS]: Constants.EVENT_TYPE.ADS,
        [Constants.PAGE_ENDPOINTS.GRATIFICATION]: Constants.EVENT_TYPE.GRAITIFICATION,
    };
    const eventType = endPointEventTypeMap[route];
    EventManager.sendEventWhenSessionInit(Constants.EVENT.SPAM_PIXEL_FIRED, extraData, eventType);
};

export const fireEmailSub = formData => {
    const eventData = {
        cat: 'email',
        ...formData,
    };
    evaluateUserStatusOnEmailSub();
    EventManager.sendEvent(Constants.EVENT.EMAIL_ADDED, eventData, Constants.EVENT_TYPE.LANDING);
};
