import { IdStore } from 'dataStore';
import Utils from 'common/utils';
import UrlManager from 'common/urlManager';
import Network from 'common/network';
import * as Constants from 'common/constants';
import { FeatureMap } from 'providers';
import { FeatureValueHelper, StringHelper, CommonHelper } from 'helpers';
import AnalyticsManager from './analyticsManager';
import { EVENT_CONSTANTS } from './common';
import { getFlavor } from 'flavors.macro';

/**
 * If a value in data is an object, then we are calling function recursively to map values inside it
 * @param {*} data
 * @param {*} eventObj
 */

const allowedKeys = [
    'ts',
    'ua',
    'os',
    'ip',
    'browser',
    'device_type',
    'os_version',
    'browser_version',
    'device',
    'evt_id',
    'svid',
    'spid',
    'lid',
    'uid',
    'did',
    'sid',
    'first_name',
    'last_name',
    'email',
    'zip',
    'phone_number',
    'gender',
    'dob',
    'city',
    'state',
    'country',
    'region',
    'cs_id',
    'qid',
    'segid',
    'seg_index',
    'ans_val',
    'gid',
    'advid',
    'advnm',
    'cpc',
    'cpa',
    'utm_source',
    'utm_campaign',
    'utm_medium',
    'utm_term',
    'utm_content',
    'referrer',
    'domain',
    'cmpid',
    'crid',
    'rvn',
    'rv_type',
    'ervn',
    'lname',
    'lpath_name',
    'event_type',
    'is_skipped',
    'flv_name',
    'address',
    'hnp_url',
    'error',
    'responsedata',
    'dup',
    'qno',
    'spotno',
    'optno',
    'qnm',
    'optvl',
    'cat',
    'uuid',
    'job_type',
    'cmpnm',
    'pipv4',
    'leadid',
    'optxt',
    'cr',
    'pl',
    'ag',
    'tid',
    'clid',
    'val',
    'abt',
    'abt1',
    'abt2',
    'abt3',
    'abt4',
    'abt5',
    'abt6',
    'abt7',
    'abt8',
    'abt9',
    'abt10',
    'abt11',
    'abt12',
    'abt13',
    'abt14',
    'abt15',
    'abt16',
    'abt17',
    'abt18',
    'abt19',
    'abt20',
    'abt21',
    'abt22',
    'abt23',
    'abt24',
    'abt25',
    'abt26',
    'abt27',
    'abt28',
    'abt29',
    'abt30',
    'abt31',
    'abt32',
    'abt33',
    'abt34',
    'abt35',
    'abt36',
    'abt37',
    'abt38',
    'abt39',
    'abt40',
    'abt41',
    'abt42',
    'abt43',
    'abt44',
    'abt45',
    'abt46',
    'abt47',
    'abt48',
    'abt49',
    'abt50',
    'evs',
    'tf_curl',
    'w',
    'h',
    'ps',
    'pss',
    'purl',
    'dpr',
    'adt',
    'adst',
    'adst2',
    'ptype',
    'opid',
    'etid',
    'ljt',
    'lkw',
    'registered_visit_count',
    'pa',
    'feature_sets',
    Constants.ID_STORE_KEYS.KW_CAT,
    'indications',
    'ekw',
    'svopt',
    'pdomain',
    'domain',
    'isrdr',
    'visitCount',
    'pvs',
    'email_optin_ts',
    'phone_no_optin_ts',
    'user_status',
    'user_status_current',
    'is_sub',
    'is_reg',
    'feed_advnm',
    'feed_cmpnm',
    'logos',
    'hp',
    'ext1',
    'ext2',
    'ext3',
    'ext4',
    'ext5',
    'offerid',
    'max_deal_id',
    'vertical',
    'abt_exp',
    'first_visit',
    'last_visit',
    'job_redirect_url',
    'cta_text',
    'ots',
    'okeyword',
    'omid',
    'osource',
    'encoded_source_str',
    'max_filters',
    'pub_source_type',
];

const assignEmailInfo = eventObj => {
    if (!eventObj.email && IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.EMAIL_FED)) {
        eventObj.email = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.EMAIL_FED);
    }
};

const _overrideVsForBlackListedEmail = eventObj => {
    if (IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.IS_EMAIL_BLACK_LISTED)) {
        if (Utils.getObjVal(eventObj, ['evs'])) {
            eventObj.evs = 'failed';
        }

        if (Utils.getObjVal(eventObj, ['pvs'])) {
            eventObj.pvs = 'failed';
        }
    }
};

const _assignJBDetailsInfo = eventObj => {
    try {
        const details = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_DETAILS);
        const jbDetails = Utils.jsonSafeParse(details);

        if (!Utils.isEmptyStr(Utils.getObjVal(jbDetails, ['advnm']))) {
            eventObj.feed_advnm = Utils.getObjVal(jbDetails, ['advnm']);
        }

        if (!Utils.isEmptyStr(Utils.getObjVal(jbDetails, ['cmpnm']))) {
            eventObj.feed_cmpnm = Utils.getObjVal(jbDetails, ['cmpnm']);
        }
    } catch (e) {
        return {};
    }
};

export const mapDataToEventObj = (data, eventObj) => {
    const keys = Object.keys(data);
    keys.forEach(key => {
        if (
            EVENT_CONSTANTS.ALLOWED_ID_VALS.indexOf(key) !== -1 ||
            EVENT_CONSTANTS.ALLOWED_TRANSIENT_VALS.indexOf(key) !== -1
        ) {
            if (key === `${getFlavor('layout-theme')}_${Constants.USER.STORE_USER_KEY}`) {
                const obj = data && data[key] && JSON.parse(data[key]);

                if (!Utils.isNull(obj) && typeof obj === 'object') {
                    mapDataToEventObj(obj, eventObj);

                    return;
                }
            }

            if (!eventObj[key] && data[key]) {
                eventObj[key] = data[key];
            }
        }
    });
    eventObj[Constants.ID_STORE_KEYS.JOB_TYPE_VAL] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.JOB_TYPE_VAL,
    );
    const featureSetIds =
        Utils.jsonSafeParse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.FEATURE_SETS)) || [];
    eventObj[Constants.ID_STORE_KEYS.FEATURE_SETS] = featureSetIds.join(',');
    eventObj[Constants.ID_STORE_KEYS.FIRST_VISIT] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.FIRST_VISIT,
    );
    eventObj[Constants.ID_STORE_KEYS.LAST_VISIT] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.LAST_VISIT,
    );
    eventObj['vertical'] = 'jobs';
    eventObj[Constants.ID_STORE_KEYS.FIRST_VISIT] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.FIRST_VISIT,
    );
    eventObj[Constants.ID_STORE_KEYS.LAST_VISIT] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.LAST_VISIT,
    );

    assignEmailInfo(eventObj);
    _assignJBDetailsInfo(eventObj);

    if (IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL))
        eventObj[Constants.ID_STORE_KEYS.EXT2] = IdStore.fetchIdForKey(
            Constants.USER.EMPLOYER_TYPE_VAL,
        );

    return eventObj;
};

class EventMeta {
    constructor(eventName, eventData, eventType) {
        this.eventName = eventName;
        this.eventData = eventData;
        this.eventType = eventType;
        this.fireOnVisible = false;
    }

    setFireOnVisible(visiblity) {
        this.fireOnVisible = visiblity;
    }
}

class EventManager {
    constructor() {
        this.isSessionInitialized = false;
        this.sessionInitializedEventHandler = this.sessionInitializedEventHandler.bind(this);
        this.visiblityQueue = [];
        this.sessionQueue = [];
    }

    initialize() {
        // Listen to session manager
        window.addEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED,
            this.sessionInitializedEventHandler,
        );
    }

    sessionInitializedEventHandler(evt) {
        try {
            window.removeEventListener(
                Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED,
                this.sessionInitializedEventHandler,
            );
            console.log('[Event]: Flushing session queue');
            this.sessionQueue.forEach(evData => {
                this.sendEvent(...evData);
            });
            this.sessionQueue = [];
        } catch (rmErr) {
            console.log('SESSION_EVENT: Error removing the event-listener - ', rmErr);
        }
        console.log('SESSION_EVENT: Session initialized!');

        this.isSessionInitialized = true;
    }

    /**
     * Store data in seesion storage
     * @param {object} data
     */
    storeTransientData(data) {
        if (Utils.isEmptyObj(data)) {
            console.error('Key value pair expected for storing data in web storage');

            return;
        }
        Object.keys(data).forEach(key => IdStore.storeIdForKey(key, data[key]));
    }

    /**
     * Send event to remote
     * @param {string} eventName
     * @param {object} eventData
     */
    async sendEvent(eventName, eventData, eventType) {
        if (window.isLh) return;
        const eventMeta = new EventMeta(eventName, eventData, eventType);
        this.sendEventMeta(eventMeta);
    }

    sendEventWhenVisible(eventName, eventData, eventType) {
        const eventMeta = new EventMeta(eventName, eventData, eventType);
        eventMeta.setFireOnVisible(true);
        this.sendEventMeta(eventMeta);
    }

    async sendEventWhenSessionInit(eventName, eventData, eventType) {
        if (window.isLh) return;

        if (this.isSessionInitialized) {
            return this.sendEvent(eventName, eventData, eventType);
        } else {
            this.sessionQueue.push([eventName, eventData, eventType]);
        }
    }

    createAndGetEventUrl(eventName, eventData, eventType) {
        const eventMeta = new EventMeta(eventName, eventData, eventType);
        const eventObj = this.createEventObj(eventMeta);

        return this.getEventUrl(eventObj);
    }

    getEventData(eventName, eventData, eventType) {
        const eventMeta = new EventMeta(eventName, eventData, eventType);
        const eventObj = this.createEventObj(eventMeta);

        // push only allowed keys in the event
        let keys = Object.keys(eventObj).filter(k => allowedKeys.indexOf(k) !== -1);
        let filteredObject = {};
        keys.forEach(k => {
            if (!StringHelper.isNull(eventObj[k])) {
                filteredObject[k] = encodeURIComponent(eventObj[k]);
            }
        });

        return filteredObject;
    }

    eventCheck(eventObj) {
        // Check Events Flow for test Env
        if (Utils.isTestEnv() || Utils.isLocalEnv()) {
            if (!window.eventLogs) {
                window.eventLogs = [];
            }

            let keys = Object.keys(eventObj).filter(k => allowedKeys.indexOf(k) !== -1);
            let filteredObject = {};
            keys.forEach(k => {
                if (!StringHelper.isNull(eventObj[k])) {
                    filteredObject[k] = encodeURIComponent(eventObj[k]);
                }
            });

            const objectToPush = {
                evt_id: eventObj.evt_id,
                event_type: eventObj.event_type,
                cat: eventObj.cat,
                event: filteredObject,
            };

            window.eventLogs.push(objectToPush);
        }
    }

    sendEventMeta(eventMeta) {
        if (Utils.isNull(eventMeta)) {
            console.log(`EVENT_DATA: Not sending since event-meta is empty!`);

            return;
        }

        if (
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.IS_EMAIL_BLACK_LISTED) &&
            (eventMeta.eventName === 'hnp_lead' || eventMeta.eventName === 'hnp_imp')
        ) {
            console.log('[Event]: Skipping Lead for BL Email');

            return;
        }
        const eventObj = this.createEventObj(eventMeta);

        if (Utils.isNull(eventObj)) {
            console.log(
                `EVENT_DATA: Not sending ${eventMeta.eventName} since event-object is empty!`,
            );

            return;
        }

        this.eventCheck(eventObj);

        console.log(`EVENT_DATA: ${eventMeta.eventName} -> ${JSON.stringify(eventObj)}`);

        this.saveDispatchedEventId(eventMeta.eventName);

        if (eventMeta.fireOnVisible) {
            this.visiblityQueue.push(eventObj);

            return;
        }
        this.sendEventRequestWithObj(eventObj)
            .then(response => {
                console.log(`EVENT_DATA: Sent event - ${eventMeta.eventName}`);
            })
            .catch(err => {
                console.log(`EVENT_DATA: Err sending event - ${eventMeta.eventName} - ${err}`);
            });

        AnalyticsManager.sendEventToAnalytics(eventObj);
    }

    addFixedEvtParams(eventObj) {
        eventObj[Constants.ID_STORE_KEYS.DEVICE_WIDTH] = window.screen.width;
        eventObj[Constants.ID_STORE_KEYS.DEVICE_HEIGHT] = window.screen.height;
        eventObj[Constants.ID_STORE_KEYS.DEVICE_PIXEL_RATIO] = window.devicePixelRatio;
        eventObj[Constants.ID_STORE_KEYS.PAGE_URL] = window.location.href;
        eventObj.ua = navigator.userAgent;
        eventObj[Constants.ID_STORE_KEYS.ENCODED_SOURCE] = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.NEW_ENCODED_SUBID,
        );

        return eventObj;
    }

    createEventObj(eventMeta) {
        const { eventName, eventData, eventType } = eventMeta;

        if (Utils.isEmptyStr(eventName)) {
            console.error('EVENT_DATA: ERR: event-name cannot be empty');

            return null;
        }

        // TODO: Pre-process the response data
        let eventObj = {};

        if (false === Utils.isNull(eventType)) {
            eventObj['event_type'] = eventType;
        }

        this.addFixedEvtParams(eventObj);
        let status = 0;

        try {
            if (Notification) {
                // granted=1, denied=2, default=0, not supported=4
                switch (Notification.permission) {
                    case 'granted':
                        status = 1;
                        break;
                    case 'denied':
                        status = 2;
                        break;
                    default:
                        status = 0;
                        break;
                }
            }
        } catch {
            status = 4;
        }

        eventObj[Constants.NOTIFICATIONS.PUSH_ACCESS] = status;

        try {
            const pubSourceTypeConfig =
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG) &&
                JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG));

            if (pubSourceTypeConfig && pubSourceTypeConfig.pubSourceType)
                eventObj[Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE] =
                    pubSourceTypeConfig.pubSourceType;
        } catch (error) {
            console.log('Event Data: Pub Source Type Error - ', error);
        }

        // Add all the store entries & event entries
        const idObj = !Utils.isNull(IdStore) && IdStore.fetchAllIds && IdStore.fetchAllIds();

        if (false === Utils.isNull(idObj)) {
            // Only add entries from the list into the event object
            const mappedData = mapDataToEventObj(idObj, eventObj);
            eventObj = {
                ...eventObj,
                ...mappedData,
            };
        }

        if (false === Utils.isEmptyObj(eventData)) {
            eventObj = Object.assign(eventObj, eventData);
        }
        _overrideVsForBlackListedEmail(eventObj);

        if (Utils.isEmptyObj(eventObj)) {
            console.log(
                `ERR: Event object does not contain session-id - ${eventName} =>`,
                eventObj,
            );

            return null;
        }

        eventObj[EVENT_CONSTANTS.EVENT_ID_KEY] = eventName;

        return eventObj;
    }

    sendEventRequestWithObj(eventObj) {
        const url = this.getEventUrl(eventObj);

        // Make request
        return Network.get(url, null, true);
    }

    getEventUrl(eventObj) {
        // push only allowed keys in the event
        let keys = Object.keys(eventObj).filter(k => allowedKeys.indexOf(k) !== -1);
        let filteredObject = {};
        keys.forEach(k => {
            if (!StringHelper.isNull(eventObj[k])) {
                filteredObject[k] = encodeURIComponent(eventObj[k]);
            }
        });
        // Build the url
        let urlArgs = Utils.encodeObjectToUrlArgs(filteredObject);
        let url = `${UrlManager.getEventsUrl()}?${urlArgs}`;
        console.log(`EVENT_DATA: URL - ${url}`);

        return url;
    }

    storeEventMeta(eventMeta) {
        if (Utils.isNull(eventMeta)) {
            console.log('EVENT_DATA: Cannot store into event-meta since data is null!');

            return;
        }
        console.log(
            `EVENT_DATA: Storing ${eventMeta.eventName} into premature-events-list since session is uninitialized`,
        );
        this.prematureEventsList.push(eventMeta);

        // Drop old events after reaching a certain point
        if (this.prematureEventsList.length >= EVENT_CONSTANTS.EVENT_STORE_MAX_LIMIT) {
            let numEntries =
                this.prematureEventsList.length - EVENT_CONSTANTS.EVENT_STORE_MAX_LIMIT;

            if (numEntries > 0) {
                console.log(`EVENT_DATA: Dropping ${numEntries} from premature-events-list`);

                for (let i = 0; i < numEntries; i++) {
                    this.prematureEventsList.shift();
                }
            }
        }
    }

    fireOnVisible() {
        while (true) {
            let eventObj = this.visiblityQueue.shift();

            if (!eventObj) break;
            this.sendEventRequestWithObj(eventObj)
                .then(response => {
                    console.log(`VISIBILITY EVENT_DATA: Sent event - ${eventObj.evt_id}`);
                })
                .catch(err => {
                    console.log(
                        `VISIBILITY EVENT_DATA: Err sending event - ${eventObj.evt_id} - ${err}`,
                    );
                });
            AnalyticsManager.sendEventToAnalytics(eventObj);
        }
    }

    saveDispatchedEventId(eventId) {
        const eventToTrackFeatureValues = FeatureValueHelper.getFeatureValues(
            FeatureMap.EventsToTrack,
        );
        const eventsToTrack =
            eventToTrackFeatureValues && eventToTrackFeatureValues.length
                ? CommonHelper.arrayToObj(eventToTrackFeatureValues, true)
                : EVENT_CONSTANTS.EVENTS_TO_TRACK;
        const CS_ID = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);
        const dispatchedEventsKey = `${CS_ID}_${Constants.ID_STORE_KEYS.DISPATCHED_EVENTS}`;

        if (eventsToTrack[eventId]) {
            const dispatchedEventsFromIdStore = IdStore.fetchIdForKey(dispatchedEventsKey);

            try {
                const parsedEvents =
                    dispatchedEventsFromIdStore && JSON.parse(dispatchedEventsFromIdStore);
                let newDispatchedEvents;

                if (!Utils.isEmptyObj(parsedEvents)) {
                    newDispatchedEvents = {
                        ...parsedEvents,
                        [eventId]: parsedEvents[eventId] ? parsedEvents[eventId] + 1 : 1,
                    };
                } else {
                    newDispatchedEvents = { [eventId]: 1 };
                }

                IdStore.storeIdForKey(dispatchedEventsKey, JSON.stringify(newDispatchedEvents));
            } catch (err) {
                IdStore.storeIdForKey(dispatchedEventsKey, JSON.stringify({ [eventId]: 1 }));
            }
        }
    }
}

const eventManager = new EventManager();
export default eventManager;
