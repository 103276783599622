import { IdStore } from 'dataStore/index';
import Utils from 'common/utils';
import Constants from 'common/constants';

const SERVICE_UPDATE_MAP = {
    [Constants.ID_STORE_KEYS.BASE_API_URL]: Constants.URL_ARGS.BASE_API_URL,
    [Constants.ID_STORE_KEYS.PUBLISHER_COST_BASE_URL]: Constants.URL_ARGS.PUBLISHER_COST_BASE_URL,
    [Constants.ID_STORE_KEYS.CONFIGURATION_BASE_URL]: Constants.URL_ARGS.CONFIGURATION_BASE_URL,
};

export const preAppInitProcessor = () => {
    Object.keys(SERVICE_UPDATE_MAP).forEach(key => {
        const apiDomainVal = Utils.getValForKeyFromCurrentUrl(key);

        if (!Utils.isEmptyStr(apiDomainVal)) {
            console.log(
                '[PreAppInit] Registering API DOMAIN',
                SERVICE_UPDATE_MAP[key],
                decodeURIComponent(apiDomainVal),
            );
            IdStore.storeIdForKey(SERVICE_UPDATE_MAP[key], decodeURIComponent(apiDomainVal));
        }
    });
};
