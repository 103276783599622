/**
 * Publisher name to id mapping for Everflow on-boarded publishers
 */
export const Publishers = {
    TP: '1',
    TP2: '2',
    AIBEM: '5',
    AIBSMS: '6',
    ID: '7',
    WCM: '9',
    CT: '10',
    NCP: '11',
    ECH: '12',
    ZAPP: '13',
    AIBP: '14',
    WIFM: '15',
    FIM: '16',
    RG: '17',
    JG: '18',
    MJH: '19',
    R3I: '20',
    RET: '21',
    FSM: '22',
    FTC: '23',
    J2M: '24',
};
