export default {
    amazon: ['Warehouse', 'Delivery Driver', 'Work from home', 'Other'],
    costco: ['Cashier', 'Warehouse', 'Customer Service', 'Other'],
    'home depot': ['Warehouse', 'Cashier', 'Delivery Driver', 'Other'],
    walmart: ['Stocker', 'Customer Service', 'Warehouse', 'Other'],
    fedex: ['Warehouse', 'Customer Service', 'Delivery Driver', 'Other'],
    target: ['Customer Service', 'Stocker', 'Cashier', 'Other'],
    walgreens: ['Customer Service', 'Stocker', 'Sales', 'Other'],
    goodwill: ['Stocker', 'Customer Service', 'Warehouse', 'Other'],
    'coca cola': ['Delivery Driver', 'Receptionist', 'Warehouse', 'Other'],
    'dollar general': ['Stocker', 'Customer Service', 'Warehouse', 'Other'],
    'foot locker': ['Sales', 'Stocker', 'Cashier', 'Other'],
    'immediate hiring': [
        'Customer Service',
        'Stocker',
        'Delivery Driver',
        'Warehouse',
        'Seasonal',
        'Other',
    ],
    'full time jobs': [
        'Customer Service',
        'Stocker',
        'Delivery Driver',
        'Warehouse',
        'Seasonal',
        'Other',
    ],
    pepsi: ['Receptionist', 'Delivery Driver', 'Warehouse', 'Other'],
    'frito lay': ['Warehouse', 'Sales', 'Store Manager', 'Other'],
    'sams club': ['Stocker', 'Customer Service', 'Warehouse', 'Other'],
    usps: ['Customer Service', 'Delivery Driver', 'Package Handler', 'Other'],
    ups: ['Warehouse', 'Customer service', 'Delivery Driver', 'Other'],
    marriott: ['Front Desk', 'Housekeeping', 'Security', 'Other'],
    macys: ['Cashier', 'Warehouse', 'Sales', 'Other'],
    subway: ['Cashier', 'Manager', 'Server', 'Other'],
    'pizza hut': ['Delivery driver', 'Cashier', 'Manager', 'Other'],
    kfc: ['Manager', 'Server', 'Cashier', 'Other'],
    lowes: ['Warehouse', 'Stocker', 'Customer Service', 'Other'],
    kroger: ['Stocker', 'Cashier', 'Warehouse', 'Other'],
    nike: ['Sales', 'Delivery Driver', 'Warehouse', 'Other'],
    aldi: ['Stocker', 'Cashier', 'Customer Service', 'Other'],
    mcdonalds: ['Manager', 'Server', 'Cashier', 'Other'],
    'dollar tree': ['Warehouse', 'Cashier', 'Customer Service', 'Other'],
    uber: ['Driver', 'Customer Service', 'Manager', 'Other'],
    'american airlines': ['Security', 'Driver', 'Package Handler', 'Other'],
    starbucks: ['Barista', 'Store Manager', 'Cashier', 'Other'],
    'kelly services': ['Material Handler', 'Assembly worker', 'Customer Service', 'Other'],
    ford: ['Call Center', 'Sales', 'Production Supervisor', 'Other'],
    'taco bell': ['Cashier', 'Manager', 'Supervisor', 'Other'],
    'waste management': ['Driver', 'Laborer', 'Sorter', 'Other'],
    hilton: ['Front Desk', 'Guest Service', 'Housekeeper', 'Other'],
    'burger king': ['Manager', 'Server', 'Cashier', 'Other'],
    'union pacific railroad': ['Train Crew', 'Logistics', 'Mechanic', 'Other'],
    fema: ['Customer Service', 'Emergency team', 'Construction', 'Other'],
    cvs: ['Customer Service', 'Stocker', 'Sales', 'Other'],
    'cvs health': ['Customer Service', 'Stocker', 'Sales', 'Other'],
    'hobby lobby': ['Stocker', 'Warehouse', 'Secretary', 'Other'],
    tsa: ['Security', 'Mission Support', 'Law Enforcement', 'Other'],
    'five below': ['Stocker', 'Customer Service', 'Warehouse', 'Other'],
    petsmart: ['Sales', 'Stocker', 'Cashier', 'Other'],
    'whole foods': ['Stocker', 'Customer Service', 'Warehouse', 'Other'],
};

export const GoogleAllowedKeywordsJT = ['full time jobs', 'part time jobs', 'work from home'];

export const GoogleJtKwMappings = {
    'full time jobs': ['Warehouse', 'Sales', 'Customer Service', 'Delivery Driver', 'Other'],
    'part time jobs': ['Delivery Driver', 'Cashier', 'Warehouse', 'Other'],
    'work from home': ['Customer Service', 'Data Entry', 'Sales', 'Other'],
};

export const GoogleRGLanderKwMappings = {
    'online jobs': ['Customer Service', 'Work from home', 'Data Entry', 'Other'],
    'part time jobs': ['Delivery Driver', 'Customer Service', 'Work from home', 'Other'],
    'entry level jobs': ['Delivery Driver', 'Customer Service', 'Work from home', 'Other'],
    'full time jobs': ['Delivery Driver', 'Customer Service', 'Work from home', 'Other'],
    'immediate hiring': ['Delivery Driver', 'Customer Service', 'Work from home', 'Other'],
};

export const JobCategoriesJTMapping = {
    costco: ['Cashier', 'Stocker', 'Logistics', 'Pharmacy', 'Membership Assistant', 'Food Service'],
    walgreens: [
        'Customer Service',
        'Pharmacy',
        'Entry Level',
        'Store Manager',
        'Cashier',
        'Part Time',
    ],
    target: [
        'Warehouse',
        'Stocker',
        'Cashier',
        'Store Manager',
        'Sales',
        'Pharmacy',
        'Store Attendant',
    ],
    walmart: [
        'Sales',
        'Retail',
        'Customer Service',
        'Logistics',
        'Warehouse',
        'Stocker',
        'Cleaner',
    ],
    goodwill: [
        'Stocker',
        'Retail',
        'Driver',
        'Sales',
        'Store Clerk',
        'Cashier',
        'Janitor',
        'Forklift Operator',
    ],
    usps: [
        'Mail Handler',
        'Delivery Driver',
        'Postal Worker',
        'Customer Service',
        'Mail Processor',
        'Rural Carrier',
        'Package Handler',
    ],
    marriott: ['Front Desk', 'Housekeeper', 'Laundry Attendant', 'Cashier', 'Dishwasher'],
    'sams club': ['Stocker', 'Store Manager', 'Cashier', 'Packager', 'Sales', 'Retail'],
    amazon: [
        'Warehouse',
        'Package Handler',
        'Delivery Driver',
        'Packer',
        'Customer Service',
        'Office',
    ],
    starbucks: ['Barista', 'Retail', 'Store Manager', 'Shift Supervisor', 'Office'],
    'dollar general': ['Sales', 'Truck Driver', 'Store Manager', 'Customer Service', 'Warehouse'],
    'coca cola': [
        'Warehouse',
        'Truck Driver',
        'Technician',
        'Manufacturing',
        'Supervisor',
        'Part Time',
    ],
    pepsi: ['Sales', 'Warehouse', 'Delivery Driver', 'Material Handler', 'Packer', 'Store Manager'],
    'home depot': [
        'Cashier',
        'Store Support',
        'Customer Service',
        'Warehouse',
        'Maintenance',
        'Driver',
    ],
    'dollar tree': ['Warehouse', 'Driver', 'Sales', 'Store Manager', 'Cashier'],
    fedex: [
        'Warehouse',
        'Package Handler',
        'Customer Service',
        'Delivery Driver',
        'Technician',
        'Sorter',
    ],
    'frito lay': ['Driver', 'Sales', 'Material Handler', 'Part-Time', 'Warehouse'],
};

export const DynamicJobsJobProviderLogoMapping = {
    amazon: 'amazon',
    burgerking: 'burger king',
    burger: 'burger king',
    fedex: 'fedex',
    starbucks: 'starbucks',
    target: 'target',
    ups: 'ups',
    walmart: 'walmart',
};

export const jtDefaultKWMapping = {
    fedex: 'Customer Service',
    costco: 'Stocker',
    target: 'Stocker',
    amazon: 'Customer Service',
};
