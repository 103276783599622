import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import { ConfigProvider } from '../index';

/**
 * Feature provider methods to get, set values in client application
 */
export default class ABTests {
    constructor(data) {
        this.initialize(data);
    }

    /**
     * Initialize Features in the application, leverage on caching
     */
    async initialize(data) {
        if (Utils.isNull(data)) {
            await ConfigProvider.initialize();
            data = ConfigProvider.data;
        }

        return data.abtMap === null ? false : this.setABTests(data.abtMap);
    }

    /**
     * Set AB Test values in cache
     * @param {*} key ab test key
     * @param {*} value ab test variant name
     */
    set(key, value, channel) {
        if (Utils.isStringNullOrWhiteSpace(key)) return false;

        if (Utils.isStringNullOrWhiteSpace(channel)) return false;

        IdStore.storeIdForKey(`abt_${key}`, JSON.stringify({ value: value, channel: channel }));
        IdStore.storeIdForKey(channel, value);

        return true;
    }

    /**
     * Get AB Test value
     * @param {*} key AB Test key
     */
    get(key) {
        // parameter validation
        if (Utils.isStringNullOrWhiteSpace(key)) return null;

        return (
            IdStore.fetchIdForKey(`abt_${key}`) && JSON.parse(IdStore.fetchIdForKey(`abt_${key}`))
        );
    }

    /**
     * Get all AB Tests value in and array
     */
    getAll() {
        let abTests = {};
        Object.keys(sessionStorage).forEach((item, _index, _array) => {
            if (!Utils.isStringNullOrWhiteSpace(item) && item.startsWith('abt_')) {
                try {
                    abTests[item] = JSON.parse(IdStore.fetchIdForKey(item));
                } catch {
                    console.log(`FAILED: Parsing ABT ${item}`);
                }
            }
        });

        return abTests;
    }

    /**
     * Setup AB Tests
     * @param {*} data AB Test data map
     */
    setABTests(data) {
        if (Utils.isNull(data)) return false;

        data.forEach(test => {
            if (
                !Utils.isStringNullOrWhiteSpace(test.channel) &&
                !Utils.isStringNullOrWhiteSpace(test.variantName)
            ) {
                this.set(test.abtID, test.variantName, test.channel);
            }
        });

        return true;
    }
}
