import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'styles/jobSearch.css';
import Button from '@material-ui/core/Button';
import LoaderView from 'components/loader';
import './jobSearchView.css';
import { TextValidator } from 'react-material-ui-form-validator';
import Constants from 'common/constants';
import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import ReactHtmlParser from 'react-html-parser';
import DynamicJobListing from './dynamicJobListing';
import JobListingsV1 from './jobListingsV1';
import JobListingsV2 from './jobListingsV2';
import StaticJobListing from './staticListingsForBackClickUser';
import EventManager from 'eventManager/eventManager';
import { AbTestHelper } from 'helpers';
import { UserDetailsDataSource } from 'datasource/index';
import { Search, Notifications } from '@material-ui/icons';
import { ValidatorFormHOC } from 'hocs/validatorFormHOC';
import { FeatureValueHelper } from 'helpers/index';
import memoryStore from 'dataStore/memoryStore';
import memoryStoreKeys from 'common/memoryStoreKeys';
import { NativeAd } from './nativeAd';

const fetchDetails = () => {
    try {
        let details = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_DETAILS);

        if (!Utils.isEmptyStr(details)) {
            details = JSON.parse(details);

            return details;
        }
    } catch (e) {
        console.log('error while parsing data', e);

        return false;
    }
};

class JobsSearchView extends React.Component {
    constructor(props) {
        super(props);
        this.getCapitalizeStr = str => (str ? str[0].toUpperCase() + str.slice(1) : '');
        this.showDynListing =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.DYNAMIC_XML_FEED) || true;
        this.isBackClickUser =
            Boolean(Utils.getValForKeyFromCurrentUrl('lrdr')) &&
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE) !== 'ls';

        this.showStaticJL = AbTestHelper.showStaticJL();

        let week = 'week1';

        if (this.showStaticJL) {
            week = this.calulatePresentDate();
        }
        let searchTerm = this.props.searchTerm;

        if (
            AbTestHelper.exploreJobsZipOptimized() &&
            searchTerm &&
            searchTerm.toLowerCase &&
            searchTerm.toLowerCase() === 'null'
        ) {
            searchTerm = Constants.DEFAULT_KW;
        }
        this.state = {
            clickedSubmitBtn: 0,
            searchTerm,
            location: this.props.location,
            currPageNum: props.currentPageNum,
            touched: false,
            displayTerm: {
                searchTerm: props.searchTerm,
                location: props.location,
                queryTerm: props.queryTerm,
            },
            week,
        };

        const jobAdsSource = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_ADS_SOURCE);
        const advertiserAu = memoryStore.getItem(memoryStoreKeys.SINGLE_JOB_AD_AU) || {};

        let fallBackAdvertiserData;

        switch (jobAdsSource) {
            case Constants.ADVERTISERS.UPWARD:
                fallBackAdvertiserData = Constants.ADVERTISERS.DATA.UPWARD;
                break;
            case Constants.ADVERTISERS.TALROO:
                fallBackAdvertiserData = Constants.ADVERTISERS.DATA.TALROO;
                break;
            case Constants.ADVERTISERS.APPCAST:
                fallBackAdvertiserData = Constants.ADVERTISERS.DATA.APPCAST;
                break;
            case Constants.ADVERTISERS.ZIPRECRUITER:
                fallBackAdvertiserData = Constants.ADVERTISERS.DATA.ZIPRECRUITER;
                break;
            default:
                fallBackAdvertiserData = Constants.ADVERTISERS.DATA.UPWARD;
        }

        if (advertiserAu.advId && advertiserAu.advertiserName) {
            // TODO: Check this part
            this.advertiserData = {
                ID: advertiserAu.advId,
                NAME: advertiserAu.advertiserName,
            };
        } else {
            this.advertiserData = fallBackAdvertiserData;
        }

        Utils.setLuckyOrangeTags(`lcmp:${fallBackAdvertiserData.NAME}`);
        /*
         * this.showLogo = IdStore.fetchIdForKey("showLogo") === "true";
         * this.showHighlight = IdStore.fetchIdForKey("showHighlight") === "true";
         */
        this.showLogo = true;
        this.showHighlight = true;
        // this.showInHouseAds = this.props.showInHouseAds
        this.dynJobDetails = this.showDynListing ? fetchDetails() : '';
    }

    calulatePresentDate = () => {
        let dt = Utils.getValForKeyFromCurrentUrl('dt');
        let day,
            mon,
            year,
            currDate = new Date();

        if (dt && dt.includes('-')) {
            let date = dt.split('-');
            day = Number(date[0]);
            mon = Number(date[1]);
            year = Number(date[2]);
            currDate = new Date(year, mon - 1, day);
        }
        let date = currDate.getDate();
        let month = currDate.getMonth();

        if (date >= 16 && date <= 23 && month === 11) return 'week2';
        else if (date >= 24 && date <= 30 && month === 11) return 'week3';
        else if ((date === 31 && month === 11) || (date >= 1 && date <= 5 && month === 0))
            return 'week4';

        return 'week1';
    };

    handleSubmit = async e => {
        e.preventDefault();
        console.log('value', e.target.value);
        const { searchTerm, location } = this.state;
        let { clickedSubmitBtn } = this.state;

        if (Utils.isValidNumber(location) && location.length === 5) {
            await this.fetchAndUpdateLocDetails(location);
        }

        this.setState({
            clickedSubmitBtn: ++clickedSubmitBtn,
        });
        const searchContent = {
            searchTerm,
            location,
        };
        this.setState({
            displayTerm: {
                searchTerm,
                location,
                queryTerm: searchTerm,
            },
        });
        this.props.searchCb(searchContent);
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.adsData && this.props.adsData.Results.ViewId._text) {
            let searchTerm = this.props.searchTerm || 'CDL Jobs';

            if (searchTerm && !searchTerm.toLowerCase().endsWith('jobs')) {
                searchTerm = `${searchTerm} Jobs`;
                searchTerm = this.getCapitalizeStr(searchTerm);
            }
        }

        if (
            prevProps.scrollPageOnPagination !== this.props.scrollPageOnPagination &&
            prevProps.scrollPageOnPagination === false &&
            this.props.scrollPageOnPagination === true
        ) {
            let ele = document.querySelector('#listings-page');

            if (ele) ele.scrollIntoView({ behavior: 'smooth' });
        }

        if (this.props.selectedAdvertiser !== prevProps.selectedAdvertiser) {
            const jobAdsSource = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_ADS_SOURCE);
            let fallBackAdvertiserData;

            switch (jobAdsSource) {
                case Constants.ADVERTISERS.UPWARD:
                    fallBackAdvertiserData = Constants.ADVERTISERS.DATA.UPWARD;
                    break;
                case Constants.ADVERTISERS.TALROO:
                    fallBackAdvertiserData = Constants.ADVERTISERS.DATA.TALROO;
                    break;
                case Constants.ADVERTISERS.APPCAST:
                    fallBackAdvertiserData = Constants.ADVERTISERS.DATA.APPCAST;
                    break;
                case Constants.ADVERTISERS.ZIPRECRUITER:
                    fallBackAdvertiserData = Constants.ADVERTISERS.DATA.ZIPRECRUITER;
                    break;
                default:
                    fallBackAdvertiserData = Constants.ADVERTISERS.DATA.UPWARD;
            }

            Utils.setLuckyOrangeTags(`lcmp:${fallBackAdvertiserData.NAME}`);
            const eventData = {
                advid: fallBackAdvertiserData.ID,
                advnm: fallBackAdvertiserData.NAME,
                // val: showPopup ? 1 : 0,
            };
            EventManager.sendEvent(
                Constants.EVENT.GRATIFICATION_ROTATED,
                eventData,
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
        }
    }

    render() {
        const { classes } = this.props;
        const { searchTerm, location, touched } = this.state;
        const textFieldStyle = AbTestHelper.isFulfillmentDesignPhaseTwo()
            ? {
                  color: '#000000',
                  fontWeight: 500,
                  fontSize: 18,
              }
            : {};

        return (
            <ValidatorFormHOC
                id="listings-page"
                onError={errors => console.log(errors)}
                onSubmit={this.handleSubmit}
                ref="form">
                <div className="container">
                    <div classes={classes.root}>
                        <div className="search-bar-wrapper">
                            <div className={classes.inputContainer}>
                                <TextValidator
                                    InputProps={{
                                        disableUnderline: true,
                                        spellCheck: false,
                                        style: textFieldStyle,
                                    }}
                                    className={`${classes.textField} ${classes.searchBox}`}
                                    id="outlined-search"
                                    margin="normal"
                                    name="searchTerm"
                                    onChange={this.handleSearchParamChange.bind(this)}
                                    // onKeyUp={this.handleSubmit}
                                    value={this.getCapitalizeStr(searchTerm)}
                                />
                                <TextValidator
                                    InputProps={{
                                        disableUnderline: true,
                                        spellCheck: false,
                                        style: textFieldStyle,
                                    }}
                                    className={classes.textField}
                                    margin="normal"
                                    name="location"
                                    onChange={this.handleSearchParamChange.bind(this)}
                                    // onKeyUp={this.handleSubmit}
                                    value={touched ? location : this.props.location}
                                />
                                <div className="btn-container">
                                    <Button
                                        className={`search-btn ${classes.button}`}
                                        color="primary"
                                        endIcon={<Search className={classes.searchIcon} />}
                                        type="submit"></Button>
                                </div>
                            </div>
                        </div>
                        {/* <div id="855033567" style={{ marginTop: 10 }} ></div> */}
                        {/* Disable Adsense */}
                        {/* {(Utils.checkMobileDevice() && (!Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE)) && IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE) !== Constants.UTM_DEFAULT.SOURCE)) && <AdSense />} */}

                        {this.displayResults(classes)}
                    </div>
                </div>
            </ValidatorFormHOC>
        );
    }

    fetchAndUpdateLocDetails = async zip => {
        try {
            let user = await UserDetailsDataSource.fetchLocWithUserZip(zip);

            if (!Utils.isNull(user)) {
                const { city, country, state } = user;
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE, zip);
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_CITY, city);
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_COUNTRY, country);
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_REGION, state);
            }
        } catch (e) {
            console.error(e);
        }
    };

    handleSearchParamChange = e => {
        const { value, name } = e.target;
        this.setState({
            [name]: value,
            touched: true,
        });
    };

    getStaticJLList(configurations, dedupeMap) {
        if (!this.showStaticJL) return [];
        const activePage = this.props.activePage ? this.props.activePage - 1 : 0;

        return configurations.reduce((acc, { page, id, position, showBannerText }) => {
            if (activePage === page || (page && parseInt(page) === activePage) || page === '*') {
                acc[position] = (
                    <StaticJobListing
                        adUnitId={id}
                        dedupeMap={dedupeMap}
                        defaultJobBrand={this.props.defaultJobBrand}
                        isFulfillmentDesignPhaseTwo={this.props.isFulfillmentDesignPhaseTwo}
                        newFulfillmentPage={this.props.newFulfillmentPage}
                        position={position}
                        showBannerText={showBannerText}
                        term={this.state.displayTerm}
                        weekCls={this.state.week}
                    />
                );
            }

            return acc;
        }, []);
    }

    getNativeAdList(configurations) {
        if (!(AbTestHelper.showNativeJL() && FeatureValueHelper.getSERPNativeIframeSrc()))
            return [];
        const activePage = this.props.activePage ? this.props.activePage - 1 : 0;

        return configurations.reduce((acc, { page, id, position, showBannerText }) => {
            if (activePage === page || (page && parseInt(page) === activePage) || page === '*') {
                acc[position] = (
                    <NativeAd
                        adUnitId={id}
                        showBannerText={showBannerText}
                        src={FeatureValueHelper.getSERPNativeIframeSrc()}
                        term={this.state.displayTerm}
                    />
                );
            }

            return acc;
        }, []);
    }

    displayResults(classes, adsData) {
        const { isFulfillmentDesignPhaseTwo } = this.props;
        console.log('JOBS: ', this.props.jobsList);

        if (this.props.isLoadingJobs) {
            return <LoaderView loaderText="Loading..." />;
        }
        console.log('JOBS: Loading page', this.props.resultTitle);
        const dedupeMap = {};
        const staticListings = this.getStaticJLList(
            FeatureValueHelper.getSERPListingsConfig(),
            dedupeMap,
        );
        const preStaticListings = this.getStaticJLList(
            FeatureValueHelper.getSERPPreListingsConfig(),
            dedupeMap,
        );
        const nativeAdListings = this.getNativeAdList(
            FeatureValueHelper.getSERPNativeAdListingsConfig(),
        );

        return (
            <React.Fragment>
                {this.showStaticJL && preStaticListings}
                {this.showDynListing && !this.isBackClickUser && (
                    <DynamicJobListing
                        details={this.dynJobDetails}
                        handleCloseIcon={this.props.handleCloseIcon}
                        handleCloseSubmit={this.props.handleCloseSubmit}
                        isFulfillmentDesignPhaseTwo={isFulfillmentDesignPhaseTwo}
                        newFulfillmentPage={this.props.newFulfillmentPage}
                        onSubmitClick={this.props.onSubmitClick}
                        open={this.props.open}
                    />
                )}
                {this.props.newFulfillmentPage || isFulfillmentDesignPhaseTwo ? (
                    <div className={classes.resultTitle}>
                        <Notifications style={{ fontSize: 18, marginRight: 4 }} />{' '}
                        {this.props.resultTitle}
                    </div>
                ) : (
                    ReactHtmlParser(this.props.resultTitle)
                )}
                {this.props.isEmailOrSMSMedium && !this.isBackClickUser ? (
                    <JobListingsV2
                        classes={classes}
                        isFulfillmentDesignPhaseTwo={isFulfillmentDesignPhaseTwo}
                        isJobDedup={this.props.isJobDedup}
                        jobsList={this.props.jobsList}
                        mergedJobFeed={this.props.mergedJobFeed}
                        nativeAdListings={nativeAdListings}
                        newFulfillmentPage={this.props.newFulfillmentPage}
                        showHighlight={this.showHighlight}
                        showLogo={this.showLogo}
                        staticListings={staticListings}
                    />
                ) : (
                    <JobListingsV1
                        classes={classes}
                        handleFbGoogleInterstitialClick={this.props.handleFbGoogleInterstitialClick}
                        isFulfillmentDesignPhaseTwo={isFulfillmentDesignPhaseTwo}
                        jobsList={this.props.jobsList}
                        mailOrSMSMedium
                        mergedJobFeed={this.props.mergedJobFeed}
                        nativeAdListings={nativeAdListings}
                        newFulfillmentPage={this.props.newFulfillmentPage}
                        showFbGoogleInterstitial={this.props.showFbGoogleInterstitial}
                        showHighlight={this.showHighlight}
                        showLogo={this.showLogo}
                        staticListings={staticListings}
                        updateAdvertiser={this.props.updateAdvertiser}
                    />
                )}
            </React.Fragment>
        );
    }
}

const styles = theme => ({
    inputContainer: {
        display: 'flex',
        width: '100%',
    },
    jobTitle: {
        lineHeight: 1,
        letterSpacing: 0,
    },
    jobDescription: {
        color: '#1d5e97',
    },
    jobLocation: {
        margin: '2px 0px',
    },
    textField: {
        boxShadow: 'none',
        position: 'relative',
        border: '1px solid #c7c8c7',
        fontSize: 18,
        padding: 10,
        color: '#a8a8a8',
        height: 48,
        margin: '5px 0 15px',
        width: '82%',
        borderRadius: 4,
        boxSizing: 'border-box',
        backgroundColor: '#fff',
    },
    searchBox: {
        width: '175%',
        marginRight: 8,
    },
    v2Button: {
        height: 'auto',
        fontSize: 24,
        overflow: 'hidden',
        border: '28px',
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            lineHight: '20px',
            height: 'auto',
        },
    },
    button: {
        height: 'auto',
        fontSize: 24,
        overflow: 'hidden',
        whiteSpace: 'break-word',
        borderRadius: 4,
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            lineHight: '20px',
            height: 'auto',
        },
    },
    btnIcon: {
        width: 20,
        height: 20,
        marginTop: '10px',
    },
    searchIcon: {
        fontSize: '32px !important',
    },
    resultTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 1.5,
        color: '#245475',
        marginBottom: 12,
        backgroundColor: '#d6eafa',
        padding: 4,
        borderRadius: 6,
        marginTop: 10,
    },
    '@global': {
        '.MuiInputBase-root.Mui-disabled': {
            '&:before': {
                borderBottomStyle: 'none',
            },
        },
        '.MuiInputBase-input.Mui-disabled': {
            opacity: 0.4,
        },
        '.MuiButton-endIcon': {
            margin: 0,
        },
        '.MuiButton-root': {
            minWidth: '50px',
        },
        '.MuiAvatar-root.MuiAvatar-circle': {
            width: '48px',
            height: '48px',
        },
        '.MuiLink-underlineHover': {
            '&:hover': {
                textDecoration: 'none',
            },
        },
        '.MuiAvatar-img': {
            objectFit: 'contain',
        },
    },
});

JobsSearchView.propTypes = {
    defaultJobBrand: PropTypes.string.isRequired,
    isLoadingJobs: PropTypes.bool.isRequired,
    jobsList: PropTypes.array.isRequired,
    resultTitle: PropTypes.string.isRequired,
    searchCb: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    shouldSendEvents: PropTypes.bool.isRequired,
};

export default withStyles(styles)(JobsSearchView);
