import React, { useEffect, useState } from 'react';
import { Link, Typography, Avatar } from '@material-ui/core';
import JobSearchDataSource, { isValidAdvertiser } from 'datasource/jobsSearchDataSource';
import './jobSearchView.css';
import RouterPrefixHandler from 'common/routePrefixHandler';
import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import Constants from 'common/constants';
import AdsDataSource from 'datasource/adsDataSource';
import TargetingManager from 'targetingManager';
import OfferManager from 'components/ads/offerManager';
import EventManager from 'eventManager/eventManager';
import UrlManager from 'common/urlManager';
import Network from 'common/network';
import { useHistory } from 'react-router-dom';
import * as moment from 'moment';
import CreditScoreListings from './serpTemplates/creditScoreAd';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRightTwoTone } from '@material-ui/icons';
import { AbTestHelper } from 'helpers/index';

const getAdDedupeKey = ad => `${ad.advertiserName}-${ad.creativeId}`;

const renderDefaultHtml = (
    ad,
    term,
    week,
    job,
    newFulfillmentPage,
    isFulfillmentDesignPhaseTwo,
    classes,
    showTitle,
    position,
    customDestinationUrl,
) => {
    if (!(ad.customAdInfo && (ad.customAdInfo.link || job))) {
        return null;
    }

    let jt = getJTForDisplay(getJTVal());

    let creativeTitle = ad.creativeTitle;

    if (creativeTitle) {
        creativeTitle = Utils.matchAndReplaceParams(
            creativeTitle,
            getTitleReplaceParams(jt),
            Constants.CREATIVE_URL_PARAMS_REGEX,
        );
    }

    let URL;

    if (
        ad.customAdInfo.encodedUserData &&
        ad.customAdInfo.encodedUserData === '1' &&
        !Utils.isEmptyStr(customDestinationUrl)
    ) {
        URL = ad.customAdInfo.encodedUserDataMacroSupport
            ? Utils.matchAndReplaceParams(
                  customDestinationUrl,
                  getUrlReplaceParams(term, ad.customAdInfo),
                  Constants.CREATIVE_URL_PARAMS_REGEX,
              )
            : customDestinationUrl;
    } else if (job) {
        URL = job.url;
    } else {
        URL = ad.customAdInfo.link;
        URL = Utils.matchAndReplaceParams(
            URL,
            getUrlReplaceParams(term, ad.customAdInfo),
            Constants.CREATIVE_URL_PARAMS_REGEX,
        );
    }

    console.log('[JOBS][SERP] URL', URL);

    let lrdrFromUrl = Utils.getValForKeyFromCurrentUrl('lrdr');
    const creditScoreAdEnable =
        !Utils.isNull(lrdrFromUrl) &&
        !Utils.isEmptyStr(lrdrFromUrl) &&
        (lrdrFromUrl.includes('landing') || lrdrFromUrl.includes('survey'));

    const userDetails =
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS) &&
        JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS));
    const userFirstName =
        Utils.filterInvalidUrlInput(Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.FNAME)) ||
        (userDetails && userDetails['first_name']) ||
        false;

    const ShowTipBelowSERP = AbTestHelper.ShowTipBelowSERP();
    const getLinkBody = () => {
        if (newFulfillmentPage) {
            return (
                <>
                    <span className={classes.newStaticBranding}>
                        <picture>
                            <source
                                srcSet={RouterPrefixHandler.getLogoPath('logos/star-2.webp')}
                                type="image/webp"
                            />
                            <img
                                alt={`icon`}
                                className={classes.startIcon}
                                src={RouterPrefixHandler.getLogoPath('logos/star-2.png')}
                            />
                        </picture>
                        <span>NEW</span>
                    </span>
                    <div
                        className={`${classes.jobCardContainer} job-element-card static-feed`}
                        style={{ border: 'solid 2px #54ae02' }}>
                        <div className={classes.contentIconContainer}>
                            <div className="job-content-wrapper">
                                <div className="job-content-title-info">
                                    <div className={`job-logo-wrapper`}>
                                        <Avatar
                                            src={
                                                ad.creativeIcon && ad.creativeIcon.url
                                                    ? ad.creativeIcon.url
                                                    : RouterPrefixHandler.getLogoPath(
                                                          `logos/static-default.png`,
                                                      )
                                            }
                                            variant={'circle'}
                                        />
                                    </div>
                                    <div
                                        className="job-description-wrapper static-desc"
                                        style={{ textTransform: 'capitalize' }}>
                                        <Typography variant="h1">{creativeTitle}</Typography>
                                        {ad.creativeDescription1 && (
                                            <Typography variant="h1">
                                                {ad.creativeDescription1}
                                            </Typography>
                                        )}
                                        {ad.creativeDescription2 && (
                                            <Typography variant="h1">
                                                {ad.creativeDescription2}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.jobApplyWrapper}>
                                <div className={classes.staticFieldRight}>
                                    <ChevronRightTwoTone />
                                </div>
                            </div>
                        </div>
                        <span className={classes.underLine}></span>
                        <Link className={classes.staticAnc} href={URL} target="_blank">
                            <span>Click here to browse openings in your area</span>
                        </Link>
                    </div>
                </>
            );
        }

        if (isFulfillmentDesignPhaseTwo) {
            const city = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_CITY);
            const stateVar = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_REGION);
            const ljt = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_JT);

            return (
                <div
                    className={`${classes.jobCardContainer} job-element-card static-feed`}
                    style={{
                        boxShadow: '0 2px 4px 1px rgb(148 147 147 / 10%)',
                        border: 'solid 0.5px #d9d9d9',
                    }}>
                    <span className={classes.greyBoxHeader}>
                        {creativeTitle}.{' '}
                        {ad.creativeDescription1 &&
                            ad.creativeDescription1.replace('paying', 'Earn')}
                    </span>
                    <div className={classes.contentIconContainer}>
                        <div className="job-content-wrapper">
                            <div className="job-content-title-info">
                                <div className={`job-logo-wrapper`}>
                                    <Avatar
                                        src={
                                            ad.creativeIcon && ad.creativeIcon.url
                                                ? ad.creativeIcon.url
                                                : RouterPrefixHandler.getLogoPath(
                                                      `logos/static-default.png`,
                                                  )
                                        }
                                        variant={'circle'}
                                    />
                                </div>
                                <div
                                    className="job-description-wrapper static-desc"
                                    style={{ textTransform: 'capitalize' }}>
                                    <Typography variant="h1">{`${city}, ${stateVar}`}</Typography>
                                    <Typography
                                        className={classes.jobLandingKW}
                                        style={{ color: '#8e8e8e' }}
                                        variant="h2">
                                        {ljt}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className={classes.jobApplyWrapper}>
                            <div className={classes.staticFieldRight}>
                                <ChevronRightTwoTone />
                            </div>
                        </div>
                    </div>
                    <span className={classes.underLine}></span>
                    <Link className={classes.staticAnc} href={URL} target="_blank">
                        <span>Click here to browse openings in your area</span>
                    </Link>
                </div>
            );
        }
    };

    const titleElement =
        newFulfillmentPage || isFulfillmentDesignPhaseTwo ? (
            <div className={`${classes.titleBody} title`}>
                <span className="bold">
                    <span className={`${classes.titleHead} focus`}>
                        {userFirstName ? `${userFirstName}!` : ''}
                    </span>{' '}
                </span>
                We have located a job which is hiring immediately.
            </div>
        ) : (
            <div className="title">
                <span className="bold">
                    <span className="focus">Wait!</span>{' '}
                </span>
                We’ve located a job hiring immediately!
            </div>
        );

    return creditScoreAdEnable &&
        ad.customAdInfo &&
        ad.customAdInfo.uiTemplate &&
        ad.customAdInfo.uiTemplate === 'creditScore' ? (
        <CreditScoreListings
            URL={URL}
            ad={ad}
            creativeTitle={creativeTitle}
            isFulfillmentDesignPhaseTwo={isFulfillmentDesignPhaseTwo}
            job={job}
            newFulfillmentPage={newFulfillmentPage}
            week={week}
        />
    ) : (
        <React.Fragment>
            {showTitle && titleElement}
            {newFulfillmentPage || isFulfillmentDesignPhaseTwo ? (
                <div className={`jobs-list-wrapper static-list ${week}`}>
                    <Link
                        className={'link-container'}
                        href={URL}
                        onClick={e => {
                            const eventData = {
                                advid: ad.advId,
                                advnm: ad.advertiserName,
                                cmpid: ad.campaignId,
                                cmpnm: ad.campaignName,
                                cat: 'AdUnit',
                                ...(!Utils.isNull(position) && { spotno: position }),
                            };

                            if (ad.customAdInfo.cpa) {
                                eventData.cpa = ad.customAdInfo.cpa;
                            }
                            EventManager.sendEvent(
                                Constants.EVENT.AD_CLICK,
                                { ...eventData, job_redirect_url: URL, cat: 'AdUnit' },
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );

                            if (ad.revType.toLowerCase() === 'cpc') {
                                EventManager.sendEvent(
                                    Constants.EVENT.REVENUE,
                                    {
                                        ervn: ad.bid,
                                        ...eventData,
                                    },
                                    Constants.EVENT_TYPE.GRAITIFICATION,
                                );
                            } else if (
                                ad.revType.toLowerCase() === 'cpa' &&
                                ad.customAdInfo &&
                                ad.customAdInfo.useBid === '1' &&
                                job.bid
                            ) {
                                EventManager.sendEvent(
                                    Constants.EVENT.REVENUE,
                                    {
                                        ervn: job.bid,
                                        ...eventData,
                                    },
                                    Constants.EVENT_TYPE.GRAITIFICATION,
                                );
                            }

                            if (ad.onClickTrackingPixels && ad.onClickTrackingPixels.length) {
                                const adsData = OfferManager.getAdsData(ad);
                                const eventUrl = EventManager.createAndGetEventUrl(
                                    Constants.EVENT.MAX_CLICK,
                                    adsData,
                                    Constants.EVENT_TYPE.ADS,
                                );
                                const clickParams = {
                                    url: encodeURIComponent(ad.onClickTrackingPixels[0]),
                                    eventUrl: encodeURIComponent(eventUrl),
                                };
                                Network.get(
                                    `${UrlManager.getAdClickUrl()}?${Utils.encodeObjectToUrlArgs(
                                        clickParams,
                                    )}`,
                                ).catch(err => {
                                    console.log(err);
                                });
                            }
                            Utils.setCookie(`${getAdDedupeKey(ad)}-click`, 1, 60);
                        }}
                        target="_blank">
                        {getLinkBody()}
                    </Link>
                </div>
            ) : (
                <div className={`jobs-list-wrapper static-list ${week}`}>
                    <Link
                        className={'link-container'}
                        href={URL}
                        onClick={e => {
                            const eventData = {
                                advid: ad.advId,
                                advnm: ad.advertiserName,
                                cmpid: ad.campaignId,
                                cmpnm: ad.campaignName,
                                cat: 'AdUnit',
                                ...(!Utils.isNull(position) && { spotno: position }),
                            };

                            if (ad.customAdInfo.cpa) {
                                eventData.cpa = ad.customAdInfo.cpa;
                            }
                            EventManager.sendEvent(
                                Constants.EVENT.AD_CLICK,
                                { ...eventData, job_redirect_url: URL, cat: 'AdUnit' },
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );

                            if (ad.revType.toLowerCase() === 'cpc') {
                                EventManager.sendEvent(
                                    Constants.EVENT.REVENUE,
                                    {
                                        ervn: ad.bid,
                                        ...eventData,
                                    },
                                    Constants.EVENT_TYPE.GRAITIFICATION,
                                );
                            } else if (
                                ad.revType.toLowerCase() === 'cpa' &&
                                ad.customAdInfo &&
                                ad.customAdInfo.useBid === '1' &&
                                job.bid
                            ) {
                                EventManager.sendEvent(
                                    Constants.EVENT.REVENUE,
                                    {
                                        ervn: job.bid,
                                        ...eventData,
                                    },
                                    Constants.EVENT_TYPE.GRAITIFICATION,
                                );
                            }

                            if (ad.onClickTrackingPixels && ad.onClickTrackingPixels.length) {
                                const adsData = OfferManager.getAdsData(ad);
                                const eventUrl = EventManager.createAndGetEventUrl(
                                    Constants.EVENT.MAX_CLICK,
                                    adsData,
                                    Constants.EVENT_TYPE.ADS,
                                );
                                const clickParams = {
                                    url: encodeURIComponent(ad.onClickTrackingPixels[0]),
                                    eventUrl: encodeURIComponent(eventUrl),
                                };
                                Network.get(
                                    `${UrlManager.getAdClickUrl()}?${Utils.encodeObjectToUrlArgs(
                                        clickParams,
                                    )}`,
                                ).catch(err => {
                                    console.log(err);
                                });
                            }
                            Utils.setCookie(`${getAdDedupeKey(ad)}-click`, 1, 60);
                        }}
                        target="_blank">
                        <span className="static-branding-txt">
                            <picture>
                                <source
                                    srcSet={RouterPrefixHandler.getImagePath('images/bolt.webp')}
                                    type="image/webp"
                                />
                                <img
                                    alt={`icon`}
                                    className={'bolt-icon'}
                                    src={RouterPrefixHandler.getImagePath('images/bolt.png')}
                                />
                            </picture>
                            <span>JUST POSTED</span>
                        </span>

                        <div className="job-element-card static-feed">
                            <div className="job-content-wrapper">
                                <div className="job-content-title-info">
                                    <div className="job-logo-wrapper static-logo-wrapper MuiAvatar-root">
                                        <picture>
                                            <img
                                                alt={`icon`}
                                                className={' MuiAvatar-img static-logo'}
                                                src={
                                                    ad.creativeIcon && ad.creativeIcon.url
                                                        ? ad.creativeIcon.url
                                                        : RouterPrefixHandler.getLogoPath(
                                                              `logos/static-default.png`,
                                                          )
                                                }
                                            />
                                        </picture>
                                    </div>
                                    <div
                                        className="job-description-wrapper static-desc"
                                        style={{ textTransform: 'capitalize' }}>
                                        <Typography variant="h1">{creativeTitle}</Typography>
                                        {ad.creativeDescription1 && (
                                            <Typography variant="h1">
                                                {ad.creativeDescription1}
                                            </Typography>
                                        )}
                                        {ad.creativeDescription2 && (
                                            <Typography variant="h1">
                                                {ad.creativeDescription2}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                                <Link className="static-anc" href={URL} target="_blank">
                                    <span>Click here to browse openings in your area</span>
                                </Link>
                            </div>
                            <div className="job-apply-wrapper">
                                <div className="static-feed-right">
                                    <picture>
                                        <source
                                            srcSet={RouterPrefixHandler.getImagePath(
                                                'images/arrow2.webp',
                                            )}
                                            type="image/webp"
                                        />
                                        <img
                                            alt={`icon`}
                                            className={'arrow-icon'}
                                            src={RouterPrefixHandler.getImagePath(
                                                'images/arrow2.png',
                                            )}
                                        />
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div className="jobs-list-wrapper">
                        {ShowTipBelowSERP ? (
                            <Typography className="job-list-header">
                                <span className="bold">Tip:</span> Choose all jobs that you’re
                                interested in, we’ll send you alerts for more jobs like these.
                            </Typography>
                        ) : null}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

const staticImp = {};

const getTitleReplaceParams = kw => {
    const replaceParams = {};
    replaceParams[Constants.CREATIVE_URL_PARAMS.KEYWORD] = kw;

    return replaceParams;
};

const getUrlReplaceParams = (term, formatting) => {
    const replaceParams = {};
    const userDetails =
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS) &&
        JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS));

    const pubSourceTypeConfig =
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG) &&
        JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG));

    if (pubSourceTypeConfig) {
        let rawEncodedSource = '';

        if (pubSourceTypeConfig.utmSource)
            rawEncodedSource = rawEncodedSource + pubSourceTypeConfig.utmSource;

        if (pubSourceTypeConfig.pubSourceType)
            rawEncodedSource = rawEncodedSource + pubSourceTypeConfig.pubSourceType;

        const base64Encoded = btoa(`${rawEncodedSource.trim()}`);
        replaceParams[Constants.CREATIVE_URL_PARAMS.V2_ENCODED_SOURCE] = base64Encoded;
    }

    if (userDetails) {
        replaceParams[Constants.CREATIVE_URL_PARAMS.FIRST_NAME] =
            userDetails[Constants.USER_DETAILS_KEY.FIRST_NAME];
        replaceParams[Constants.CREATIVE_URL_PARAMS.LAST_NAME] =
            userDetails[Constants.USER_DETAILS_KEY.LAST_NAME];
        replaceParams[Constants.CREATIVE_URL_PARAMS.EMAIL] =
            userDetails[Constants.USER_DETAILS_KEY.EMAIL];
        replaceParams[Constants.CREATIVE_URL_PARAMS.PHONE] =
            userDetails[Constants.USER_DETAILS_KEY.PHONE];
        replaceParams[Constants.CREATIVE_URL_PARAMS.DOB] =
            userDetails[Constants.USER_DETAILS_KEY.DOB];
        replaceParams[Constants.CREATIVE_URL_PARAMS.GENDER] =
            userDetails[Constants.USER_DETAILS_KEY.GENDER];
        replaceParams[Constants.CREATIVE_URL_PARAMS.USER_STATUS] = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.USER_STATUS,
        );

        const address = userDetails[Constants.USER_DETAILS_KEY.ADDRESS];
        let addressLine1 = '';
        let addressLine2 = '';

        if (address) {
            addressLine1 = address.line1;
            addressLine2 = address.line2 || '';
        }
        replaceParams[Constants.CREATIVE_URL_PARAMS.ADDRESS] = addressLine1;
        replaceParams[Constants.CREATIVE_URL_PARAMS.ADDRESS2] = addressLine2;
    }

    if (!replaceParams[Constants.CREATIVE_URL_PARAMS.FIRST_NAME]) {
        replaceParams[Constants.CREATIVE_URL_PARAMS.FIRST_NAME] = Utils.getValForKeyFromCurrentUrl(
            Constants.URL_ARGS.FNAME,
        );
    }

    if (!replaceParams[Constants.CREATIVE_URL_PARAMS.LAST_NAME]) {
        replaceParams[Constants.CREATIVE_URL_PARAMS.LAST_NAME] = Utils.getValForKeyFromCurrentUrl(
            Constants.URL_ARGS.LNAME,
        );
    }

    if (!replaceParams[Constants.CREATIVE_URL_PARAMS.EMAIL]) {
        replaceParams[Constants.CREATIVE_URL_PARAMS.EMAIL] = Utils.getValForKeyFromCurrentUrl(
            Constants.URL_ARGS.EMAIL,
        );
    }

    if (!replaceParams[Constants.CREATIVE_URL_PARAMS.PHONE]) {
        replaceParams[Constants.CREATIVE_URL_PARAMS.PHONE] = Utils.getValForKeyFromCurrentUrl(
            Constants.URL_ARGS.PHONE,
        );
    }

    if (!replaceParams[Constants.CREATIVE_URL_PARAMS.DOB]) {
        replaceParams[Constants.CREATIVE_URL_PARAMS.DOB] = Utils.getValForKeyFromCurrentUrl(
            Constants.URL_ARGS.DOB,
        );
    }

    if (!replaceParams[Constants.CREATIVE_URL_PARAMS.GENDER]) {
        replaceParams[Constants.CREATIVE_URL_PARAMS.GENDER] = Utils.getValForKeyFromCurrentUrl(
            Constants.URL_ARGS.GENDER,
        );
    }

    replaceParams[Constants.CREATIVE_URL_PARAMS.SUBID] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.PUBLISHER_SUBID,
    );

    if (!replaceParams[Constants.CREATIVE_URL_PARAMS.GEO_CITY]) {
        replaceParams[Constants.CREATIVE_URL_PARAMS.GEO_CITY] = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.GEO_CITY,
        );
    }

    if (!replaceParams[Constants.CREATIVE_URL_PARAMS.GEO_STATE]) {
        replaceParams[Constants.CREATIVE_URL_PARAMS.GEO_STATE] = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.GEO_REGION,
        );
    }

    replaceParams[Constants.CREATIVE_URL_PARAMS.PRODUCT] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.PRODUCT,
    );

    replaceParams[Constants.CREATIVE_URL_PARAMS.ZIP_CODE] = term.location || '';
    replaceParams[Constants.CREATIVE_URL_PARAMS.KEYWORD] = Utils.cleanKwDisplay(
        getKWTerm(getJTVal()),
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.UTM_SOURCE] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.UTM_SOURCE,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.UTM_MEDIUM] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.UTM_MEDIUM,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.SID] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.UUID,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.CLID] = IdStore.fetchIdForKey(
        Constants.URL_ARGS.CLID,
    );

    replaceParams[Constants.CREATIVE_URL_PARAMS.ENCODED_SOURCE] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.NEW_ENCODED_SUBID,
    );
    replaceParams[Constants.CREATIVE_URL_PARAMS.SHORT_ENCODED_SOURCE] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.NEW_ENCODED_SUBID,
    );

    replaceParams[Constants.CREATIVE_URL_PARAMS.JOB_TYPE] = Utils.cleanKwDisplay(
        IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL),
    );

    replaceParams[Constants.CREATIVE_URL_PARAMS.KW_OVER_JT] =
        replaceParams[Constants.CREATIVE_URL_PARAMS.KEYWORD] ||
        replaceParams[Constants.CREATIVE_URL_PARAMS.JOB_TYPE];
    replaceParams[Constants.CREATIVE_URL_PARAMS.JT_OVER_KW] =
        replaceParams[Constants.CREATIVE_URL_PARAMS.JOB_TYPE] ||
        replaceParams[Constants.CREATIVE_URL_PARAMS.KEYWORD];

    if (formatting) {
        if (replaceParams[Constants.CREATIVE_URL_PARAMS.DOB] && formatting.dob_format) {
            replaceParams[Constants.CREATIVE_URL_PARAMS.DOB] = moment(
                replaceParams[Constants.CREATIVE_URL_PARAMS.DOB],
            ).format(formatting.dob_format);
        }
    }

    if (replaceParams[Constants.CREATIVE_URL_PARAMS.DOB]) {
        const dateFormat = moment(
            new Date(replaceParams[Constants.CREATIVE_URL_PARAMS.DOB]),
            'MM-DD-YYYY',
        );
        replaceParams[Constants.CREATIVE_URL_PARAMS.DOB_MONTH] = dateFormat.format('MM');
        replaceParams[Constants.CREATIVE_URL_PARAMS.DOB_DAY] = dateFormat.format('DD');
        replaceParams[Constants.CREATIVE_URL_PARAMS.DOB_YEAR] = dateFormat.format('YYYY');
        replaceParams[Constants.CREATIVE_URL_PARAMS.AGE] = moment().diff(
            replaceParams[Constants.CREATIVE_URL_PARAMS.DOB],
            'years',
            false,
        );
    }

    if (replaceParams[Constants.CREATIVE_URL_PARAMS.PHONE]) {
        replaceParams[Constants.CREATIVE_URL_PARAMS.PHONE] = replaceParams[
            Constants.CREATIVE_URL_PARAMS.PHONE
        ].replace(/[()\-\s]+/gi, '');
        replaceParams[Constants.CREATIVE_URL_PARAMS.PHONE_CODE] = replaceParams[
            Constants.CREATIVE_URL_PARAMS.PHONE
        ].substring(0, 3);
        replaceParams[Constants.CREATIVE_URL_PARAMS.PHONE_PREFIX] = replaceParams[
            Constants.CREATIVE_URL_PARAMS.PHONE
        ].substring(3, 6);
        replaceParams[Constants.CREATIVE_URL_PARAMS.PHONE_SUFFIX] = replaceParams[
            Constants.CREATIVE_URL_PARAMS.PHONE
        ].substring(6);
    }

    replaceParams[Constants.CREATIVE_URL_PARAMS.IS_MOBILE] = Utils.checkMobileDevice() ? '1' : '0';

    return replaceParams;
};

const getJTForDisplay = initTerm => {
    let jt = initTerm === '' || initTerm === 'Jobs near me' ? '' : Utils.getCapitalizeStr(initTerm);
    jt = Utils.cleanKwDisplay(jt);

    if (jt) {
        jt = `${jt} Jobs`;
    } else jt = 'Jobs';

    return jt;
};

const getKWTerm = initTerm => {
    return initTerm === 'Jobs near me' || initTerm === '' ? Constants.DEFAULT_KW : initTerm;
};

const getJTVal = () => {
    // Resolved from URL PARAMS
    let { jobType } = Utils.getJobType();
    const kw = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);

    if (!Utils.isEmptyStr(kw)) return kw;

    if (!Utils.isEmptyStr(jobType)) return jobType;

    jobType = IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL);

    if (!Utils.isEmptyStr(jobType)) return jobType;

    jobType = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

    if (!Utils.isEmptyStr(jobType)) return jobType;

    return 'Jobs near me';
};

const getUserPrePopData = allowPrePop1 => {
    const userDetails =
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS) &&
        JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS));

    const isPrePopulated =
        (!Utils.isNull(userDetails) &&
            userDetails[Constants.USER_DETAILS_KEY.FIRST_NAME] &&
            userDetails[Constants.USER_DETAILS_KEY.LAST_NAME] &&
            userDetails[Constants.USER_DETAILS_KEY.EMAIL] &&
            userDetails[Constants.USER_DETAILS_KEY.PHONE] &&
            userDetails[Constants.USER_DETAILS_KEY.DOB] &&
            userDetails[Constants.USER_DETAILS_KEY.ADDRESS]) ||
        Utils.hasNonEmptyPIIFieldsFromUrl();

    const allowPartialPrePop =
        allowPrePop1 &&
        !Utils.isNull(userDetails) &&
        !!userDetails[Constants.USER_DETAILS_KEY.FIRST_NAME] &&
        !!userDetails[Constants.USER_DETAILS_KEY.LAST_NAME] &&
        !!userDetails[Constants.USER_DETAILS_KEY.EMAIL];

    if (isPrePopulated || allowPartialPrePop) {
        let email = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EMAIL) || '';
        let firstName = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.FNAME) || '';
        let lastName = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.LNAME) || '';
        let addr = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ADDRESS);

        firstName = Utils.filterInvalidUrlInput(firstName);
        lastName = Utils.filterInvalidUrlInput(lastName);
        email = Utils.filterInvalidUrlInput(email);
        addr = Utils.filterInvalidUrlInput(addr);

        const urlPhone = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE) || '',
        );
        const prePopUserData = {
            first_name: firstName || userDetails.first_name,
            last_name: lastName || userDetails.last_name,
            email: email || userDetails.email,
            phone: (urlPhone || userDetails.phone_number || '').replace(/\D/g, ''),
            address:
                addr ||
                `${Utils.getObjVal(userDetails, ['address', 'line1'])} ${
                    Utils.getObjVal(userDetails, ['address', 'line2'])
                        ? Utils.getObjVal(userDetails, ['address', 'line1'])
                        : ''
                }`,
            city: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_CITY),
            state: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_REGION),
            zip: IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE),
        };

        return [isPrePopulated || allowPartialPrePop, prePopUserData];
    }

    return [false, {}];
};

const StaticJobListing = props => {
    const [adUnit, setAdUnit] = useState();
    const [job, setJob] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isDuplicate, setDuplicate] = useState(true);
    const [customDestinationUrl, setCustomDestinationUrl] = useState('');
    const [isProxyCallSuccess, setProxyCall] = useState(true);

    const jtVal = getJTVal();
    const jt = getJTForDisplay(jtVal);
    const history = useHistory();
    const { pathname } = history.location;
    const { newFulfillmentPage, isFulfillmentDesignPhaseTwo, dedupeMap } = props;
    const classes = styles();

    const fetchJobResults = async (advertiserName, target, cpcfloor) => {
        try {
            const source = isValidAdvertiser(advertiserName);

            if (!Utils.isEmptyStr(source)) {
                const { queryTerm, location } = props.term;
                const jobQuery = JobSearchDataSource.createJobsQueryForTerm({
                    term: queryTerm,
                    pageNum: 1,
                    pageSize: 1,
                    location,
                    source,
                    target,
                    cpcfloor,
                });
                const jobsData = await JobSearchDataSource.fetchJobsDataForQuery(jobQuery);
                const { jobs } = jobsData;

                if (jobs && jobs.length) {
                    return jobs[0];
                }
            }

            return null;
        } catch (err) {
            throw err;
        }
    };

    const getUrlParams = () => {
        const allUrlParams = Utils.getCurrentUrlParams();

        if (Object.keys(allUrlParams).includes('lrdr')) {
            if (allUrlParams['lrdr'].includes('landing')) {
                allUrlParams['lrdr'] = 'landing';
            }

            if (allUrlParams['lrdr'].includes('survey')) {
                allUrlParams['lrdr'] = 'survey';
            }
        }

        return allUrlParams;
    };

    useEffect(() => {
        const allUrlParams = getUrlParams();

        if (!adUnit) {
            const targetingParams = TargetingManager.fetchTargetingParams({
                extraParams: {
                    kw: getKWTerm(jtVal),
                    ...allUrlParams,
                },
                geoParams: {
                    zip: props.term.location || '',
                },
            });
            const adUnitId = props.adUnitId;

            const fetchAdsData = async () => {
                try {
                    let fallbacks = [];

                    for (let i = -1; i < 2; i++) {
                        let adUnitData;

                        if (i < 0) {
                            adUnitData = await AdsDataSource.fetchAdsForAdUnit(
                                targetingParams,
                                adUnitId,
                            );

                            if (adUnitData && adUnitData.fallbacks && adUnitData.fallbacks.length) {
                                fallbacks = adUnitData.fallbacks;
                            }
                        } else if (i < fallbacks.length) {
                            const fb = fallbacks[i];
                            const singleAdUnitData = await AdsDataSource.fetchSingleAdForAdUnit(fb);
                            adUnitData = { ad: singleAdUnitData };
                        } else {
                            break;
                        }

                        const cookie =
                            adUnitData && adUnitData.ad
                                ? Utils.getCookie(`${getAdDedupeKey(adUnitData.ad)}-click`)
                                : null;

                        if (!cookie) {
                            const { advertiserName, customAdInfo } = adUnitData.ad;

                            if (customAdInfo) {
                                const {
                                    type,
                                    target,
                                    cpcfloor,
                                    encodedUserData,
                                    allowPartialPrePop,
                                    param,
                                    link,
                                    singleEncodedUserDataField,
                                } = customAdInfo;
                                const { JOB } = Constants.AD_UNIT.CUSTOM_AD_INFO.TYPE;

                                if (type === JOB) {
                                    const jobResult = await fetchJobResults(
                                        advertiserName,
                                        target,
                                        cpcfloor,
                                    );

                                    if (jobResult) {
                                        setJob(jobResult);
                                    } else {
                                        continue;
                                    }
                                }

                                const [isPrePopulated, prePopUserData] = getUserPrePopData(
                                    allowPartialPrePop === '1',
                                );

                                if (encodedUserData === '1' && isPrePopulated) {
                                    let queryParams = '';

                                    if (singleEncodedUserDataField) {
                                        queryParams = prePopUserData[singleEncodedUserDataField];
                                    } else {
                                        for (let param in prePopUserData) {
                                            {
                                                let tempString = queryParams;
                                                queryParams = tempString.concat(
                                                    `${param}=${encodeURIComponent(
                                                        prePopUserData[param],
                                                    )}`,
                                                    '&',
                                                );
                                            }
                                        }
                                    }

                                    const payload = {
                                        url: UrlManager.getAes256CbcEncodedUrl(queryParams),
                                        options: {
                                            method: 'GET',
                                        },
                                    };
                                    const encodedURLResponse = await fetch(
                                        Constants.CONNEXUS_PROXY_URL,
                                        {
                                            method: 'POST',
                                            body: JSON.stringify(payload),
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                        },
                                    ).then(response => response.json());

                                    if (encodedURLResponse && encodedURLResponse.encryptedData) {
                                        const customUrl = `${link}&${param}=${encodedURLResponse.encryptedData}`;
                                        setCustomDestinationUrl(customUrl);
                                    } else {
                                        console.error(`Error encoding user Data`);
                                        setIsLoading(false);
                                        setProxyCall(false);
                                    }
                                }
                            }

                            const dedupeKey = getAdDedupeKey(adUnitData.ad);

                            if (!dedupeMap[dedupeKey]) {
                                console.log('Debug Non Dupe', dedupeKey);
                                dedupeMap[dedupeKey] = true;
                                setAdUnit(adUnitData);
                                setDuplicate(false);
                            }
                            break;
                        }
                    }
                    setIsLoading(false);
                } catch (err) {
                    console.error(`Error fetching ad unit data: ${err}`);
                    setIsLoading(false);
                }
            };

            fetchAdsData();
        }
    }, []);

    if (adUnit && isProxyCallSuccess) {
        const { ad } = adUnit;

        if (
            !(isLoading || isDuplicate) &&
            ad &&
            (!(ad.customAdInfo && ad.customAdInfo.type === 'job') || job)
        ) {
            if (
                pathname.includes(
                    RouterPrefixHandler.getRouteTemplateForPath(
                        Constants.PAGE_ENDPOINTS.GRATIFICATION,
                    ),
                )
            ) {
                const adImpKey = `${jt}-${ad.advId}-${ad.campaignId}`;

                if (!staticImp[adImpKey]) {
                    staticImp[adImpKey] = 1;
                    EventManager.sendEvent(
                        Constants.EVENT.ADS_IMP,
                        {
                            advid: ad.advId,
                            advnm: ad.advertiserName,
                            cmpid: ad.campaignId,
                            cmpnm: ad.campaignName,
                            cat: 'AdUnit',
                            ...(!Utils.isNull(props.position) && { spotno: props.position }),
                        },
                        Constants.EVENT_TYPE.GRAITIFICATION,
                    );

                    const impVEventData = {
                        advid: ad.advId,
                        advnm: ad.advertiserName,
                        cmpid: ad.campaignId,
                        cmpnm: ad.campaignName,
                        cat: 'AdUnit',
                        ...(!Utils.isNull(props.position) && { spotno: props.position }),
                    };

                    EventManager.sendEvent(
                        Constants.EVENT.AD_IMP_V,
                        impVEventData,
                        Constants.EVENT_TYPE.GRAITIFICATION,
                    );

                    OfferManager.fireTrackingPixelsList(ad.trackingPixels);
                }
            }

            return (
                <div className="static-listings-wrapper listing-item content">
                    {renderDefaultHtml(
                        ad,
                        props.term,
                        props.weekCls,
                        job,
                        newFulfillmentPage,
                        isFulfillmentDesignPhaseTwo,
                        classes,
                        props.showBannerText,
                        props.position,
                        customDestinationUrl,
                    )}
                </div>
            );
        }
    }

    return <div className="listing-item"></div>;
};

const styles = makeStyles({
    titleHead: {
        color: '#57b101 !important',
    },
    titleBody: {
        color: '#245475 !important',
        fontWeight: 'normal !important',
        lineHeight: '1.29 !important',
        marginBottom: 14,
    },
    newStaticBranding: {
        backgroundColor: '#f90319',
        color: '#fff',
        height: 20,
        borderRadius: '0 4px',
        display: 'inline-flex',
        alignItems: 'center',
        width: 'auto',
        position: 'absolute',
        right: 0,
        fontFamily: 'Roboto',
        fontSize: 11,
        lineHeight: 11,
        padding: '0px 5px',
    },

    startIcon: {
        height: 11,
        paddingRight: 2,
    },
    jobCardContainer: {
        flexDirection: 'column !important',
        margin: '0px !important',
    },
    staticFieldRight: {
        width: 25,
        height: 25,
        backgroundColor: '#54ae00',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        borderRadius: '50%',
        margin: 'auto 20px',
        padding: 2,
    },
    contentIconContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    jobApplyWrapper: {
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'flex-end',
        justifyContent: 'center',
        paddingLeft: 4,
        width: '20%',
    },
    underLine: {
        width: 'auto',
        height: 1,
        margin: '0px 10px 10px',
        borderBottom: 'solid 0.5px #d9d9d9',
    },
    staticAnc: {
        padding: '0px 10px 8px',
        textDecoration: 'none',
        color: '#007aff',
        fontSize: 14,
    },
    greyBoxHeader: {
        borderRadius: '4px 0px 0px',
        backgroundColor: '#e8e8e8',
        padding: 8,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.36,
        color: '#262831',
        textTransform: 'capitalize',
    },
    jobLandingKW: {
        margin: '2px 0px',
    },
});

export default StaticJobListing;
