import TargetingManager from 'targetingManager';
import AdsDataSource from 'datasource/adsDataSource';
import Utils from 'common/utils';
import Constants from 'common/constants';
import { jobTypedAdUnitValidatorWithEventsV1 } from './adUnitDataValidators';
import { IdStore, MemoryStore } from 'dataStore/index';
import memoryStoreKeys from 'common/memoryStoreKeys';

const _skipToTabInValidAuCb = () => {
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.AB_TEST_23, 'control');
    IdStore.sync();
};

export const fetchSkipToTabAdUnit = () =>
    AdsDataSource.fetchAdsForAdUnitFbs(
        TargetingManager.fetchTargetingParams(),
        Utils.isTestEnv()
            ? Constants.AD_UNIT_ID.SKIP_TO_NEW_TAB_AU.STAG
            : Constants.AD_UNIT_ID.SKIP_TO_NEW_TAB_AU.PROD,
        jobTypedAdUnitValidatorWithEventsV1,
    )
        .then(adUnitData => {
            if (Utils.getObjVal(adUnitData, ['isValid'])) {
                MemoryStore.setItem(memoryStoreKeys.SKIP_TO_TAB_AD_UNIT_DATA, adUnitData);
            } else {
                _skipToTabInValidAuCb();
            }
        })
        .catch(err => {
            _skipToTabInValidAuCb();
        });
