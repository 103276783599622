import { getFlavor } from 'flavors.macro';

const flavor = getFlavor('layout-theme');
module.exports = {
    SINGLE_VALUED: 'single value',
    MULTI_VALUED: 'multi value',
    VALID_DATE_FORMATS: ['M-D-YYYY', 'D-M-YYYY', 'YYYY-M-D', 'YYYY-D-M'],
    AMERICAN_AIRLINES: 'american airlines',
    RG_GROCERY_LOGOS: ['walmart', 'kroger', 'whole_foods'],
    DEFAULT_NETWORK_TIMEOUT_MS: 10000,
    DEFAULT_SURVEY_TITLE: 'Survey',
    DEFAULT_KW: 'Full Time Jobs',
    SUBJECTWELL_CLINIC_TRIAL_TCPA: userPhoneNumber =>
        `I acknowledge that by submitting this form, I may be contacted by phone by a representative of Northwest Medical or SubjectWell or receive SMS text messages at ${userPhoneNumber} from`,
    MULTIPLE_COMPANIES: 'Multiple Companies',
    GOOGLE_DISPLAY_LANDER_VARIANT_TEXT: 'Your next job awaits',
    RESTRICT_NOTIFICATION_HOURS: 24,
    NAME_ON_CARD_LENGTH: 13,
    RESTRICT_NOTIFICATION: 'restrict_notification',
    GOOGLE_DISPLAY_LANDER_VARIANT_CTA:
        'MyJobsCorner.com is a job search engine. We are not an agent or representative of any employer.All company names are the registered trademarks of their original owners. The use of any trade name or trademark does not imply any association with the third-party company.',
    PREMIUM_JOB_PROGRESS_BAR_TEXT: 'Premium Job Found! Complete your profile to view it',
    NET_SPEND_DESKTOP_TCPA: `Subject to card activation and ID verification.Terms, costs and other fees apply. Card issued by Pathward, N.A., Member FDIC.`,
    DVM_CREDIT_REPAIR_CTA: phone =>
        `By clicking "Yes" I agree by electronic signature to: 
        (1) be contacted by Lexington Law Firm about credit repair or 
        credit repair marketing by a live agent, artificial or prerecorded voice, 
        and SMS text at the phone number provided, ${phone}, dialed manually or by 
        auto-dialer, and by email (consent to be contacted is not a condition to 
        purchase services); and (2) the`,
    LIGHT_FIRE_AI_TCPA: userPhoneNumber =>
        `By clicking 'YES', I provide my signature expressly consenting to contact from <a href="https://autoprotectors.us/" target="_blank">AutoProtectors.us</a> at ${userPhoneNumber} using live agent, automated telephone dialing systems, pre-recorded messages, artificial voice, text/SMS messages and/or emails, even if the number you provide is on a Do Not Call registry. Message and data rates may apply. Your consent is not required to get a quote and you may revoke consent at any time. You are in no way required to purchase any products or services.`,
    GOOGLE_DISPLAY_LANDER_VARIANT_JOB_LIST_V3: [
        'Full time jobs',
        'Online Jobs',
        'Part time jobs',
        'Entry Level jobs',
    ],
    GOOGLE_DISPLAY_LANDER_VARIANT_JOB_LIST_V2: [
        'Full time jobs',
        'Part time jobs',
        'Entry Level jobs',
        'Online Jobs',
    ],
    GOOGLE_DISPLAY_LANDER_VARIANT_JOB_LIST_V1: [
        'Full time jobs',
        'Part time jobs',
        'Online Jobs',
        'Entry Level jobs',
    ],
    THEME: {
        MYJOBSCORNER: 'mjc',
        MYJOBSCORNERV2: 'mjcv2',
        GREEN: 'green',
        BLUE: 'blue',
    },
    EDU: {
        V3: 'dropdown-button-hybrid',
        V2: 'dropdown-test-v2',
        V1: 'controlv2',
    },
    /*
     * UPWARDRG: {
     *     V2: 'upward_rg_v2',
     *     V1: 'upward_rg_v1'
     * },
     */
    PATH_ARGS: {
        LANDER_NAME: 'landerName',
        LANDER_PATH_NAME: 'landerPathName',
    },
    URL_ARGS: {
        SURVEY_ID: 'sid',
        SURVEY_PATH_ID: 'spid',
        USER_ID: 'uid',
        UTM_SOURCE: 'utm_source',
        UTM_MEDIUM: 'utm_medium',
        UTM_CAMPAIGN: 'utm_campaign',
        UTM_TERM: 'utm_term',
        UTM_CONTENT: 'utm_content',
        UTM_SOURCE_G: 'p',
        UTM_MEDIUM_G: 'ch',
        UTM_CAMPAIGN_G: 'cm',
        UTM_TERM_G: 'kw',
        UTM_CONTENT_G: 'cnt',
        PREVIEW: 'preview',
        ADGROUP_ID: 'ag',
        CREATIVE_ID: 'cr',
        PLACEMENT_ID: 'pl',
        TARGET_ID: 'tid',
        CLID: 'clid',
        FBCLID: 'fbclid',
        FNAME: 'pifnm',
        LNAME: 'pilnm',
        GENDER: 'pigd',
        DOB: 'pibd',
        ADDRESS: 'piaddr',
        PHONE: 'piphnm',
        EMAIL: 'piem',
        SHOW_OTHER_GENDER_OPTION: 'ogen',
        DISABLE_REG2_OPT_IN: 'dr2o',
        SHOW_INTERSTITIAL_ADS: 'shw_intrtial_ads',
        HOURLY_PAY: 'hp',
        PUBLISHER_SUBID: 'ps',
        PUBLISHER_SUB_SOURCE: 'pss',
        JOB_DETAILS: 'jb_details',
        SOURCE: 'source',
        ZIP: 'z',
        ENCRYPTED_EMAIL: 'pieem',
        INITIAL_VECTOR: 'pieiv',
        EVERFLOW_TRANSACTION_ID: 'etid',
        REDIRECT_URL: 'redirect_url',
        P_DOMAIN: 'pdomain',
        MULTI_BRAND_LOGO: 'l',
        BASE_API_URL: 'BASE_API_URL',
        PUBLISHER_COST_BASE_URL: 'PUBLISHER_COST_BASE_URL',
        CONFIGURATION_BASE_URL: 'CONFIGURATION_BASE_URL',
        EXT1: 'ext1',
        EXT2: 'ext2',
        EXT3: 'ext3',
        EXT4: 'ext4',
        EXT5: 'ext5',
        O_TS: 'ots',
        O_KEYWORD: 'okeyword',
        O_MID: 'omid',
        O_SOURCE: 'osource',
    },
    ID_STORE_KEYS: {
        PRODUCT: 'product',
        BRINKS_MONEY: 'brinks_money',
        BRINKS_MONEY_CAMPAIGN_RESPONDING: 'brinks_money_campaign_active',
        KW_CAT: 'kwcat',
        LANDING_KEYWORD: 'lkw',
        LANDING_JT: 'ljt',
        LANDING_URL: 'lurl',
        JT_KW_MISMATCH: 'jtkw_mis',
        IS_KW_DEDUCED: 'kw_deduced',
        BACK_INTERSTITIAL: 'bck_int',
        CURRENT_APP: 'current_app',
        AD_SOURCE: 'ad_source',
        REG2_TRUSTED_FORM_CERT_URL: 'reg2_tf_curl',
        REG2_LEADID: 'reg2_leadid',
        LISTING_BG_REG2_SKIPPED: 'listing_bg_reg2_skipped',
        AARP_PREPING: 'aarp_preping',
        FLATIRON_MEDIA_PREPING: 'flatiron_preping',
        PENTIUS_PREPING: 'pentius_preping',
        JOBCASE_PREPING: 'jobcase_preping',
        DVM_CREDIT_REPAIR: 'dvm_credit_repair',
        LIGHT_FIRE_AI_PREPING: 'light_fire_ai_preping',
        LIGHT_FIRE_AI: 'light_fire_ai',
        LIGHT_FIRE_AI_2: 'light_fire_ai_#2',
        LIGHT_FIRE_AI_PREPING_2: 'light_fire_ai_preping_#2',
        SUBJECTWELL_CLINIC_TRIAL: 'subject_well_clinic_trial',
        SUBJECTWELL_CLINIC_TRIAL_PREPING: 'subject_well_clinic_trial_preping',
        SUBJECTWELL_CLINIC_TRIAL_CONDITION: 'subject_well_clinic_trial_condition',
        PUZZ_PREPING: 'puzz_preping',
        JOBCASE_COREG: 'jobcase_coreg',
        ADVISIO_MEDICARE_PREPING: 'advisio_medicare_preping',
        DVM_DIABETES: 'dvm_diabetes',
        PUZZ: 'puzz',
        QS_AUTO: 'qs_auto',
        QS_HEALTH: 'qs_health',
        PUBLIC_IP: 'pipv4',
        SESSION_ID: 'sid',
        DEVICE_ID: 'did',
        LANDER_ID: 'lid',
        LANDER_NAME: 'lname',
        LANDER_PATH_NAME: 'lpath_name',
        FLAVOR_NAME: 'flv_name',
        USER_ID: 'uid',
        SURVEY_ID: 'svid',
        SURVEY_PATH_ID: 'spid',
        JOB_TYPE_VAL: 'job_type',
        GEO_CITY: 'city',
        GEO_COUNTRY: 'country',
        GEO_REGION: 'region',
        GEO_ZIP_CODE: 'zip',
        GEO_ZIP_CODE_FED: 'zip_fed',
        EMAIL_FED: 'email_fed',
        GEO_IP: 'geo_ip',
        UTM_SOURCE: 'utm_source',
        UTM_MEDIUM: 'utm_medium',
        UTM_CAMPAIGN: 'utm_campaign',
        UTM_TERM: 'utm_term',
        PREFERENCE_ZIP_CODE: 'preference_zip_code',
        PREFERENCE_JOB_TYPE: 'preference_job_type',
        UTM_CONTENT: 'utm_content',
        REFERRER: 'referrer',
        DOMAIN: 'domain',
        SURVEY_DATA_PREFIX: '__survey_data',
        CS_ID: 'cs_id',
        USER_RESP_HASH_ID: 'user_resp_hash',
        SUB_DATA_KEY: 'survey_sub_key',
        USER_DETAILS: `${flavor}_user_details`,
        QUESTION_NUMBER: 'qno',
        QUESTION_NAME: 'qnm',
        AD_SPOT_NUMBER: 'spotno',
        EMAIL_RESETTED: 'email_resetted',
        UUID: 'uuid',
        EMAIL_VERIFICATION: 'evs',
        PHONE_VERIFICATION: 'pvs',
        CAMPAIGN_BLACKLIST: 'campaign_blacklist',
        JOB_ADS_SOURCE: 'jad',
        PAGE_REFRESH: 'pg_refresh',
        IS_REGISTERED: 'isRegistered',
        GTM_IS_REGISTERED: 'gtm_isRegistered',
        SKIP_INTERSTITIAL_CLICK_CNT: 'skip_int_clk_cnt',
        AB_TEST: 'abt',
        AB_TEST_1: 'abt1',
        AB_TEST_2: 'abt2',
        AB_TEST_3: 'abt3',
        AB_TEST_4: 'abt4',
        AB_TEST_5: 'abt5',
        AB_TEST_6: 'abt6',
        AB_TEST_7: 'abt7',
        AB_TEST_8: 'abt8',
        AB_TEST_9: 'abt9',
        AB_TEST_10: 'abt10',
        AB_TEST_11: 'abt11',
        AB_TEST_12: 'abt12',
        AB_TEST_13: 'abt13',
        AB_TEST_14: 'abt14',
        AB_TEST_15: 'abt15',
        AB_TEST_16: 'abt16',
        AB_TEST_17: 'abt17',
        AB_TEST_18: 'abt18',
        AB_TEST_19: 'abt19',
        AB_TEST_20: 'abt20',
        AB_TEST_21: 'abt21',
        AB_TEST_22: 'abt22',
        AB_TEST_23: 'abt23',
        AB_TEST_24: 'abt24',
        AB_TEST_25: 'abt25',
        AB_TEST_26: 'abt26',
        AB_TEST_27: 'abt27',
        AB_TEST_28: 'abt28',
        AB_TEST_29: 'abt29',
        AB_TEST_30: 'abt30',
        AB_TEST_31: 'abt31',
        AB_TEST_32: 'abt32',
        AB_TEST_33: 'abt33',
        AB_TEST_34: 'abt34',
        AB_TEST_35: 'abt35',
        AB_TEST_36: 'abt36',
        AB_TEST_37: 'abt37',
        AB_TEST_38: 'abt38',
        AB_TEST_39: 'abt39',
        AB_TEST_40: 'abt40',
        AB_TEST_41: 'abt41',
        AB_TEST_42: 'abt42',
        AB_TEST_43: 'abt43',
        AB_TEST_44: 'abt44',
        AB_TEST_45: 'abt45',
        AB_TEST_46: 'abt46',
        AB_TEST_47: 'abt47',
        AB_TEST_48: 'abt48',
        AB_TEST_49: 'abt49',
        AB_TEST_50: 'abt50',
        DEVICE_WIDTH: 'w',
        DEVICE_HEIGHT: 'h',
        DEVICE_PIXEL_RATIO: 'dpr',
        PAGE_URL: 'purl',
        JOB_DETAILS: 'jb_details',
        DYNAMIC_XML_FEED: 'dyn_feed',
        THEME_COLOR: `${flavor}_theme_color`,
        THEME_GRADIENT: `${flavor}_theme_grad`,
        THEME_TEXT_COLOR: `${flavor}_theme_txtcolor`,
        THEME_BORDER: `${flavor}_theme_border`,
        THEME_BORDER_RADIUS: `${flavor}_theme_borderradius`,
        THEME_FONT_SIZE: `${flavor}_theme_fontsize`,
        THEME_FONT_WEIGHT: `${flavor}_theme_fontweight`,
        THEME_HEIGHT: `${flavor}_theme_height`,
        THEME_WIDTH: `${flavor}_theme_width`,
        PUBLISHER_SUBID: 'ps',
        PUBLISHER_SUB_SOURCE: 'pss',
        UPWARDRGFLOW: 'upward_rg',
        EXIT_INTERSTITIAL_JOBS: 'ext_intstl_jb',
        EVERFLOW_TRANSACTION_ID: 'etid',
        LANDING_FLOW: 'landing_flow',
        PROVIDER_DATA: 'provider_data',
        FIRST_VISIT: 'first_visit',
        LAST_VISIT: 'last_visit',
        EMAIL_SUB: 'email_sub',
        PHONE_SUB: 'phone_sub',
        SURVEY_DATA_FOR_AD: 'survey_data_for_ad_req',
        SURVEY_DATA_FOR_AD_PREFIX: 'survey_',
        PII_FOUND: 'pii_found',
        VISIT_COUNT: 'visitCount',
        REGISTERED_VISIT_COUNT: 'registered_visit_count',
        USER_STATUS: 'user_status',
        USER_STATUS_CURRENT: 'user_status_current',
        USER_STATUS_IS_SUBSCRIBED: 'is_sub',
        USER_STATUS_IS_REGISTERED: 'is_reg',
        DISPATCHED_EVENTS: 'dispatched_events',
        ASA_PIXEL: 'ASA_PIXEL',
        FEATURE_SETS: 'feature_sets',
        CONFIG_SCOPES_LOADED: 'config_scopes_loaded',
        WORK_TIME_CLK_ANSWER: 'ans_val',
        AMAZON_BRANDED_LANDER_FAILED: 'amazon_lander_failed',
        XML_VISITED: 'xml_visited',
        P_DOMAIN: 'pdomain',
        IS_RDR: 'isrdr',
        OFFER_SRC_DOC_FACEBOOK: 'offersSrcDoc',
        PRE_POP: 'prePop',
        EMAIL_OPT_IN_TS: 'email_optin_ts',
        PHONE_OPT_IN_TS: 'phone_no_optin_ts',
        NEUROLOGICAL_GENETIC_PREPING: 'neurological_genetic_preping',
        NEUROLOGICAL_GENETIC_TOKEN: 'Neurological_Genetic_token',
        NEUROLOGICAL_GENETIC: 'neurological_genetic',
        USER_EMAIL: 'user_email',
        FIRE_LANDING_IMP_ON_GRAT: 'FIRE_LANDING_IMP_ON_GRAT',
        MULTI_BRAND_LOGO: 'logos',
        SURVEY_REQUEST_ID: 'survey_request_id',
        IS_EMAIL_BLACK_LISTED: 'is_email_bl',
        EXT1: 'ext1',
        EXT2: 'ext2',
        EXT3: 'ext3',
        EXT4: 'ext4',
        EXT5: 'ext5',
        HOURLY_PAY: 'hp',
        BASE_API_URL: 'BASE_API_URL',
        PUBLISHER_COST_BASE_URL: 'PUBLISHER_COST_BASE_URL',
        CONFIGURATION_BASE_URL: 'CONFIGURATION_BASE_URL',
        INIT_EMAIL: 'init_email',
        PUB_SOURCE_TYPE_CONFIG: 'pub_source_type_config',
        ABT_EXP: 'abt_exp',
        O_TS: 'ots',
        O_KEYWORD: 'okeyword',
        O_MID: 'omid',
        O_SOURCE: 'osource',
        NEW_ENCODED_SUBID: 'NEW_ENCODED_SUBID',
        ENCODED_SOURCE: 'encoded_source_str',
        SHORT_ENCODED_SOURCE: 'short_encoded_source',
        PUB_SOURCE_TYPE: 'pub_source_type',
    },
    WEBSTORE_CONSTANTS: {
        SYNC: 0,
        ASYNC: 1,
    },
    EVENT: {
        NON_US_SKIP: 'non_us_skip',
        SURVEY_QUESTION_ANSWERED: 'sv_ans',
        SURVEY_ANSWER_CONVERTED: 'sv_ans_conv',
        LANDING_PAGE_IMP: 'lp_imp',
        ZIP_IMP: 'zip_imp',
        LANDING_PAGE_SPECIAL_IMP: 'lp_imp2',
        USER_REGISTRATION_COMPLETE: 'usr_reg',
        SURVEY_PATH_IMP: 'sp_imp',
        SURVEY_QUESTION_IMP: 'sv_ques_imp',
        SURVEY_PAGE_IMP: 'sv_imp',
        SURVEY_COMPLETE: 'sv_comp',
        HNP_IMP: 'hnp_imp',
        HNP_SKIP: 'hnp_skip',
        HNP_LEAD: 'hnp_lead',
        HNP_LEAD_SENT: 'hnp_lead_send',
        HNP_LEAD_SUCCESS: 'hnp_lead_success',
        HNP_LEAD_FAIL: 'hnp_lead_failure',
        AD_LEAD: 'ad_lead',
        ADS_IMP: 'ad_imp',
        AD_PROG: 'ad_prog',
        AD_CLICK: 'ad_clk',
        LANDING_AD_CLICK: 'lp_ad_clk',
        MAX_CLICK: 'mx_clk',
        AD_SKIP: 'ad_skip',
        AD_FAILED: 'slot_failed',
        AD_IMP_V: 'slot_imp_v',
        AD_PATH_IMP: 'ad_path_imp',
        AD_PATH_CMP: 'ad_path_cmp',
        GRATIFICATION_PAGE_IMP: 'grat_imp',
        GRATIFICATION_PAGE_FAIL: 'grat_fail',
        GRATIFICATION_AVAIL: 'grat_avail',
        SPOT_GRAT_IMP: 'spot_grat_imp',
        USER_REGISTRATION_SKIP: 'skip',
        REPEAT_USER_STATUS_CHANGE: 'repeat_user_status_change',
        SPAM_PIXEL_FIRED: 'spam_pixel_fired',
        ERROR: 'error',
        REVENUE: 'ervn',
        EXPLORE_JOBS_BUTTON_SUB: 'explore_jobs_btn_sub',
        ALERT_SUB_CLK: 'alert_sub_clk',
        ALERT_SUB_SKIP: 'alert_sub_skip',
        USER_DETAIL_CONTINUE_BUTTON_SUB: 'user_detail_continue_btn_sub',
        ZIP_AADED: 'zip_sub',
        EMAIL_ADDED: 'email_sub',
        FIRSTNAME_ADDED: 'firstname_sub',
        LASTNAME_ADDED: 'lastname_sub',
        REPLACE_USER: 'replace_user',
        RVN_EVENT_NAME: 'rvn',
        ADLEAD_STATUS_EVENT: 'adlead_status',
        OFFER_CLICK: 'offer_path_clicked',
        SHOW_SKIP_BUTTON: 'show_skip_button',
        IFRAME_CONTENT_CHANGED: 'iframe_content_changed',
        EMAIL_VERIFICATION_FAILED: 'ev_failed',
        EMAIL_VERIFICATION_API_FAILED: 'ev_ap_failed',
        ZIP_SKIP: 'zip_skip',
        PAGE_REFRESH: 'pg_refresh',
        PAGE_BACK: 'pg_back',
        REG1_SUB: 'reg1_sub',
        REG1_CTA_FAILED: 'reg1_cta_failed',
        REG2_CTA_FAILED: 'reg2_cta_failed',
        REG3_CTA_FAILED: 'reg3_cta_failed',
        UUID_MISSING: 'uuid_missing',
        GENDER_PREDICTED: 'gen_p',
        GENDER_PREDICTION_USED: 'gen_p_u',
        GENDER_SELECTED: 'gen_s',
        SMS_OPT_IN_EVENT: 'sms_optin',
        KEYWORD_CLICKED: 'keyword_clicked',
        AUTO_SKIP: 'auto_skip',
        SLOT_LOAD_FAILED: 'slot_l_fs',
        DYN_XML_POPOUT: 'dxml_reg1_popout',
        KEYWORD_SELECT: 'kw_select',
        GRATIFICATION_ROTATED: 'grat_advertiser_changed',
        OFFER_PATH_NEXT: 'offer_path_next_clicked',
        OFFER_PATH_NAVIGATE: 'offer_path_next_navigated',
        XML_PRE_LANDER: 'xml_pre_lander',
        INTERSTITIAL_IMP: 'interstitial_imp',
        INTERSTITIAL_CLOSE: 'interstitial_close',
        INTERSTITIAL_CLOSE_EMAIL: 'interstitial_close_email',
        REG2_CTA_CLICK: 'reg2_click',
        EMAIL_SUB_LISTINGS: 'email_sub_listings',
        PHONE_SUB_LISTINGS: 'phone_sub_listings',
        PERSONAL_DATA_REQUEST: 'pdr',
        REMIND_LATER_CTA: 'remind_later_cta_click',
        REMIND_LATER_INTERSTITIAL_IMP: 'remind_later_interstitial_imp',
        REMIND_LATER_INTERSTITIAL_CLOSE: 'remind_later_interstitial_close',
        SKIP_TO_OFFERS: 'skip_to_offers',
        PREPING_SEND: 'preping_send',
        PREPING_SUCCESS: 'preping_success',
        PREPING_FAILURE: 'preping_failure',
        ADWORKS_SCRIPT_EMBEDDED: 'adworks_script_embedded',
        NEUVOO_SCRIPT_EMBEDDED: 'neuvoo_script_embedded',
        JT_KW_DEDUCE_SUCCESS: 'jt_kw_deduce_success',
        JT_KW_DEDUCE_FAILURE: 'jt_kw_deduce_failure',
        MJH_EMAIL_REG1ONLY: 'mjh-email-reg1only',
        MJH_REG2_ALL: 'mjh-reg2-all',
        SKIP_INT_IMP: 'skip_int_imp',
        SKIP_INT_YES: 'skip_int_yes',
        SKIP_INT_NO: 'skip_int_no',
        SKIP_INT_CLOSE: 'skip_int_close',
        EMAIL_SUB_INT_IMP: 'email_sub_int_imp',
        EMAIL_SUB_INT_SUBMIT: 'email_sub_int_submit',
        EMAIL_SUB_INT_CANCEL: 'email_sub_int_cancel',
        RECAPTCHA_CHALLENGE_SHOWN: 'rc_challenge_shown',
        RECAPTCHA_CHALLENGE_SUCCESS: 'rc_challenge_success',
        RECAPTCHA_CHALLENGE_FAILURE: 'rc_challenge_failure',
        WORK_TIME_CLK: 'work_time_clk',
        WARM_UP_QUES: 'warmup_ques',
        WARM_UP_QUES_ANS: 'warmup_ques_ans',
        MOTIVE_BACK_LINK: 'back_clk_link',
        GOOGLE_SSO_CTA_CLICKED: 'google_sso_cta_clicked',
        ONE_TAP_GOOGLE_SIGNIN_CLICK: 'one_tap_google_signin_click',
        AMAZON_BRAND_INTERSTITIAL_OPEN: 'amz_interstitial_imp',
        AMAZON_BRAND_INTERSTITIAL_CLOSE: 'amz_interstitial_close',
        PHONE_VERIFICATION_VERIFIED: 'pv_verified',
        PHONE_VERIFICATION_NOT_VERIFIED: 'pv_failed',
        BACK_BTN_IMPRESSION: 'back_btn_imp',
        PREFERENCE_PAGE_IMP: 'prefpg_Imp',
        PREFERENCE_PAGE_CLOSE: 'prefpg_cls',
        PREFERENCE_PAGE_SAVE_CLICK: 'prefpg_save_clk',
        EMAIL_INTERSTITIAL_SHOW: 'email_interstitial_show',
        EMAIL_INTERSTITIAL_CLOSE: 'email_interstitial_close',
        PHONE_INTERSTITIAL_SHOW: 'phone_interstitial_show',
        PHONE_INTERSTITIAL_CLOSE: 'phone_interstitial_close',
        SURVEY_PATH_FAILED: 'sp_failure',
    },
    AB_TEST: {
        INTERSTITIAL_VARIATION: 'interstitial1',
    },
    EVENT_TYPE: {
        LOCAL_STORAGE_NOT_ACCESSIBLE: 'local_storage_excp',
        SESSION_STORAGE_NOT_ACCESSIBLE: 'session_storage_excp',
        LANDING: 'landing',
        REGISTRATION: 'registration',
        SURVEY: 'survey',
        ADS: 'ads',
        GRAITIFICATION: 'gratification',
        SURVEY_PATH: 'survey_path',
        DATA: 'data',
        LANDING_INTERSTITIAL: 'landing_interstitial',
        CONTACTUS: 'contactus_pg',
        UNSUBSCRIBE: 'unsubscribe_pg',
    },
    CONFIG: {
        DEFAULT_PREPROCESS_SURVEY_TITLE: false,
        DEFAULT_SURVEY_SHOW_COMPLETED_SURVEY: false,
        DEFAULT_ALLOW_COOKIES_IN_CORS: true,
    },
    PAGE_ENDPOINTS: {
        LANDING: '/landing',
        SURVEY: '/survey',
        ADS: '/offers',
        GRATIFICATION: '/listings',
        HOME: '/home',
        SUBSCRIBE: '/subscribe',
        UNSUBSCRIBE: '/unsubscribe',
        DEFAULT_SEARCH_PARAM: '?sid=46',
        CONTACTUS: '/contactus',
        TERMSANDCONDITIONS: '/terms-and-conditions',
        PRIVACYPOLICY: '/privacy-policy',
        CCPA: '/California-Privacy-Notice',
        DONOTSELLMYINFO: '/do-not-sell-my-info/',
        ARTICLE: '/articles/:articleId',
        FINDJOBS: '/findjobs',
        EMAIL_PREFERENCE: '/email-preference',
    },
    DOM_EVENTS: {
        ON_APP_MOUNTED: 'onAppLoaded',
        SURVEY_NUM_QUESTIONS: 'numQuestionsInSurvey',
        SURVEY_COMPLETED: 'surveyCompleted',
        SURVEY_QUESTION_ANSWERED: 'surveyQuestionAnswered',
        ON_USER_DATA_PROCESSOR_CB_CREATED: 'onUserDataProcessorCbCreated',
        IFRAME_RESIZE: 'resize',
        CREATIVE_FAILURE: 'creativeFailure',
        CREATIVE_SUCCESS: 'creativeSuccess',
        RE_CAPTCHA_LOAD: 'RE_CAPTCHA_LOAD',
    },
    INTERNAL_EVENTS: {
        ON_SESSION_FETCHED: 'sessionFetched',
        ON_SESSION_FETCH_FAILED: 'sessionFetchFailed',
    },
    PREPROCESSOR: {
        /**
         * List of all the keys that contains an expression that may contain the question-id
         */
        QUESTION_PREFIX_EXPRESSION_FIELDS: ['visibleIf', 'enableIf', 'expression'],
    },
    USER: {
        STORE_USER_KEY: 'user_details',
        DOB_USER_INPUT_DATE_FORMAT: 'YYYY-MM-DD',
        DOB_API_DATE_FORMAT: 'MM/DD/YYYY',
        DOB_API_DAY_FORMAT: 'DD/MM/YYYY',
        STORE_REGISTERED_USER_KEY: 'registered_user',
        EMAILS_SUBMITTED: 'sub_emails',
        EMPLOYER_TYPE_VAL: 'employer_type',
    },
    USER_DETAILS_KEY: {
        EMAIL: 'email',
        DOB: 'dob',
        ZIP_CODE: 'zip_code',
        FIRST_NAME: 'first_name',
        LAST_NAME: 'last_name',
        PHONE: 'phone_number',
        ADDRESS: 'address',
        GENDER: 'gender',
    },
    ADS: {
        /*eslint-disable */
        DUMMY_AD_CODE:
            '<script id="mNCC" language="javascript">medianet_width="328";medianet_height="250";medianet_crid="571076646";medianet_versionId="3111299";medianet_requrl="https://mxplayer.in/";</script> <script src="https://contextual.media.net/nmedianet.js?cid=8CU3O41G4"></script>',
        /* eslint-enable */
        RESP_KEYS: {
            OFFERPATH_ID: 'opid',
            AD_ID: 'advId',
            ADVERTISER_NAME: 'advertiserName',
            CAMPAIGN_ID: 'campaignId',
            CAMPAIGN_NAME: 'campaignName',
            CREATIVE_ID: 'creativeId',
            REVENUE_TYPE: 'revType',
            REVENUE: 'bid',
        },
        REVENUE_TYPE: {
            CPC: 'cpc',
            CPM: 'cpm',
        },
        EVENT_BODY_KEYS: {
            OFFERPATH_ID: 'opid',
            AD_ID: 'advid',
            ADVERTISER_NAME: 'advnm',
            CAMPAIGN_ID: 'cmpid',
            CAMPAIGN_NAME: 'cmpnm',
            CREATIVE_ID: 'crid',
            REVENUE_TYPE: 'rv_type',
            REVENUE: 'rvn',
            EFFECTIVE_REVENUE: 'ervn',
        },
        EXTRAS: {
            SURVEY_ID: 'surveyId',
            SURVEY_PATH_ID: 'surveyPathId',
            DEVICE_ID: 'deviceId',
            LANDER_ID: 'landerId',
            USER_IP: 'userIP',
            UTM_SOURCE: 'p',
            UTM_MEDIUM: 'ch',
            UTM_CAMPAIGN: 'cm',
            UTM_CAMPAIGN_G: 'utm_campaign',
            UTM_MEDIUM_G: 'utm_medium',
            UTM_TERM: 'kw',
            AARP_PREPING: 'aarp_preping',
            FLATIRON_MEDIA_PREPING: 'flatiron_preping',
            PENTIUS_PREPING: 'pentius_preping',
            EMAIL_VERIFICATION: 'evs',
            PHONE_VERIFICATION: 'pvs',
        },
    },
    SURVEY_CACHE: {
        PAGE_NUM_CACHE_KEY: '__page_num',
    },
    ANALYTICS: {
        CATEGORY: {
            USER: 'user',
            SURVEY: 'survey',
            ADS: 'ads',
            GRATIFICATION: 'gratification',
            MISC: 'miscellaneous',
        },
    },
    JOBS_SEARCH: {
        DEFAULT_JOB_SEARCH_TERM: 'iOS developer',
        DEFAULT_JOB_RADIUS: '100',
        DEFAULT_JOB_PAGE_NUMBER: 1,
        DEFAULT_JOB_RESULTS_PER_PAGE: 10,
        SEARCH_BTN_TEXT: 'SEARCH',
        // DEFAULT_JOB_LOCATION: "Santa Monica"
    },
    UTM_DEFAULT: {
        SOURCE: 'direct',
        MISC: 'none',
    },
    APP_COMPONENT_NAME: {
        LANDING: 'LandingApp',
        SURVEY: 'SurveyApp',
        ADS: 'AdsApp',
        GRATIFICATION: 'GratificationApp',
    },
    SURVEY: {
        SURVEY_WRAPPER_ID: 'survey-wrapper',
    },
    NOTIFICATIONS: {
        /*
         * NOTE: This can be different for different environments as well
         * TODO: Add multiple environments
         */
        VAPID_PUBLIC_KEY:
            'BBQGtXxzkjIQhmmfYIyYafjGIiavIc_VEvUCv1mmvGNkcGIDiVv0VwpZOfwo7u8uSAiV_bTqLNChDjZTnb1XYZs',
        PUSH_ACCESS: 'pa',
    },
    CREATIVE_URL_PARAMS: {
        PRODUCT: '%%max-product%%',
        SHORT_ENCODED_SOURCE: '%%short_encoded_source%%',
        ZIP_CODE: '%%ZIP_CODE%%',
        SID: '%%SID%%',
        KEYWORD: '%%KEYWORD%%',
        JOB_TYPE: '%%JOB_TYPE%%',
        KW_OVER_JT: '%%KW_OVER_JT%%',
        JT_OVER_KW: '%%JT_OVER_KW%%',
        UTM_CONTENT: '%%UTM_CONTENT%%',
        FIRST_NAME: '%%FIRST_NAME%%',
        LAST_NAME: '%%LAST_NAME%%',
        USER_STATUS: '%%USER_STATUS%%',
        PHONE: '%%PHONE%%',
        PHONE_CODE: '%%PHONE_CODE%%',
        PHONE_PREFIX: '%%PHONE_PREFIX%%',
        PHONE_SUFFIX: '%%PHONE_SUFFIX%%',
        EMAIL: '%%EMAIL%%',
        GEO_IP: '%%GEO_IP%%',
        GEO_STATE: '%%GEO_STATE%%',
        GEO_CITY: '%%GEO_CITY%%',
        GENDER: '%%GENDER%%',
        DOB: '%%DOB%%',
        DOB_MONTH: '%%DOB_MONTH%%',
        DOB_DAY: '%%DOB_DAY%%',
        DOB_YEAR: '%%DOB_YEAR%%',
        AGE: '%%AGE%%',
        ADDRESS: '%%ADDRESS%%',
        ADDRESS2: '%%ADDRESS2%%',
        MD5EMAIL: '%%MD5EMAIL%%',
        UTM_CAMPAIGN: '%%UTM_CAMPAIGN%%',
        UTM_SOURCE: '%%UTM_SOURCE%%',
        THEME_COLOR: '%%THEME_COLOR%%',
        THEME_GRADIENT: '%%THEME_GRADIENT%%',
        THEME_TEXT_COLOR: '%%THEME_TEXT_COLOR%%',
        THEME_BORDER: '%%THEME_BORDER%%',
        THEME_BORDER_RADIUS: '%%THEME_BORDER_RADIUS%%',
        THEME_FONT_SIZE: '%%THEME_FONT_SIZE%%',
        THEME_FONT_WEIGHT: '%%THEME_FONT_WEIGHT%%',
        THEME_HEIGHT: '%%THEME_HEIGHT%%',
        THEME_WIDTH: '%%THEME_WIDTH%%',
        OPID: '%%OPID%%',
        SUBID: '%%SUBID%%',
        SUB_SOURCE_ID: '%%SUB_SOURCE_ID%%',
        ENCODED_SOURCE: '%%ENCODED_SOURCE%%',
        USER_IP: '%%USER_IP%%',
        UTM_MEDIUM: '%%UTM_MEDIUM%%',
        CLID: '%%CLID%%',
        IS_MOBILE: '%%IS_MOBILE%%',
        V2_ENCODED_SOURCE: '%%V2_Encoded_Source%%',
    },
    ADVERTISERS: {
        UPWARD: 'upward',
        TALROO: 'talroo',
        ZIPRECRUITER: 'zip_recruiter',
        APPCAST: 'appcast',
        LOCALSTAFFING: 'Local Staffing',
        DATA: {
            UPWARD: {
                NAME: 'Upward',
                ID: '2220',
                CAMPAIGN_NAME: 'Fulfilment Upward',
                CAMPAIGN_ID: '115930',
                REVENUE_TYPE: 'cpc',
            },
            TALROO: {
                NAME: 'Talroo',
                ID: '2250',
                CAMPAIGN_NAME: 'Fulfilment Talroo',
                CAMPAIGN_ID: '115933',
                REVENUE_TYPE: 'cpc',
            },
            ZIPRECRUITER: {
                NAME: 'Ziprecruiter',
                ID: '2440',
                CAMPAIGN_NAME: 'Fulfilment Ziprecruiter',
                REVENUE_TYPE: 'cpc',
            },
            APPCAST: {
                NAME: 'AppCast',
                ID: '2251',
                CAMPAIGN_NAME: 'Fulfilment AppCast',
                CAMPAIGN_ID: '115932',
                REVENUE_TYPE: 'cpc',
            },
            LOCALSTAFFING: {
                NAME: 'Local Staffing',
                ID: '2252',
            },
            JOBOWL: {
                NAME: 'JobOwl',
                ID: '2429',
            },
        },
    },
    EMAIL_VERIFICATION: {
        VERIFIED: 'verified',
        RETRY: 'retry',
        FAILED: 'failed',
    },
    PHONE_VERIFICATION: {
        VERIFIED: 'verified',
        FAILED: 'failed',
    },
    REDIRECT_MEDIUM: {
        SMS: 'sms',
    },
    AD_UNIT_ID: {
        BRINKS_MONEY: {
            STAG: 'e34e11f3-4479-46da-85fb-6357c4c0c681',
            PROD: '1950d434-bb0c-4dde-a494-57db455e451d',
        },
        SKIP_TO_NEW_TAB_AU: {
            PROD: 'f2965cf6-2295-4190-9839-83949c426914',
            STAG: '0833dd51-a22d-48d9-aa68-747ea9092d9c',
        },
        EXIT_INTERSTITIAL: {
            STAG: 'e0b2504b-0880-4b85-812c-2000aa1c9acd',
            PROD: '142b8c68-eada-4356-b92f-15a5b891e53f',
        },
        TALENT_INC: {
            STAG: '6999fb0e-4ff9-49cc-96ab-43edd9057b4d',
            PROD: 'b187b26b-33e2-484d-857b-fc8b6fc2132a',
        },
        JOBCASE_COREG: {
            STAG: 'b91912b6-277b-4044-88aa-e2a769084007',
            PROD: '9a984af9-eadd-4f48-bdae-fc37a31a9d2a',
        },
        DVM_DIABETES: {
            STAG: '5ef6258c-cd78-4a8d-b4a7-e4bde28f5ab7',
            PROD: '43fe493a-2813-46b7-8093-122251ae2a1a',
        },
        QS_AUTO: {
            STAG: '80d88d08-2a92-42b0-9da0-1d66e85c9922',
            PROD: '68ad971a-d17a-4e7c-b479-0942ff82e7de',
        },
        QS_HEALTH: {
            STAG: '6f6cdc52-8395-437c-ae26-3301e26ab9e2',
            PROD: '00de0a2f-5d1c-42b4-a4ce-a25cc241b338',
        },
        FULFILMENT_PAGE_LRDR: {
            STAG: '3477da22-0910-410b-9b37-985ef3fb25d2',
            PROD: '29cde415-66d4-4537-9648-e46de5d7dea1',
        },
        LANDING_BRANDED_OFFER_AMAZON: {
            STAG: '0d84da29-e284-4250-9e05-4fcaf7c9b52f',
            PROD: '9853545e-b63d-4b4c-a2f0-78043812fab1',
        },
        PUZZ: {
            STAG: '6f7de658-429a-4ced-be9b-6145deafa26b',
            PROD: '3d54f352-e524-4c5f-b2c0-cf6764512090',
        },
        DVM_CREDIT_REPAIR: {
            STAG: '93ea2a73-e6a4-4f7a-8b78-84682b1818b1',
            PROD: 'c07fb82f-9474-4c36-bc4c-bdccb8d91ce0',
        },
        LIGHT_FIRE_AI: {
            STAG: '3c6531bc-5566-4f21-b35b-297af37ac874',
            PROD: '2dba38f6-e05c-4faf-a4a6-29de369b57f6',
        },
        LANDING_BRANDED_OFFER_FED_EX: {
            STAG: '33f5e088-ad72-4042-b0e7-552d581dbaba',
            PROD: '7533b9c0-9332-4f01-979c-d88790353b50',
        },
        SUBJECTWELL_CLINIC_TRIAL: {
            STAG: '6d0b5166-06ed-447e-8ad9-27ad716a062e',
            PROD: 'b62e64bc-3191-4d74-8276-66fd4a3de115',
        },
        MOTIVE_INTERACTIVE_TEST: {
            STAG: 'e42529e9-6dbc-4662-83a1-0bad6458f88c',
            PROD: '8e7f726a-6629-4154-8011-f19e3fab228e',
        },
        NEUROLOGICAL_GENETIC: {
            STAG: '0cb27dcf-4862-49b3-809f-bc6d64ff66ec',
            PROD: 'fff5485f-a182-4e55-b828-242ccaa608a0',
            PROJECT_ID: '488f81f8-94dd-4e10-a1b1-afb65eb7ae90',
        },
    },
    CREATIVE_CATEGORIES: ['appcast_cpa', 'linkout', 'trucking', 'gigs', 'warehouse', 'jf_linkout'],
    TCPA_OPTIN_TEXT_SMS: `I agree to be contacted by phone and/or receive daily recurring SMS text messages (messages and data rates may apply) by NewJobScanner and Marketing Partners at the phone number provided (including my wireless) , whether or not the number is the federal or state do not call registry. I acknowledge that these calls or SMS messages may be made using an autodialer and may also contain pre-recorded messages. For SMS message campaigns: Text STOP to stop and HELP for help. Msg & data rates may apply. I understand my consent is not required to perform a job search, or to purchase or use any goods or services offered. To receive this information without providing this consent, skip `,
    TCPA_OPTIN_TEXT: `I agree to be contacted by phone and/or receive daily recurring SMS text messages (messages and data rates may apply) by NewJobScanner`,
    TCPA_EXTRA_PARTNERS:
        ', Citizens Disability, National Disability, Rocket Jobs, Local Jobs, Quick Job Finder, Hustlyy ',
    TCPA_OPTIN_TEXT_CONT: ` at the phone number provided (including my wireless) , whether or not the number is the federal or state do not call registry. I acknowledge that these calls or SMS messages may be made using an autodialer and may also contain pre-recorded messages. For SMS message campaigns: Text STOP to stop and HELP for help. Msg & data rates may apply. I understand my consent is not required to perform a job search, or to purchase or use any goods or services offered. To receive this information without providing this consent, skip `,
    TCPA_OPTIN_TEXT_CONT_NO_SKIP: ` at the phone number provided (including my wireless) , whether or not the number is the federal or state do not call registry. I acknowledge that these calls or SMS messages may be made using an autodialer and may also contain pre-recorded messages. For SMS message campaigns: Text STOP to stop and HELP for help. Msg & data rates may apply. I understand my consent is not required to perform a job search, or to purchase or use any goods or services offered.`,
    TCPA_PHONE_PART1:
        'I agree to be contacted by phone and/or receive daily SMS text messages (messages and data rates may apply) from NewJobScanner ',
    TCPA_PHONE_PART2:
        'at the phone number provided (including my wireless) , whether or not the number is the federal or state do not call registry. I acknowledge that these calls or SMS messages may be generated using an autodialer and may also contain pre-recorded messages. I understand my consent is not required to perform a job search or as a condition of purchasing any goods or services.',
    TCPA_PHONE_STOP:
        'For SMS message campaigns: Text STOP to stop and HELP for help. Msg & data rates may apply. Periodic messages; max. 20/month ',
    TCPA_EMAIL_PART1:
        'By clicking on "Confirm" I agree to the terms of use & privacy policy and to receive email updates for new job listings and career opportunities from ',
    TCPA_EMAIL_PART2: ' and our Marketing Partners.',
    TCPA_EMAIL_V2:
        'I want to receive email updates from new job listings and career opportunities from ',
    NJS_EMAIL_TCPA: `By clicking on "Confirm" I agree to the Terms of Use & Privacy Policy and to receive email updates for new job listings and career opportunities from NewJobScanner.com and our Marketing Partners`,
    NJS_PHONE_TCPA:
        'I agree to be contacted by phone and/or receive daily SMS text messages (messages and data rates may apply) from NewJobScanner at the phone number provided (including my wireless) , whether or not the number is the federal or state do not call registry. I acknowledge that these calls or SMS messages may be generated using an autodialer and may also contain pre-recorded messages. I understand my consent is not required to perform a job search or as a condition of purchasing any goods or services.<br/> <br/> For SMS message campaigns: Text STOP to stop and HELP for help. Msg & data rates may apply. Periodic messages; max. 30/month',
    EDU_OPTIN_TEXT: `By clicking “Agree and Continue”, I agree to be contacted by phone or received daily SMS text message (message and data rates may apply) by DegreeUSA, EDU Degree at {userPhoneNumber} (including my wireless) regarding educational opportunities. I acknowledge that these calls or sms messages may be generated using an autodialer and may also contain pre-recorded messages. I understand my consent is not required as a condition of purchasing any goods or services. To receive this information without providing consent, skip`,
    DEFAULT_JOB_TYPE_ICONS: ['amazon', 'walmart', 'fedex', 'costco'],
    JOBS_NEAR_ME: 'Jobs near me',
    JOBS_CATEGORY_QN: 'Which job categories are you interested in?',
    EVERY_JOB_FOR_ME_QN: 'This will help EveryJobForMe send you the best jobs',
    EVERY_JOB_FOR_ME_CHECK: 'Yes, sign me up for daily email job alerts from EveryJobForMe',
    EMAIL_TCPA: `I want to receive email updates for new job listings and career opportunities from MyJobsCorner, EveryJobForMe.com, NewJobConnections and our Marketing Partners`,
    SID_SMS_COMPLIANCE: '54',
    AD_UNIT: {
        CUSTOM_AD_INFO: {
            TYPE: {
                JOB: 'job',
            },
        },
    },
    PLACEMENT_TYPE: {
        EXIT_INTERSTITIAL: 'exit_interstitial',
    },
    LABELS: {
        LANDING: {
            ZIP: 'Verify Your Zip Code',
            CTA: 'View Jobs',
        },
    },
    DEFAULT_PAY_RANGE: '$15 - $26/hr',
    DEFAULT_PAY_RANGE_SHORT: '$15/hr',
    DEFAULT_PAY_FACEBOOK: '$16 - $36/hr',
    MAX_REPLACEMENT_MACROS: {
        creativeTitle: '%%max-ad-title%%',
        displayUrl: '%%max-ad-display-url%%',
        creativeIcon: '%%max-ad-icon%%',
        creativeImage: '%%max-ad-main-image%%',
        creativeDescription1: '%%max-ad-description-1%%',
        creativeDescription2: '%%max-ad-description-2%%',
        onClickTrackingPixels: '%%max-ad-click-url%%',
        trackingPixels: '%%max-ad-impression-url%%',
        bid: '%%max-ad-bid-value%%',
        revType: '%%max-ad-bid-type%%',
        // under customAdInfo
        link: '%%max-ad-custom-link%%', // (currently being use for SERP ads)
        target: '%%max-ad-custom-target%%', // (currently being used for Neuvoo SERP ad)
        type: '%%max-ad-custom-ad-type%%', // (currently being used for Neuvoo SERP ad)
        adCTA1: '%%max-ad-cta-1%%',
        adCTA2: '%%max-ad-cta-2%%',
        image: '%%max-ad-image%%',
        creativeType: '%%max-ad-creative-type%%',
        advId: '%%max-ad-adv-id%%',
        campaignId: '%%max-ad-cmp-id%%',
        adCategory: '%%max-ad-category%%',
        cpcFloor: '%%max-ad-cpc-floor%%',
        job_employer_blocklist: '%%max-job-employer-blocklist%%',
        product: '%%max-product%%',
        SHORT_ENCODED_SOURCE: '%%short_encoded_source%%',
    },
    CREATIVE_URL_PARAMS_REGEX: /%%ZIP_CODE%%|%%SID%%|%%KEYWORD%%|%%JOB_TYPE%%|%%KW_OVER_JT%%|%%JT_OVER_KW%%|%%UTM_CONTENT%%|%%FIRST_NAME%%|%%LAST_NAME%%|%%PHONE%%|%%PHONE_CODE%%|%%PHONE_PREFIX%%|%%PHONE_SUFFIX%%|%%EMAIL%%|%%GEO_IP%%|%%GEO_CITY%%|%%GEO_STATE%%|%%GENDER%%|%%DOB%%|%%ADDRESS%%|%%MD5EMAIL%%|%%UTM_CAMPAIGN%%|%%UTM_SOURCE%%|%%SUBID%%|%%ENCODED_SOURCE%%|%%THEME_COLOR%%|%%THEME_GRADIENT%%|%%THEME_TEXT_COLOR%%|%%THEME_BORDER%%|%%THEME_BORDER_RADIUS%%|%%THEME_FONT_SIZE%%|%%THEME_FONT_WEIGHT%%|%%THEME_HEIGHT%%|%%THEME_WIDTH%%|%%OPID%%|%%ADDRESS2%%|%%CLID%%|%%IS_MOBILE%%|%%UTM_MEDIUM%%|%%DOB_MONTH%%|%%DOB_DAY%%|%%DOB_YEAR%%|%%AGE%%|%%SUB_SOURCE_ID%%|%%USER_IP%%|%%USER_STATUS%%|%%max-product%%|%%V2_Encoded_Source%%|%%short_encoded_source%%/gi,
    MAX_MACROS_REGEX: /%%max-ad-title%%|%%max-ad-display-url%%|%%max-ad-icon%%|%%max-ad-description-1%%|%%max-ad-description-2%%|%%max-ad-click-url%%|%%max-ad-impression-url%%|%%max-ad-bid-value%%|%%max-ad-bid-type%%|%%max-job-employer-blocklist%%|%%max-ad-custom-link%%|%%max-ad-custom-target%%|%%max-ad-custom-ad-type%%|%%max-ad-cta-1%%|%%max-ad-cta-2%%|%%max-ad-image%%|%%max-ad-main-image%%|%%max-ad-creative-type%%|%%max-ad-adv-id%%|%%max-ad-cmp-id%%|%%max-ad-category%%|%%max-ad-cpc-floor%%|%%max-product%%|%%short_encoded_source%%/gi,
    CR_MAX_UNIFIED_REGEX: /%%ZIP_CODE%%|%%SID%%|%%KEYWORD%%|%%JOB_TYPE%%|%%KW_OVER_JT%%|%%JT_OVER_KW%%|%%UTM_CONTENT%%|%%FIRST_NAME%%|%%LAST_NAME%%|%%PHONE%%|%%PHONE_CODE%%|%%PHONE_PREFIX%%|%%PHONE_SUFFIX%%|%%EMAIL%%|%%GEO_IP%%|%%GEO_CITY%%|%%GEO_STATE%%|%%GENDER%%|%%DOB%%|%%ADDRESS%%|%%MD5EMAIL%%|%%UTM_CAMPAIGN%%|%%UTM_SOURCE%%|%%SUBID%%|%%ENCODED_SOURCE%%|%%THEME_COLOR%%|%%THEME_GRADIENT%%|%%THEME_TEXT_COLOR%%|%%THEME_BORDER%%|%%THEME_BORDER_RADIUS%%|%%THEME_FONT_SIZE%%|%%THEME_FONT_WEIGHT%%|%%THEME_HEIGHT%%|%%THEME_WIDTH%%|%%OPID%%|%%ADDRESS2%%|%%CLID%%|%%IS_MOBILE%%|%%UTM_MEDIUM%%|%%DOB_MONTH%%|%%DOB_DAY%%|%%DOB_YEAR%%|%%AGE%%|%%SUB_SOURCE_ID%%|%%USER_IP%%|%%max-ad-title%%|%%max-ad-display-url%%|%%max-ad-icon%%|%%max-ad-description-1%%|%%max-ad-description-2%%|%%max-ad-click-url%%|%%max-ad-impression-url%%|%%max-ad-bid-value%%|%%max-ad-bid-type%%|%%max-job-employer-blocklist%%|%%max-ad-custom-link%%|%%max-ad-custom-target%%|%%max-ad-custom-ad-type%%|%%max-ad-cta-1%%|%%max-ad-cta-2%%|%%max-ad-image%%|%%max-ad-main-image%%|%%max-ad-creative-type%%|%%max-ad-adv-id%%|%%max-ad-cmp-id%%|%%max-ad-category%%|%%max-ad-cpc-floor%%|%%USER_STATUS%%|%%max-product%%|%%V2_Encoded_Source%%|%%short_encoded_source%%/gi,
    CONNEXUS_PROXY_URL: 'https://api.cnxdserv.com/a/api/v2/proxy',
    JOBCASE_AUTH_TOKEN: 'kFtKsDAV0LXNuRxSZZtMXQ',
    NON_SPECIFIC_KWS: ['full time jobs', 'entry level jobs', 'part time jobs', 'online jobs'],
    PREPING_URLS: {
        JOBCASE: 'https://api.careerboutique.com/api/v2/pre_pings',
        DVM: 'https://dvm.api.twyne.io/preping/check',
        ADVISIO: 'https://leads.usacoverage.com/new_api/api.php',
        PENTIUS: 'https://portal.systemadmin.com/preping_check.asp',
        PUZZ: 'https://puzz.biglist.com/api/1.1/lists/news/subscriptions',
        LIGHT_FIRE_AI:
            'https://lms.lightfirepartners.com/ping/48cf9c1d-0661-4757-a3ab-4f3408e9390a',
        SUBJECTWELL_CLINIC_TRIAL: 'https://api.subjectwell.com/v1/pre_ping',
        FLATIRON:
            'https://fmmanagedadvertiserprepings.azurewebsites.net/api/rungoodrx?code=nqHsvQawDjGgIs/5LgoCcEGGGtOJ2V7jPiva0X8FUdZC908kfdj0Dg==',
        CONVERZATE: 'https://api.converzate.com/v1/ping',
    },
    PREPING_API_KEY: {
        SUBJECTWELL_CLINIC_TRIAL: 'f79b6e31fd3eb3004d28272d190b2f87',
        CONVERZATE: 'HjXTunZKx02J9WaCCWRSH3ORmkmBLdFp8j7AS4qQ',
    },
    RECAPTCHA: {
        NO_CHALLENGE_KEY: '6LcghmYbAAAAAJ1n_FSoFT22YSwc9mZobvBIdZx0',
        CHALLENGE_KEY: '6LflW_IbAAAAAPmVNcWbMmC3ErJgeVRLQEqY1Ys2',
    },
    ANTI_SPAM_TEMPLATE_ID: {
        '/landing': {
            lander: 28,
            registration: 36,
        },
        '/survey': 32,
        '/offers': 33,
        '/listings': 34,
    },
    NEUROLOGICAL_TCPA: userPhoneNumber =>
        `By clicking "Yes" I expressly authorize Modern Doc and GRS to call and text me at the phone number ${userPhoneNumber} using an automated dialer and prerecorded messages to coordinate health services. Accepting this consent is not required to obtain any good or service.`,
    NEUROLOGICAL_QUESTION_BODY: `New at-home genetic test can help assess the risk of neurological diseases. Other services include a genetic test that can tell if your medications are working effectively, medication management to have your medications sent directly to you, and an on-call pharmacist that can help save on out-of-pocket costs. Click YES to receive a call by a representative to see if you qualify.`,
    API_PROXY_URL: 'https://api.cnxdserv.com/a/api/v2/proxy',
    INBOXRESEARCH_HEADING2: `Join our community of over 1 million members and earn rewards by taking surveys! It's simple!`,
    INBOXRESEARCH_DISCLAIMER: ` Click YES to join and check your email to confirm your registration!`,
    ZRB_TCPA: `By clicking submit, agree to the ZipRecruiter <a class="atag" href=" https://www.ziprecruiter.com/terms" target="_blank">Terms of Use</a>  and acknowledge I have read the <a class="atag" href=" https://www.ziprecruiter.com/privacy" target="_blank">Privacy Policy</a>  and agree to receive daily job alert emails I understand I may modify or remove my resume or change my privacy settings at any time by visiting my ZipRecruiter profile at <a class="atag" href="https://www.ziprecruiter.com/" target="_blank">ZipRecruter.com</a>`,
    ZRB_BODY: `By choosing to join ZipRecruiter, I understand that <a class="atag" href="https://myjobscorner.com/" target="_blank">myjobscorner.com</a>  will share my name, email, and search details with ZipRecruiter, and ZipRecruiter will use this information to send me job alert emails and create a job seeker account.`,

    TABOOLA_LANDER: {
        TRUCKER: {
            BANNER_IMG: 'images/taboola/taboola-truck-banner.png',
            BANNER_IMG2: 'images/taboola/taboola-truck-banner2.png',
        },
        PRODUCT: {
            BANNER_IMG: 'images/taboola/taboola-product-banner.png',
            BANNER_IMG2: 'images/taboola/taboola-product-banner2.png',
        },
        AMAZON: {
            BANNER_IMG: 'images/taboola/taboola-amazon-banner-new.png',
            BANNER_IMG2: 'images/taboola/taboola-amazon-banner2.png',
        },
    },
};
