import UrlManager from 'common/urlManager';
import Network from 'common/network';
import CommonDataSource from './commonDataSource';
import JobSearchDataSource from './jobsSearchDataSource';
import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import Constants from 'common/constants';

const MAX_FALLBACK_ITR = 2;
export default class OfferWallDataSource {
    /**
     * Fetch offer-wall from remote endpoint.
     * It returns a promise for whenever it is complete
     */
    static fetchAdsDataForTargetingParams(targetingParams, options) {
        console.log('OFFER_WALL: Fetching session');

        return new Promise((resolve, reject) => {
            const url = UrlManager.getAdsUrl();
            let reqBody = {};

            if (false === Utils.isNull(options)) {
                Object.assign(reqBody, options);
            }

            if (
                false === Utils.isNull(targetingParams) &&
                targetingParams.extras.surveyId !== '46'
            ) {
                reqBody = {
                    ...targetingParams,
                    ...reqBody,
                    ...{
                        categories: Constants.CREATIVE_CATEGORIES,
                    },
                };
            } else {
                reqBody = {
                    ...targetingParams,
                    ...reqBody,
                    ...{
                        categories: ['linkout', 'offers', 'dataoffer'],
                    },
                };
            }
            console.log('ADS: Making ads-request with body - ', reqBody);

            const headers = {
                'Content-Type': 'application/json',
            };

            let resp = IdStore.fetchIdForKey(Utils.hashCode(JSON.stringify(reqBody)));

            if (resp) {
                console.log('ADS:  returning cached response');

                try {
                    resolve(CommonDataSource.processJSONResponse(JSON.parse(resp)));

                    return;
                } catch (err) {
                    console.error(err);
                }
            }

            Network.post(url, headers, reqBody)
                .then(response => {
                    if (false === response.ok) {
                        console.error(new Error(`response status invalid - ${response}`));
                    }

                    return response.json();
                })
                .then(responseJson => {
                    IdStore.storeIdForKey(
                        Utils.hashCode(JSON.stringify(reqBody)),
                        JSON.stringify(responseJson),
                    );

                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    console.log('OFFER_WALL: Got response data - ', processedResp);
                    resolve(processedResp);
                })
                .catch(sessionErr => {
                    console.log('ERR: OFFER_WALL: error - ', sessionErr);
                    reject(sessionErr);
                });
        });
    }

    static async processAdUnitMeta(adUnitData = {}, { term, zip }) {
        const { customAdInfo = {} } = adUnitData.ad || {};

        if (customAdInfo.type === Constants.AD_UNIT.CUSTOM_AD_INFO.TYPE.JOB) {
            const jobsInfo = await JobSearchDataSource.fetchJobDataForAdUnitSourceV3(
                term || IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM),
                zip || IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE),
                adUnitData,
            );

            return { ...adUnitData, jobsInfo };
        }

        return adUnitData;
    }

    static async fetchAdsForAdUnitFbs(
        targetingParams,
        adUnitId,
        adValidatorCb,
        jobSearchTargeting = {},
        maxFallbackItr = MAX_FALLBACK_ITR,
    ) {
        try {
            const adUnitResponse = await this.fetchAdsForAdUnit(targetingParams, adUnitId);
            let adUnitData = await this.processAdUnitMeta(adUnitResponse, jobSearchTargeting);
            let [isValid, validatorExtraData] = await adValidatorCb(adUnitData);
            const fallbacks = adUnitData && adUnitData.fallbacks;

            console.log('AdUnit: Ad Unit Response', adUnitData, isValid);

            if (!isValid && Array.isArray(fallbacks)) {
                console.log('AdUnit: Trying fallbacks', fallbacks);

                for (let i = 0; i < maxFallbackItr && i < fallbacks.length; i++) {
                    const singleAdUnitData = await this.fetchSingleAdForAdUnit(fallbacks[i]);

                    adUnitData = await this.processAdUnitMeta(
                        { ad: singleAdUnitData },
                        jobSearchTargeting,
                    );
                    [isValid, validatorExtraData] = await adValidatorCb(adUnitData);
                    console.log('AdUnit: Ad Unit Response', adUnitData, isValid);

                    if (isValid) {
                        return { ...adUnitData, isValid, ...validatorExtraData };
                    }
                }
            }

            return { ...adUnitData, isValid, ...validatorExtraData };
        } catch (err) {
            console.error('AdUnit: Error Fetching Ad unit', err);
        }
    }

    static fetchAdsForAdUnit(targetingParams, adUnitId) {
        return new Promise((resolve, reject) => {
            const url = UrlManager.getAdUnitUrl();
            let reqBody = {};

            if (adUnitId) {
                reqBody.adUnitId = adUnitId;
            }

            if (
                false === Utils.isNull(targetingParams) &&
                targetingParams.extras.surveyId !== '46'
            ) {
                reqBody = {
                    ...targetingParams,
                    ...reqBody,
                    ...{
                        categories: Constants.CREATIVE_CATEGORIES,
                    },
                };
            } else {
                reqBody = {
                    ...targetingParams,
                    ...reqBody,
                    ...{
                        categories: ['linkout', 'offers', 'dataoffer'],
                    },
                };
            }
            console.log('ADS: Making ads-request with body - ', reqBody);

            const headers = {
                'Content-Type': 'application/json',
            };

            Network.post(url, headers, reqBody)
                .then(response => {
                    if (false === response.ok) {
                        console.error(new Error(`response status invalid - ${response}`));
                    }

                    return response.json();
                })
                .then(responseJson => {
                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    console.log('Ad Unit: Got response data - ', processedResp);
                    resolve(processedResp);
                })
                .catch(sessionErr => {
                    console.log('ERR: Ad Unit: error - ', sessionErr);
                    reject(sessionErr);
                });
        });
    }

    static fetchSingleAdForAdUnit(path) {
        return new Promise((resolve, reject) => {
            const url = UrlManager.getSingleAdUrl(path);
            Network.get(url)
                .then(response => {
                    if (false === response.ok) {
                        console.error(new Error(`response status invalid - ${response}`));
                    }

                    return response.json();
                })
                .then(responseJson => {
                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    console.log('Ad Unit: Got response data - ', processedResp);
                    resolve(processedResp);
                })
                .catch(sessionErr => {
                    console.log('ERR: Ad Unit: error - ', sessionErr);
                    reject(sessionErr);
                });
        });
    }

    static fetchCreativeForSlot(path) {
        console.log('Getting another creative for current slot');

        return new Promise((resolve, reject) => {
            const url = UrlManager.getSingleAdUrl(path);

            let resp = IdStore.fetchIdForKey(Utils.hashCode(url));

            if (resp) {
                console.log('ADS:  returning cached response');

                try {
                    resolve(CommonDataSource.processJSONResponse(JSON.parse(resp)));
                } catch (err) {
                    reject(err);
                }

                return;
            }

            Network.get(url)
                .then(response => {
                    if (false === response.ok) {
                        console.error(new Error(`response status invalid - ${response}`));
                    }

                    return response.json();
                })
                .then(responseJson => {
                    IdStore.storeIdForKey(Utils.hashCode(url), JSON.stringify(responseJson));

                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    console.log('OFFER_PATH: Got response data - ', processedResp);
                    resolve(processedResp);
                })
                .catch(sessionErr => {
                    console.log('ERR: OFFER_PATH: error - ', sessionErr);
                    reject(sessionErr);
                });
        });
    }
}
