import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';

const bugsnagClient = bugsnag({
    apiKey: 'd7047e86f2a16f1132a956e1899c1441',

    /**
     * NOTE: If you'd like to report errors even from the developement phase,
     * add "development" to the list below, or remove the notifyReleaseStages key
     * completely.
     */
    notifyReleaseStages: ['production'],
});
bugsnagClient.use(bugsnagReact, React);

const BugsnagErrBoundary = bugsnagClient.getPlugin('react');

export { bugsnagClient, BugsnagErrBoundary };
