import * as moment from 'moment';
import UrlManager from 'common/urlManager';
import Constants from 'common/constants';
import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import { SessionHelper, StringHelper, AbTestHelper } from 'helpers';
import Features from './features';
import Lander from './lander';
import ABTests from './abTests';
import FeatureSets from './featureSets';
import { KeyMap as FeatureMap } from './constants/features';
import { KeyMap as LanderMap } from './constants/landers';
import { GenderMap } from './constants/common';
import { fetchRetry, addValueToObject } from './utils';
import UserDataValidator from 'common/userDataValidator';
import DateUtils from 'common/DateUtils';
import { getFlavor } from 'flavors.macro';
import {
    APP_SCOPES,
    getCurrentPageScope,
    getScopeStr,
    markScopeDataLoaded,
} from 'common/scopeUtils';

const flavor = getFlavor('layout-theme');
const domains = {
    NewJobScanner: 'newJobScanner',
};

class RootProvider {
    constructor() {
        this.data = null;
        this.Features = null;
        this.Lander = null;
        this.ABTests = null;
    }

    updateKeywordConfig(data) {
        const keywordConfigSuccess = Utils.getObjVal(data, ['keywordConfig', 'success']) || false;
        const { category, kw, jt } = Utils.getObjVal(data, ['keywordConfig', 'data']) || {};

        if (keywordConfigSuccess && AbTestHelper.kwJtConfigIntegration()) {
            console.log('Config: Updating Store Kw Jt values');
            kw &&
                kw.toLowerCase &&
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UTM_TERM, kw.toLowerCase());
            jt && jt.toLowerCase && IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL, jt);
        }

        if (!StringHelper.isNull(category))
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.KW_CAT, category);
        else {
            console.error('Config: Emtpy kwcat, setting fallback kwcat');
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.KW_CAT, 'Other');
        }
    }

    async fetchConfig(aScopes) {
        // get data from API
        const userDetails =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS) &&
            JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS));

        const surveyId = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.SURVEY_ID);
        const keyword = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);
        const jobType = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL) || '';
        const landingKw = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_KEYWORD) || '';
        const firstName = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.FNAME);
        const lastName = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.LNAME);
        const email = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EMAIL);
        const addr = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ADDRESS),
        );
        const phone = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE),
        );
        const uDob = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.DOB),
        );
        const dobMoment = Utils.parseDateFromUrl();
        const isPiiPresent =
            !StringHelper.isNull(firstName) &&
            !StringHelper.isNull(lastName) &&
            !StringHelper.isNull(email) &&
            !UserDataValidator.isValidEmail(email);
        const isPiiReg2Present =
            !StringHelper.isNull(addr) &&
            !StringHelper.isNull(uDob) &&
            dobMoment &&
            dobMoment.isValid &&
            dobMoment.isValid() &&
            !DateUtils.isAboveTeenage(dobMoment) &&
            !StringHelper.isNull(phone);
        const urlJobType = Utils.getValForKeyFromCurrentUrl('jt');

        const isSub = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_IS_SUBSCRIBED);
        const isReg = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_IS_REGISTERED);
        const userStatus = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS);
        const userStatusCurrent = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.USER_STATUS_CURRENT,
        );

        const params = {};

        addValueToObject(params, 'scope', aScopes.map(getScopeStr).join(','));
        addValueToObject(
            params,
            'utm_source',
            encodeURIComponent(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE)),
        );
        addValueToObject(
            params,
            'utm_campaign',
            encodeURIComponent(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN)),
        );
        addValueToObject(
            params,
            'utm_medium',
            encodeURIComponent(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM)),
        );
        addValueToObject(params, 'utm_term', encodeURIComponent(keyword));
        addValueToObject(params, 'job_type', encodeURIComponent(jobType));
        addValueToObject(
            params,
            'ljt',
            encodeURIComponent(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_JT)),
        );
        addValueToObject(
            params,
            'lkw',
            encodeURIComponent(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_KEYWORD)),
        );
        addValueToObject(params, 'duplicate_user', SessionHelper.timeSinceFirstVisit());
        addValueToObject(params, 'key_vals', [
            `surveyId:${encodeURIComponent(Utils.isNull(surveyId) ? '' : surveyId)}`,
            `isRegistered:${
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GTM_IS_REGISTERED) || '0'
            }`,
            `isKeywordEqualsJobType:${jobType === keyword ? '1' : '0'}`,
            `isKeywordPresent:${Utils.isEmptyStr(keyword) ? '0' : '1'}`,
            `isLandingJTPresent:${Utils.isEmptyStr(Utils.getJobType().jobType) ? '0' : '1'}`,
            `isUrlJtPresent:${StringHelper.isNull(urlJobType) ? '0' : '1'}`,
            `isKeywordEqualsBrand:${
                !StringHelper.isNull(keyword) && Utils.isJobTypeAllowed(keyword.toLowerCase())
                    ? '1'
                    : '0'
            }`,
            `isJobTypeEqualsBrand:${
                Utils.isJobTypeAllowed(jobType && jobType.toLowerCase && jobType.toLowerCase())
                    ? '1'
                    : 0
            }`,
            `isLandingKWPresent:${Utils.isEmptyStr(landingKw) ? '0' : '1'}`,
            `isLandingKWEqualsJobType:${
                !StringHelper.isNull(landingKw) &&
                landingKw.toLowerCase &&
                landingKw.toLowerCase() === jobType.toLowerCase()
                    ? '1'
                    : '0'
            }`,
            `isLandingKWEqualsBrand:${
                !StringHelper.isNull(landingKw) && Utils.isJobTypeAllowed(landingKw.toLowerCase())
                    ? '1'
                    : '0'
            }`,
            `isNonDefaultJobTypePresent:${
                Utils.isEmptyStr(jobType) || jobType === Constants.DEFAULT_KW ? '0' : '1'
            }`,
            `isPIIFound:${isPiiPresent ? '1' : '0'}`,
            `isPIIReg2Found:${isPiiReg2Present ? '1' : '0'}`,
            `islrdr:${Utils.getValForKeyFromCurrentUrl('lrdr') ? '1' : '0'}`,
            `is_sub:${isSub}`,
            `is_reg:${isReg}`,
            `userStatus:${userStatus}`,
            `userStatusCurrent:${userStatusCurrent}`,
            `${Constants.ID_STORE_KEYS.EXT1}:${IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.EXT1,
            )}`,
            `${Constants.ID_STORE_KEYS.EXT2}:${IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.EXT2,
            )}`,
            `${Constants.ID_STORE_KEYS.EXT3}:${IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.EXT3,
            )}`,
            `${Constants.ID_STORE_KEYS.EXT4}:${IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.EXT4,
            )}`,
            `${Constants.ID_STORE_KEYS.EXT5}:${IdStore.fetchIdForKey(
                Constants.ID_STORE_KEYS.EXT5,
            )}`,
        ]);

        if (userDetails && !Utils.isEmptyObj(userDetails)) {
            const { dob, gender } = userDetails;

            if (!Utils.isEmptyStr(dob)) {
                params.age = moment().diff(dob, 'years');
            }

            if (!Utils.isEmptyStr(gender)) {
                params.gender = GenderMap[gender];
            }
        }

        if (flavor !== 'myJobsCorner') {
            addValueToObject(params, 'domain', flavor);
        } else {
            if (
                process.env.REACT_APP_TITLE &&
                process.env.REACT_APP_TITLE !== 'MyJobsCorner' &&
                domains[process.env.REACT_APP_TITLE]
            ) {
                addValueToObject(params, 'domain', domains[process.env.REACT_APP_TITLE]);
            }
        }

        const data = await fetchRetry(
            `${UrlManager.getConfigurationProviderUrl()}?${Utils.encodeObjectToUrlArgs(params)}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            2,
        );

        if (!Utils.isNull(data)) {
            aScopes.forEach(markScopeDataLoaded);
            IdStore.sync();
        }

        return data;
    }

    mergeConfigData(data, prevData, key) {
        const currDataVal = data[key] || [];
        const prevDataVal = prevData[key] || [];

        return [...prevDataVal, ...currDataVal];
    }

    async fetchScopeConfig(aScopes) {
        const data = (await this.fetchConfig(aScopes)) || {};
        const prevData = this.data || {};

        const currAbtMap = data.abtMap || [];
        const currFeatureMap = data.featureMap || [];
        const currFeatureSets = data.featureSets || [];
        const prevAbtMap = prevData.abtMap || [];
        const prevFeatureMap = prevData.featureMap || [];
        const prevFeatureSets = prevData.featureSets || [];

        const filteredAbtMap = currAbtMap.filter(
            abtMap => !prevAbtMap.find(i => i.channel === abtMap.channel),
        );
        const filteredFeatureSets = Array.from(new Set([...prevFeatureSets, ...currFeatureSets]));

        const mergedData = {
            ...prevData,
            abtMap: [...prevAbtMap, ...filteredAbtMap],
            featureMap: [...prevFeatureMap, ...currFeatureMap],
            featureSets: filteredFeatureSets,
        };

        this.data = mergedData;
        this.Features.setFeatures(currFeatureMap);
        this.ABTests.setABTests(filteredAbtMap);
        this.FeatureSets.set(filteredFeatureSets);
    }

    /**
     * Initialize the application, leverage on caching
     */
    async initialize() {
        const CS_ID = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);
        const dataCache = IdStore.fetchIdForKey(
            `${CS_ID}_${Constants.ID_STORE_KEYS.PROVIDER_DATA}`,
        );

        try {
            if (!Utils.isEmptyStr(CS_ID)) {
                if (!Utils.isEmptyStr(dataCache)) {
                    try {
                        this.data = JSON.parse(dataCache) || {};
                    } catch (exception) {
                        console.error('FAILED to parse cached provider data - ', exception);
                    }
                }
            }

            if (Utils.isNull(this.data)) {
                this.data = await this.fetchConfig([APP_SCOPES.GLOBAL, getCurrentPageScope()]);
            }

            if (Utils.isNull(this.data)) {
                this.Features = new Features({});
                this.Lander = new Lander({});
                this.ABTests = new ABTests({});
                this.FeatureSets = new FeatureSets({});

                return false;
            }

            // Set sub providers
            this.Features = new Features(this.data);
            this.Lander = new Lander(this.data);
            this.ABTests = new ABTests(this.data);
            this.FeatureSets = new FeatureSets(this.data);

            // set config data in IdStore
            IdStore.storeIdForKey(
                `${CS_ID}_${Constants.ID_STORE_KEYS.PROVIDER_DATA}`,
                JSON.stringify(this.data),
            );
            this.data.pubSourceTypeConfig &&
                IdStore.storeIdForKey(
                    Constants.ID_STORE_KEYS.PUB_SOURCE_TYPE_CONFIG,
                    JSON.stringify(this.data.pubSourceTypeConfig || ''),
                );

            !dataCache && this.updateKeywordConfig(this.data);

            return true;
        } catch (exception) {
            console.log('FAILED to load provider data - ', exception);
        }
        this.Features = new Features({});
        this.Lander = new Lander({});
        this.ABTests = new ABTests({});
        this.FeatureSets = new FeatureSets({});
        console.error('Config: Failed config load, setting fallback kwcat');
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.KW_CAT, 'Other');

        return false;
    }

    /**
     * Force refresh of config data
     */
    async reset() {
        const CS_ID = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);

        if (!Utils.isEmptyStr(CS_ID)) {
            IdStore.removeIdForKey(`${CS_ID}_${Constants.ID_STORE_KEYS.PROVIDER_DATA}`);
        }
        await this.initialize();
    }
}

// export providers
export const ConfigProvider = new RootProvider();
export { FeatureMap, LanderMap };
