import Utils from 'common/utils';

class TrackingMacroManager {
    /**
     * Perform macro replacement in the given url.
     * It will always return a string, even if the input arg is empty.
     * @param {string} url
     * @returns {string}
     */
    processMacrosInUrl(url) {
        // Add replacement logic
        if (Utils.isNull(url)) {
            return '';
        }
        let processedUrl = url.replace(/\$\{(\w+)\}/g, this.performReplacement.bind(this));

        return processedUrl;
    }

    // TODO: Add the logic to the macro managers
    performReplacement(match, macroKey) {
        return '';
    }
}

let trackingMacroManager = new TrackingMacroManager();
export default trackingMacroManager;
