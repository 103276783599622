import Utils from 'common/utils';

export const getUserFromCache = () => {
    if (Utils.isTestEnv() || Utils.isLocalEnv()) {
        if (!window.eventLogs) {
            window.eventLogs = [];
        }
        const objectClick = {
            evt_id: 'cta_clicked',
        };

        window.eventLogs.push(objectClick);
    }
};
