import Utils from 'common/utils';
import UserDetails from 'models/userDetails';
import Network from 'common/network';
import CommonDataSource from './commonDataSource';
import UrlManager from 'common/urlManager';
import UserDetailsProcessingError from 'models/userDetailsProcessingError';
import { Publishers } from 'common/publishers';

export default class UserDetailsDataSource {
    /**
     * Returns a promise for updating user-details
     * @param {models.UserDetails} userDetails
     * resolved(userId {string})
     * reject(Error)
     */
    static updateUserDetails(userDetails, skipValidation) {
        return new Promise((resolve, reject) => {
            if (Utils.isNull(userDetails)) {
                reject(new UserDetailsProcessingError('user-details cannot be empty!'));

                return;
            } else if (false === userDetails instanceof UserDetails) {
                reject(
                    new UserDetailsProcessingError(
                        'user-details object needs to be an instance of models.UserDetails',
                    ),
                );

                return;
            }

            if (Utils.isNull(skipValidation) || skipValidation !== true) {
                skipValidation = false;
            }

            if (skipValidation === false) {
                // Validate user-details
                let validationDetails = userDetails.validate();

                if (false === Utils.isNull(validationDetails)) {
                    console.log('USER_DETAILS: Invalid user-details - ', userDetails);
                    console.log('USER_DETAILS: Invalid validation - ', validationDetails);
                    let validationErr = new UserDetailsProcessingError('validation error');
                    validationErr.setValidationErrDetails(validationDetails);
                    reject(validationErr);

                    return;
                }
            }

            let endpoint = UrlManager.getUserDetailsUrl();
            endpoint = CommonDataSource.processUrl(endpoint);
            let headers = {
                'Content-Type': 'application/json',
            };

            Network.post(endpoint, headers, userDetails)
                .then(response => {
                    if (false === response.ok) {
                        throw new Error(`invalid response status - ${response}`);
                    }

                    return response.json();
                })
                .then(responseJson => {
                    console.log('USER_DETAILS Response: ', JSON.stringify(responseJson, null, 4));

                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    resolve(processedResp);
                })
                .catch(respErr => {
                    let customErr = new UserDetailsProcessingError('Error when sending data');
                    customErr.setNonValidationErr(respErr);
                    reject(customErr);
                });
        });
    }

    static validateEmail(email) {
        const url = `${UrlManager.getEmailVerificationUrl()}?email=${email}`;

        return new Promise((resolve, reject) => {
            Network.get(url)
                .then(response => {
                    if (false === response.ok) {
                        throw new Error(`invalid response status - ${response}`);
                    }

                    return response.json();
                })
                .then(responseJson => {
                    console.log('Email validation response: ', JSON.stringify(responseJson));

                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    resolve(processedResp);
                })
                .catch(respErr => {
                    console.error('Error : ', respErr);
                    reject('Error in email validation fetch');
                });
        });
    }

    static validatePhone(phone) {
        const url = UrlManager.getPhoneVerificationUrl();

        const reqBody = {
            phone,
        };

        const headers = {
            'Content-Type': 'application/json',
        };

        return new Promise((resolve, reject) => {
            Network.post(url, headers, reqBody)
                .then(response => {
                    if (false === response.ok) {
                        throw new Error(`invalid response status - ${response}`);
                    }

                    return response.json();
                })
                .then(responseJson => {
                    console.log('Phone validation response: ', JSON.stringify(responseJson));

                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    resolve(processedResp);
                })
                .catch(respErr => {
                    console.error('Error : ', respErr);
                    reject('Error in Phone validation fetch');
                });
        });
    }

    static fetchLocWithUserZip(zip) {
        const url = `${UrlManager.getZipUrl()}?zip=${zip}`;

        return new Promise((resolve, reject) => {
            Network.get(url)
                .then(response => {
                    if (false === response.ok) {
                        throw new Error(`invalid response status - ${response}`);
                    }

                    return response.json();
                })
                .then(responseJson => {
                    console.log('Zip response: ', JSON.stringify(responseJson));

                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    resolve(processedResp);
                })
                .catch(respErr => {
                    console.error('Error : ', respErr);
                    reject('Error while fetching location details');
                });
        });
    }

    static decryptPublisher = async (encryptedData, publisher, initialVector) => {
        try {
            if (
                !Utils.isEmptyStr(encryptedData) &&
                !Utils.isEmptyStr(publisher) &&
                !Utils.isEmptyStr(initialVector) &&
                (publisher.toLowerCase() === 'mjh' || publisher.toLowerCase() === Publishers.MJH)
            ) {
                const response = await fetch(
                    `${UrlManager.getPublisherDecryptionUrl()}?iv=${encodeURIComponent(
                        initialVector,
                    )}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            pubCode: publisher.toLowerCase(),
                            encryptedData,
                        }),
                    },
                );
                const res = await response.json();

                if (!Utils.isEmptyObj(res) && res.decryptedData) {
                    return res.decryptedData;
                }

                return null;
            }

            return null;
        } catch (err) {
            throw new Error(`ERR while decrypting: ${err}`);
        }
    };
}
