import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import { AbTestHelper, FeatureValueHelper } from 'helpers/index';
import Constants from 'common/constants';

export const postConfigInitRedirection = () => {
    const hardRedirectLink = FeatureValueHelper.getHardRedirectOnInitLink();

    if (AbTestHelper.hardRedirectOnInit() && !Utils.isEmptyStr(hardRedirectLink)) {
        console.log(
            '[Debug]: Redirecting to Site',
            hardRedirectLink,
            AbTestHelper.hardRedirectOnInit(),
        );
        window.location = hardRedirectLink;

        return true;
    }

    return false;
};

export const postConfigInitProcessor = () => {
    const { jobType } = Utils.getJobType();
    const keyword = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);

    if (AbTestHelper.upwardJtAsUtmTerm()) {
        if (!keyword && jobType) IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UTM_TERM, jobType);
        else if (!keyword && !jobType)
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UTM_TERM, Constants.DEFAULT_KW);
    }

    if (AbTestHelper.disregardJobType()) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL, Constants.DEFAULT_KW);
    }
};
