import React from 'react';
import { Link, Avatar, Typography, Button, Dialog } from '@material-ui/core';
import { AbTestHelper, RecaptchaHelper } from 'helpers';
import Utils from 'common/utils';
import './jobSearchView.css';
import RouterPrefixHandler from 'common/routePrefixHandler';
import eventManager from 'eventManager/eventManager';
import Constants from 'common/constants';
import { DynamicJobsJobProviderLogoMapping } from 'common/jobtypeKeywordMappings';
import { IdStore } from 'dataStore/index';

const XmlUserInformationPopUp = React.lazy(() => import('./xmlUserInformation'));

class DynamicJobListing extends React.PureComponent {
    constructor(props) {
        super(props);
        this.details = props.details || {};
    }

    handleClick = ele => {
        console.log('clicked!!!!!!!');
        console.log(ele);
        window.l5_trk(ele);
    };

    getImageName = dynFeedDetails => {
        const { JobProvider } = dynFeedDetails;

        if (JobProvider && JobProvider.length) {
            const jpSlitArr = JobProvider.split(' ');

            if (jpSlitArr.length) {
                const imgName = DynamicJobsJobProviderLogoMapping[jpSlitArr[0].toLowerCase()];

                return imgName;
            }
        }

        return '';
    };

    handleFormErrors = errors => {
        console.error(errors);
    };

    shouldComponentUpdate(newProps) {
        if (this.props.open === newProps.open) {
            return false;
        }

        return true;
    }

    render() {
        const {
            URL: url,
            Title: title = '',
            Location: location = '',
            Description: shortDesc = '',
            JobProvider: company = '',
            bid = undefined,
            cpa,
            cpc,
            max_filters,
        } = this.details;
        let { Logo: logo } = this.details;

        if (AbTestHelper.isDXMLlChannel() && url) {
            const jobProviderLogo = this.getImageName(this.details);
            const fallBackLogo = !Utils.isEmptyStr(jobProviderLogo)
                ? jobProviderLogo
                : 'dxml-default';
            logo = !Utils.isEmptyStr(logo)
                ? logo
                : RouterPrefixHandler.getLogoPath(`logos/${fallBackLogo}.png`);
        } else if (!logo || !url) return false;

        const onClick = () => {
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.XML_VISITED) !== '1'
                ? this.props.onSubmitClick()
                : handleSecondOrganicClick();
        };
        const handleSecondOrganicClick = () => {
            window.open(url);
            eventManager.sendEvent(
                Constants.EVENT.GRATIFICATION_AVAIL,
                {
                    adt: title,
                    adst: company,
                    adst2: shortDesc,
                    advid: 0,
                    advnm: 'dxml',
                    ervn: bid,
                    job_redirect_url: url,
                    cpa,
                    cpc,
                    max_filters,
                },
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
        };
        const onClose = () => {
            eventManager.sendEvent(
                Constants.EVENT.GRATIFICATION_AVAIL,
                {
                    adt: title,
                    adst: company,
                    adst2: shortDesc,
                    advid: 0,
                    advnm: 'dxml',
                    ervn: bid,
                    job_redirect_url: Utils.getObjVal(this.details, ['URL']),
                    cpa,
                    cpc,
                    max_filters,
                },
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
            this.props.handleCloseIcon();
        };

        return (
            <div className="jobs-list-wrapper">
                {!AbTestHelper.xmlPopUpTest() && (
                    <Link
                        className={'link-container'}
                        href={url}
                        onClick={e => {
                            eventManager.sendEvent(
                                Constants.EVENT.GRATIFICATION_AVAIL,
                                {
                                    adt: title,
                                    adst: company,
                                    adst2: shortDesc,
                                    advid: 0,
                                    advnm: 'dxml',
                                    ervn: bid,
                                    job_redirect_url: url,
                                    cpa,
                                    cpc,
                                    max_filters,
                                },
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                            RecaptchaHelper.genRecaptcha();
                            this.handleClick(e.currentTarget);
                            console.log(e.target.href);
                        }}
                        target="_blank">
                        <span className="branding-txt">
                            <picture>
                                <source
                                    srcSet={RouterPrefixHandler.getImagePath(
                                        'images/dynXmlSearch.webp',
                                    )}
                                    type="image/webp"
                                />
                                <img
                                    alt={`icon`}
                                    className={'search-icon'}
                                    src={RouterPrefixHandler.getImagePath(
                                        'images/dynXmlSearch.png',
                                    )}
                                />
                            </picture>
                            <span>View your selected job</span>
                        </span>

                        <div className="job-element-card dynamic-feed">
                            <div className="job-content-wrapper">
                                <div className="job-logo-wrapper">
                                    <Avatar src={logo} />
                                </div>

                                <div className="job-description-wrapper">
                                    <Typography variant="h1">{title}</Typography>
                                    <Typography variant="h2">{location}</Typography>
                                    <Link href={url} target="_blank" underline="always">
                                        View Description
                                    </Link>
                                </div>
                            </div>
                            <div className="job-apply-wrapper">
                                <Button href={url} target="_blank">
                                    View Salary
                                </Button>
                            </div>
                        </div>
                    </Link>
                )}
                {AbTestHelper.xmlPopUpTest() && (
                    <>
                        <div className={'link-container xml-pop-up-container'} onClick={onClick}>
                            <span className="branding-txt">
                                <picture>
                                    <source
                                        srcSet={RouterPrefixHandler.getImagePath(
                                            'images/dynXmlSearch.webp',
                                        )}
                                        type="image/webp"
                                    />
                                    <img
                                        alt={`icon`}
                                        className={'search-icon'}
                                        src={RouterPrefixHandler.getImagePath(
                                            'images/dynXmlSearch.png',
                                        )}
                                    />
                                </picture>
                                <span>View your selected job</span>
                            </span>

                            <div className="job-element-card dynamic-feed">
                                <div className="job-content-wrapper">
                                    <div className="job-logo-wrapper">
                                        <Avatar src={logo} />
                                    </div>

                                    <div className="job-description-wrapper">
                                        <Typography variant="h1">{title}</Typography>
                                        <Typography variant="h2">{location}</Typography>
                                        <Link underline="always">View Description</Link>
                                    </div>
                                </div>
                                <div className="job-apply-wrapper">
                                    <Button href={url} target="_blank">
                                        View Salary
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <Dialog
                            className={`listings-bg-dialog xml-user-information-popup ${
                                AbTestHelper.XmlPopUpTestVariant2() &&
                                'xml-user-information-popup-variant2'
                            }`}
                            disableEscapeKeyDown={true}
                            open={this.props.open}>
                            <XmlUserInformationPopUp
                                details={this.details}
                                handleCloseIcon={onClose}
                                handleCloseSubmit={this.props.handleCloseSubmit}
                                handleFormErrors={this.handleFormErrors}
                                logo={logo}
                            />
                        </Dialog>
                    </>
                )}
            </div>
        );
    }
}
export default DynamicJobListing;
