import React, { Component } from 'react';
import 'styles/err_page.css';
import TopBar from 'components/common/topBar';
import RoutePrefixHandler from 'common/routePrefixHandler';

export default class ErrorPage extends Component {
    render() {
        return (
            <div className="err-page-wrapper">
                <TopBar />
                <div className="err-page-content-wrapper">
                    <div className="err-page-img-wrapper">
                        <img
                            alt="err-banner"
                            src={RoutePrefixHandler.getImagePath('images/err-banner.svg')}
                        />
                    </div>
                    <div className="err-page-heading">Oops!</div>
                    <div className="err-page-content">Something went wrong!</div>
                </div>
            </div>
        );
    }
}
