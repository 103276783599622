import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Utils from 'common/utils';
import Constants from 'common/constants';

const useStyles = makeStyles(theme => ({
    captchaContainer: {
        display: 'flex',
        padding: 8,
        height: 'calc(100vh - 110px)',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            padding: 0,
            justifyContent: 'center',
        },
    },
}));

export const RecaptchaChallenge = props => {
    useEffect(() => {
        window.onRecaptchaloadCallback = recaptchaChallengeRender;
        Utils.addScript({
            type: 'text/javascript',
            src:
                'https://www.google.com/recaptcha/api.js?onload=onRecaptchaloadCallback&render=explicit',
            async: true,
            defer: true,
        });

        if (props.onMount) {
            props.onMount();
        }
    }, []);
    const classes = useStyles();

    const recaptchaChallengeRender = () => {
        window.grecaptcha &&
            window.grecaptcha.render &&
            window.grecaptcha.render('recaptcha-challenge', {
                sitekey: Constants.RECAPTCHA.CHALLENGE_KEY,
                callback: verifyRecaptchaResponse,
            });
    };

    const verifyRecaptchaResponse = response => {
        Utils.setCookie('GRC_CHALLENGE', 'passed', 30 * 24 * 60);
        const recaptchaImageSelector = document.querySelector('[title="recaptcha challenge"]');

        if (
            recaptchaImageSelector &&
            recaptchaImageSelector.parentElement &&
            recaptchaImageSelector.parentElement.parentElement
        ) {
            recaptchaImageSelector.parentElement.parentElement.innerHTML = '';
        }
        props.recaptchaSuccessfulResponse(response);
    };

    return <div className={classes.captchaContainer} id="recaptcha-challenge"></div>;
};
