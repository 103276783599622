export default {
    SINGLE_JOB_AD_AU: 'SINGLE_JOB_AD_AU',
    SKIP_TO_TAB_AD_UNIT_DATA: 'SKIP_TO_TAB_AD_UNIT_DATA',
    JOB_AD_META_DATA: 'JOB_AD_META_DATA',
    JOB_AD_AU: 'JOB_AD_AU',
    JOB_AD_SOURCE_IDS: 'JOB_AD_SOURCE_IDS',
    JT_JOB_AD_INFO_DATA: 'JT_JOB_AD_INFO_DATA',
    JOB_AD_SOURCES_UUID_MAP: 'JOB_AD_SOURCES_UUID_MAP',
    INITIAL_VIEW_PORT_HEIGHT: 'INITIAL_VIEW_PORT_HEIGHT',
};
