/**
 * UserDetailsProcessingError contains errors when processing user-details.
 * If user-details validation error occurs, then isValidationErr is set to true, with the object in validationErrDetails.
 * The other errors are stored in nonValidationErr.
 */
export default class UserDetailsProcessingError extends Error {
    constructor(message) {
        super(message);
        this.name = 'UserDetailsProcessingError';
        this.validationErrDetails = null;
        this.nonValidationErr = null;
        this.isValidationErr = false;
    }

    setValidationErrDetails(validationDetails) {
        this.isValidationErr = true;
        this.validationErrDetails = validationDetails;
    }

    setNonValidationErr(nonValidationErr) {
        this.isValidationErr = false;
        this.nonValidationErr = nonValidationErr;
    }
}
