module.exports = {
    COMPONENT_INCLUSIONS: {
        EXPLORE_JOBS_FORM_JOB_TYPE: true,
        EXPLORE_JOBS_FORM_LOGO: false,
        USER_INFO_FORM_LOGO: true,
        USER_AGE_FORM_LOGO: true,
        USER_AGE_FORM_DOB_FORMAT: true,
        FORM_JOB_EMPLOYER_DEFAULT_VALUE: '',
    },
    TEXT_CONTENTS: {
        FOOTER_DISCLOSURE_LINK: 'newjobscanner.com',
        FOOTER_DISCLOSURE: 'MyJobsCorner.com, EveryJobForMe.com, NewJobConnections',
        MANAGE_PARTNERS: ['Neuvoo', 'Arcamax Job Updates'],
        TCPA_MANAGE_PARTNERS: ['Marketing USA'],
        EXPLORE_JOBS_PAY: '$17 - $35 /hr',
        OTHER_EMPLOYER_TEXT: 'Other potential employers in your area',
        EXPLORE_JOBS_FORM_SUBMIT_BUTTON: 'VIEW',
        EXPLORE_JOBS_FORM_CONTINUE_BUTTON: 'CONTINUE',
        EXPLORE_JOBS_INFO:
            ' is a job search engine. We are not an agent or representative of any employer',
        EXPLORE_JOBS_FORM_TITLE: 'Jobs',
        EXPLORE_JOBS_FORM_TITLE_PART1: 'Explore',
        EXPLORE_JOBS_FORM_TITLE_PART2: 'near you',
        DOB_USER_INPUT_DATE_FORMAT: 'YYYY-MM-DD',
        MIN_YEAR: '1900',
        EXPLORE_JOBS_CONTAINER: 'See other popular jobs',
        USER_AGE_FORM_SUBMIT_BUTTON: {
            IS_SUBMITTING: 'Submitting..',
            DEFAULT: 'Continue',
        },
    },
    FIELD_ANIMATIONS: {
        TRANSITION_APPEAR: false,
        TRANSITION_APPEAR_TIMEOUT: 1000,
        TRANSITION_LEAVE: false,
        TRANSITION_NAME: 'slide-down',
        TRANSITION_ENTER_TIMEOUT: 1000,
        TRANSITION_LEAVE_TIMEOUT: 1000,
    },

    EVENTNAME: {
        CONTACTUS: 'contactus',
        UNSUBSCRIBE: 'unsubscribe',
        SUBSCRIBE: 'subscribe',
    },
    MARKETING_PARTNERS: 'Marketing Partners',
    MARKETING_PARTNERS_LIST: [
        'Jobsflag Alerts',
        'Assistance National USA',
        'Life Insurance Matchup',
        'Presidio Interactive',
        'Clean Energy Concept',
        'Dvinci',
        'Bright Home Solar',
        'US Energy Solutions',
        'Career Resource Center',
        'Senor Aid Helper',
        'Fuentes',
        'School Match Pro about educational opportunities',
        'Top Job Explorer',
        'Ad Heavens - ADH',
        'Claim Your Benefits',
        'AWTRVL',
        'GRN',
        'FFHF',
        'SC',
        'AHRA',
        'RVA',
        'SS',
        'YAN',
        'YGA',
        'Rocket Jobs',
        'National Disability',
        'United States Disability',
        'American Disability',
        'American Disability Helpline',
        'Home Support Group',
        'Support First',
        'Medical Support Group',
        'Choice Home Warranty',
        'Hello Warranty',
        'Full Spectrum Naturals',
        'Medicare Supplement',
        'Medicare Part D',
        'Medicare Advantage',
        'RateSearch.net',
        'Alliance Tax Advocate',
    ],
};
