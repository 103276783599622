import React, { Component } from 'react';
import LandingPage from 'components/landing';
import analyticsManager from 'eventManager/analyticsManager';
import CommonAppWrapper from './commonAppWrapper';
import Constants from 'common/constants';
import EventManager from 'eventManager/eventManager';
import { IdStore } from 'dataStore';
import Utils from 'common/utils';
import JobSearchDataSource from 'datasource/jobsSearchDataSource';
import UserDataStore from 'components/landing/userDataStore';
import { withRouter } from 'react-router-dom';
import RoutePrefixHandler from 'common/routePrefixHandler';
import AbManager from '../abManager/abTest';
import { AbTestHelper } from 'helpers/index';
import { fetchSkipToTabAdUnit } from 'helpers/adUnits/adUnitFetchHelpers';

const InterstitialAdsVariation2 = React.lazy(() =>
    import('components/landing/forms/exploreJobs/interstitialAdsVariation2'),
);

class LandingApp extends Component {
    constructor(props) {
        super(props);
        this.setState({
            showInterstitialAds: false,
        });
        this.goToListings = false;
        this.popState = this.onPopState.bind(this);
        !AbTestHelper.disableBackButtonListings() &&
            window.addEventListener('popstate', this.popState);
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.CURRENT_APP, Constants.EVENT_TYPE.LANDING);
        const abt6 = AbManager.getAbt6();

        switch (abt6) {
            case 'rg_nonbillable_skip_listings':
                this.props.history.push({
                    pathname: RoutePrefixHandler.generateActualPathname(
                        Constants.PAGE_ENDPOINTS.GRATIFICATION,
                    ),
                    search: `${window.location.search}&lrdr=rg_non_bl_listings`,
                });
                break;
            case 'rg_nonbillable_skip_offers':
                this.props.history.push({
                    pathname: RoutePrefixHandler.generateActualPathname(
                        Constants.PAGE_ENDPOINTS.ADS,
                    ),
                    search: `${window.location.search}&lrdr=rg_non_bl_offers`,
                });
                break;
            default:
                break;
        }

        if (AbTestHelper.listingsAsLandingFlow()) {
            this.props.history.push({
                pathname: RoutePrefixHandler.generateActualPathname(
                    Constants.PAGE_ENDPOINTS.GRATIFICATION,
                ),
            });
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.FIRE_LANDING_IMP_ON_GRAT, true);
        }
    }

    onPopState(e) {
        let hash = window.location.hash;

        if (hash) {
            hash = hash.replace('#', '');
        }

        if (
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.BACK_INTERSTITIAL) === '1' &&
            Utils.enableBack2Listings(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE))
        ) {
            this.props.history.push({
                pathname: RoutePrefixHandler.generateActualPathname(
                    Constants.PAGE_ENDPOINTS.GRATIFICATION,
                ),
                search: `${window.location.search}&lrdr=landing${hash}`,
            });
        }
    }

    async componentWillMount() {
        this.setState({
            showInterstitialAds: false,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.popState);
        window.removeEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED,
            this.sessionCallback,
            false,
        );
        window.removeEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCH_FAILED,
            this.sessionFailureCallback,
            false,
        );
    }

    async componentDidMount() {
        analyticsManager.pageView('Landing', window.location.pathname + window.location.search);
        this.sessionCallback = this.sessionInitializedEventHandler.bind(this);
        window.addEventListener(Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED, this.sessionCallback);
        window.addEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCH_FAILED,
            this.sessionFailureCallback,
        );
    }

    fireLandingImp2 = () => {
        if (
            AbTestHelper.shouldFireLpImp2() &&
            !(
                AbTestHelper.brandedOfferFedExTest() ||
                AbTestHelper.brandedOfferAmazonTest() ||
                AbTestHelper.brandedOfferTest() ||
                AbTestHelper.skipToNewTabV3()
            )
        ) {
            EventManager.sendEvent(
                Constants.EVENT.LANDING_PAGE_SPECIAL_IMP,
                {},
                Constants.EVENT_TYPE.LANDING,
            );
        }
    };

    fireLandingImp = () => {
        if (!Utils.getValForKeyFromCurrentUrl('s2nt')) {
            EventManager.sendEvent(
                Constants.EVENT.LANDING_PAGE_IMP,
                {},
                Constants.EVENT_TYPE.LANDING,
            );

            if (AbTestHelper.skipToNewTabV2()) {
                fetchSkipToTabAdUnit().finally(this.fireLandingImp2);
            } else {
                this.fireLandingImp2();
            }
        }
    };

    sessionFailureCallback = () => {
        if (!Utils.getValForKeyFromCurrentUrl('s2nt')) {
            this.fireLandingImp();
        }
        window.removeEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCH_FAILED,
            this.sessionFailureCallback,
            false,
        );
    };

    sessionInitializedEventHandler() {
        if (!Utils.getValForKeyFromCurrentUrl('s2nt')) {
            this.fireLandingImp();
        }

        window.removeEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED,
            this.sessionCallback,
            false,
        );
        this.updateJobsDataForInterstitial();
    }

    render() {
        const { showInterstitialAds, jobs } = this.state;

        return (
            <div className={`app-wrapper`}>
                {jobs && showInterstitialAds && (
                    <InterstitialAdsVariation2
                        handleClose={this.hideInterstitial.bind(this)}
                        jobs={jobs}
                        open={showInterstitialAds}
                    />
                )}
                <LandingPage {...this.props} />
            </div>
        );
    }

    hideInterstitial() {
        this.setState({
            showInterstitialAds: false,
        });
        this.goToListings = true;
    }

    updateJobsDataForInterstitial() {
        const oldUserDetails = UserDataStore.fetchUserDetailsFromStore();
        let zip =
            Utils.getValForKeyFromCurrentUrl('z') ||
            (oldUserDetails && oldUserDetails.zip) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE);
        const term = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);

        if (zip) {
            this.updateJobsDataForQuery(
                JobSearchDataSource.createJobsQueryForTerm({
                    term,
                    pageNume: 1,
                    pageSize: 4,
                    location: zip,
                    target: 'count',
                    source: 'upward',
                }),
            );
        }
    }

    updateJobsDataForQuery(jobsQuery) {
        JobSearchDataSource.fetchJobsDataForQuery(jobsQuery)
            .then(jobsData => {
                console.log('JOBS: got jobs data - ', jobsData);

                let { totalCount: totalJobs } = jobsData;

                if (Utils.isNull(totalJobs)) {
                    totalJobs = 0;
                }
                let { jobs = [] } = jobsData;

                this.setState({
                    jobs,
                });
            })
            .catch(e => {
                console.log('JOBS: ERR: could not fetch the data - ', e);
            });
    }
}
export default CommonAppWrapper(withRouter(LandingApp), {
    showSubFooter: true,
    lndScrollCls: 'sv-scroll-header',
});
