import Constants from 'common/constants';
import EventManager from 'eventManager/eventManager';
import { IdStore } from 'dataStore';
import Utils from 'common/utils';
import UserDetails from 'models/userDetails';
import { getConcatAddressField } from './eventDataHelpers';
import { FeatureValueHelper } from 'helpers/index';
import { LABELS } from 'strings/labels';
import { FeatureMap } from 'providers';

export const _getBaseEduOptionLeadData = userDetails => {
    const eduPartners = FeatureValueHelper.getFeatureValues(FeatureMap.EDUPartners);

    const currentLeadId =
        document.getElementById('leadid_token') && document.getElementById('leadid_token').value;
    const jt =
        IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL) ||
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);
    /*
     * const { valueName, name } = this.model.currentPage.elements[0];
     * const qName = (valueName || name).split('__').reverse()[0];
     * const no = this.model.currentPageNo;
     */
    let tfCertUrl =
        document.getElementById('xxTrustedFormToken_0') &&
        document.getElementById('xxTrustedFormToken_0').value;

    return {
        optxt: LABELS.EDU_OPTIN_TCPA(eduPartners, userDetails.phone_number),
        leadid: currentLeadId,
        dob: userDetails.dob,
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        zip: userDetails.zip_code,
        phone_number: userDetails.phone_number,
        email: userDetails.email,
        evs: userDetails.evs,
        address: getConcatAddressField(userDetails.address),
        job_type: jt,
        /*
         * qnm: qName,
         * qno: no,
         *
         */
        tf_curl: tfCertUrl,
    };
};

const _getBaseSurveyQuestionLeadData = userDetails => {
    let jt =
        IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL) ||
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL) ||
        Constants.DEFAULT_KW;
    let leadId = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REG2_LEADID);
    let tfCertUrl = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REG2_TRUSTED_FORM_CERT_URL);

    let geo = {};
    // city, country, region
    let city = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_CITY);

    if (false === Utils.isNull(city)) {
        geo['city'] = city;
    }

    let country = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_COUNTRY);

    if (false === Utils.isNull(country)) {
        geo['country'] = country;
    }

    let region = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_REGION);

    if (false === Utils.isNull(region)) {
        geo['state'] = region;
        geo['region'] = region;
    }
    let zip = '';

    if (userDetails && userDetails.zip_code) {
        zip = userDetails.zip_code;
    } else {
        zip = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE);
    }

    geo['zip'] = zip;

    let address = '';

    if (userDetails.address) {
        address = userDetails.address.line1;

        if (userDetails.address.line1) {
            if (userDetails.address.line2) {
                address = `${userDetails.address.line1} ${userDetails.address.line2}`;
            } else {
                address = userDetails.address.line1;
            }
        }
    }

    return {
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        email: userDetails.email,
        gender: userDetails.gender,
        dob: userDetails.dob,
        address,
        zip: geo.zip,
        city: geo.city,
        region: geo.state,
        country: geo.country,
        phone_number: userDetails.phone_number,
        evs: userDetails.evs,
        job_type: jt,
        leadid: leadId,
        tf_curl: tfCertUrl,
    };
};

const _fireEduOptionQuestionNegation = userDetails => {
    const eventData = _getBaseEduOptionLeadData(userDetails);

    const questionEventData = _getBaseSurveyQuestionLeadData(userDetails);

    EventManager.sendEvent(
        Constants.EVENT.HNP_LEAD,
        { ...eventData, cat: 'edu_optin_no' },
        Constants.EVENT_TYPE.DATA,
    );

    EventManager.sendEvent(
        Constants.EVENT.HNP_LEAD,
        { ...questionEventData, cat: 'auto_insurance_delayed' },
        Constants.EVENT_TYPE.DATA,
    );
};

const _fireEduOptinQuestionLeads = userDetails => {
    EventManager.sendEvent(
        Constants.EVENT.HNP_LEAD,
        {
            cat: 'edu_first_7_days',
            ..._getBaseSurveyQuestionLeadData(userDetails),
        },
        Constants.EVENT_TYPE.DATA,
    );
    EventManager.sendEvent(
        Constants.EVENT.HNP_LEAD,
        {
            cat: 'dms_edu_30_days',
            ..._getBaseSurveyQuestionLeadData(userDetails),
        },
        Constants.EVENT_TYPE.DATA,
    );

    EventManager.sendEvent(
        Constants.EVENT.HNP_LEAD,
        {
            cat: 'edu_optin',
            ..._getBaseEduOptionLeadData(userDetails),
        },
        Constants.EVENT_TYPE.DATA,
    );
};

const getUserFromCache = () => {
    const userDetailsCache =
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS) &&
        JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS));

    return new UserDetails(userDetailsCache);
};

export const fireHnpReg2HnpImp = () =>
    EventManager.sendEvent(Constants.EVENT.HNP_IMP, { cat: 'reg2' }, Constants.EVENT_TYPE.DATA);

export const fireHnpReg2HnpLead = (payload = {}) => {
    const {
        sid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SURVEY_ID),
        jt = IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL) ||
            Constants.DEFAULT_KW,
        leadId = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REG2_LEADID),
        tfCertUrl = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REG2_TRUSTED_FORM_CERT_URL),
        userDetails = getUserFromCache(),
    } = payload;

    const eventData = {
        cat: 'reg2',
        first_name: userDetails.firstName,
        last_name: userDetails.lastName,
        email: userDetails.emailId,
        gender: userDetails.gender,
        address: getConcatAddressField(userDetails.address),
        phone_number: userDetails.phoneNumber,
        optxt:
            sid === Constants.SID_SMS_COMPLIANCE
                ? Constants.TCPA_OPTIN_TEXT_SMS
                : `${
                      Constants.TCPA_OPTIN_TEXT +
                      (Utils.isLiveSurvey() ? Constants.TCPA_EXTRA_PARTNERS : '')
                  }Marketing Partners ${Constants.TCPA_OPTIN_TEXT_CONT}`,
        evs: userDetails.evs,
        job_type: jt,
        leadid: leadId,
        tf_curl: tfCertUrl,
    };
    EventManager.sendEvent(Constants.EVENT.HNP_LEAD, eventData, Constants.EVENT_TYPE.DATA);
};

export const fireHnpEmailHnpImp = (type = Constants.EVENT_TYPE.DATA) =>
    EventManager.sendEvent(Constants.EVENT.HNP_IMP, { cat: 'email' }, type);

export const firePrefChangeHnpLead = (data = {}, type = Constants.EVENT_TYPE.DATA) => {
    const userDetails = getUserFromCache();
    const eventData = {
        cat: 'email_pref',
        email: userDetails.emailId,
        ...data,
    };
    EventManager.sendEvent(Constants.EVENT.HNP_LEAD, eventData, type);
};

export const fireHnpLeadUserRepeat = (data = {}, type = Constants.EVENT_TYPE.DATA) => {
    const eventData = {
        cat: 'user_repeat',
        ...data,
    };
    EventManager.sendEvent(Constants.EVENT.HNP_LEAD, eventData, type);
};

export const fireHnpLeadNetSpendRepeat = (data = {}, type = Constants.EVENT_TYPE.DATA) => {
    const eventData = {
        cat: 'netspend_repeat',
        ...data,
    };
    EventManager.sendEvent(Constants.EVENT.HNP_LEAD, eventData, type);
};

export const fireHnpLeadEmailRepeat = (data = {}, type = Constants.EVENT_TYPE.DATA) => {
    const eventData = {
        cat: 'email_repeat',
        ...data,
    };
    EventManager.sendEvent(Constants.EVENT.HNP_LEAD, eventData, type);
};

export const fireEduOptionImp = () => {
    const eventData = {
        cat: 'edu_optin',
    };
    EventManager.sendEvent(Constants.EVENT.HNP_IMP, eventData, Constants.EVENT_TYPE.DATA);

    EventManager.sendEvent(
        Constants.EVENT.SURVEY_QUESTION_IMP,
        { qnm: 'interests_eduoptin', qid: 'interests_eduoptin' },
        Constants.EVENT_TYPE.LANDING,
    );
};

export const fireEduOptinQuestionLeads = ({ isSkipped, userData, eduQuestionSelect }) => {
    if (isSkipped) {
        EventManager.sendEvent(
            Constants.EVENT.HNP_SKIP,
            {
                cat: 'edu',
            },
            Constants.EVENT_TYPE.DATA,
        );
        _fireEduOptionQuestionNegation(userData);
    } else if (eduQuestionSelect === 'Never') {
        _fireEduOptionQuestionNegation(userData);
    } else {
        _fireEduOptinQuestionLeads(userData);
    }

    if (!isSkipped) {
        const selectedAnswer = eduQuestionSelect ? eduQuestionSelect : '';
        const eduOptinSurveyData = {
            qnm: 'interests_eduoptin',
            qid: 'interests_eduoptin',
            optvl: selectedAnswer,
        };

        EventManager.sendEvent(
            Constants.EVENT.SURVEY_QUESTION_ANSWERED,
            eduOptinSurveyData,
            Constants.EVENT_TYPE.LANDING,
        );
    }
};
