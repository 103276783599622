const JobTypes = (window.CNXBucket &&
    window.CNXBucket.JobTypes &&
    (Array.isArray(window.CNXBucket.JobTypes) && window.CNXBucket.JobTypes.length
        ? window.CNXBucket.JobTypes
        : null)) || [
    'walmart',
    'amazon',
    'fedex',
    'walgreens',
    'target',
    'burger king',
    'coca cola',
    'costco',
    'cvs',
    'cvs health',
    'goodwill',
    'home depot',
    'kfc',
    'kohls',
    'kroger',
    'macys',
    'macy',
    'marshalls',
    'mcdonalds',
    'moes',
    'panasonic',
    'pepsi',
    'pizza hut',
    'sodexo',
    'starbucks',
    'subway',
    'taco bell',
    'waste management',
    'uber',
    'ups',
    'wendys',
    'winn dixie',
    'dollar general',
    'whole foods',
    'whataburger',
    'wawa',
    'usps',
    'union pacific railroad',
    'tractor supply company',
    'sprint',
    'sams club',
    'petsmart',
    'old navy',
    'nike',
    'marriott',
    'lowes',
    'little caesars',
    'kelly services',
    'jack in the box',
    'hobby lobby',
    'hilton',
    'hardees',
    'gamestop',
    'frito lay',
    'ford',
    'foot locker',
    'food lion',
    'five below',
    'dominos pizza',
    'dollar tree',
    'circle k',
    'chick fil a',
    'aramark',
    'aldi',
    '7 eleven',
    'popeyes',
    'family dollar',
    'michaels',
    'ihop',
    'autozone',
    'dunkin donuts',
    'quicktrip',
    'chipotle',
    'staffmark',
    'publix',
    'albertsons',
    'ross stores',
    'dairy queen',
    'biglots',
    'american airlines',
    'shipt',
    'seasonal',
    'delta airlines',
    'jetblue airways',
    'dhl',
    'best buy',
    'ikea',
    'kohler',
    'maxim healthcare',
    'medtronic',
    'united healthcare',
    'tsa',
    'fema',
    'baskin robbins',
    'baskin robbin',
    'appleone',
    'apple',
    'doordash',
    'ecs',
    'microsoft',
    'panera bread',
    'postmates',
    'tjmaxx',
    'smiths',
    'tesla',
    'delta',
    'jbhunt',
    'scholastic',
    'people ready',
    'duke power',
    't mobile',
    'gopuff',
    'lyft',
    'dmv',
    'bed bath and beyond',
    'indeed',
    'rural king',
    'bucees',
    "buc ee's",
    'buc ees',
    'bucee',
    'bashas',
    'white castle',
    'lulu lemon',
    'under armour',
    'under amour',
    'adecco group',
    'amazon fresh',
    'aston carter',
    'hallmark',
    'hr block',
    'hrblock',
    'h%26r block',
    'h&r block',
    'mac',
    'm%c3%84c',
    'mäc',
    'nordstrom',
    'pearson',
    'petco',
    'randstad',
    'sephora',
    'ulta beauty',
    'united airlines',
    'valvoline',
    'salesforce',
    'bass pro shops',
    'cisco',
    'greyhound',
    'megabus',
    'nvidia',
    'red bull',
    'tropicana',
    'sony',
    'vamoose',
];

export default JobTypes;
