import Utils from 'common/utils';
import Constants from 'common/constants';

export const SYNC = 0;
export const ASYNC = 1;
export default class WebStoreManager {
    constructor(storageVal) {
        // Check if the storage val is valid
        if (false === this.isStoreValid(storageVal)) {
            storageVal = null;
        }
        this.store = storageVal;
        this.inMemStore = {};
        this.syncInterval = setInterval(() => {
            this.sync();
        }, 5000);
        this.mode = Constants.WEBSTORE_CONSTANTS.ASYNC;
    }

    setMode(mode) {
        if (mode === Constants.WEBSTORE_CONSTANTS.SYNC) {
            clearInterval(this.syncInterval);
            this.sync();
        } else {
            this.syncInterval = setInterval(() => {
                this.sync();
            }, 5000);
        }
        this.mode = mode;
    }

    sync() {
        for (let [key, value] of Object.entries(this.inMemStore)) {
            this.setItemInternal(key, value);
        }
    }

    /**
     * Check if the store is a valid web-store
     * @param {object} store
     */
    isStoreValid(storeArg) {
        return storeArg instanceof Storage;
    }

    /**
     * @returns {boolean} True if webStore is available
     */
    isStoreAvailable() {
        return false === Utils.isNull(this.store);
    }

    /**
     * Get value for the key from store.
     * @param {string} key
     * @returns {string|null}
     */
    getItem(key) {
        let val = this.inMemStore[key];

        if (!Utils.isNull(val)) return val;

        try {
            if (Utils.isEmptyStr(key)) {
                throw new Error('key should not be an empty string');
            }

            if (false === this.isStoreAvailable()) {
                throw new Error('Store not available');
            }
            val = this.store.getItem(key);
        } catch (e) {
            console.log(`ERR: Error when getting ${key} from store ${e}`);
        } finally {
            this.inMemStore[key] = val;

            return val;
        }
    }

    /**
     * Returns all the entries from the store
     * @returns (Object|null)
     */
    getAll() {
        let allObjects = {};

        try {
            if (false === this.isStoreAvailable()) {
                throw new Error('web-store not available');
            }

            for (let i = 0; i < this.store.length; i++) {
                let key = this.store.key(i);

                if (Utils.isEmptyStr(key)) {
                    continue;
                }
                let val = this.store.getItem(key);

                if (Utils.isEmptyStr(val)) {
                    continue;
                }
                allObjects[key] = val;
            }
        } catch (e) {
            console.log(`ERR: Error when getting all keys from store ${e}`);
        } finally {
            if (this.mode === Constants.WEBSTORE_CONSTANTS.ASYNC) {
                console.log('assigning inmem kv');
                allObjects = Object.assign(allObjects, this.inMemStore);
            }

            if (Utils.isEmptyObj(allObjects)) {
                return null;
            }

            return allObjects;
        }
    }

    /**
     * Set items into the store
     * @param {string} key
     * @param {string} val
     */
    setItem(key, val) {
        this.inMemStore[key] = val;

        if (this.mode === Constants.WEBSTORE_CONSTANTS.SYNC) {
            this.setItemInternal(key, val);
        }

        return;
    }

    setItemInternal(key, val) {
        try {
            if (Utils.isEmptyStr(key) || Utils.isEmptyStr(val)) {
                throw new Error('key & values should not be an empty strings');
            }

            if (false === this.isStoreAvailable()) {
                throw new Error('Store not available');
            }
            this.store.setItem(key, val);
        } catch (e) {
            console.log(`ERR: Error when setting ${key}=>${val} into store ${e}`);
        }

        return;
    }

    /**
     * Remove item with key from web-store
     * @param {string} key
     */
    removeItem(key) {
        if (this.inMemStore[key]) {
            delete this.inMemStore[key];
        }

        try {
            if (Utils.isEmptyStr(key)) {
                throw new Error('key should not be an empty string');
            }

            if (false === this.isStoreAvailable()) {
                throw new Error('Store not available');
            }
            this.store.removeItem(key);
        } catch (e) {
            console.log(`ERR: Error when removing ${key} from store ${e}`);
        }
    }

    /**
     * Remove all the entries from the store
     */
    removeAll() {
        this.inMemStore = {};

        try {
            if (false === this.isStoreAvailable()) {
                throw new Error('Store not available');
            }
            this.store.clear();
        } catch (e) {
            console.log(`ERR: Error when clearing all from store`);
        }
    }
}
