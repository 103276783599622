import Constants from 'common/constants';
import { IdStore } from 'dataStore';
import Utils from 'common/utils';

const parseValueToArray = value => {
    let arr = [];

    if (value && value.length) {
        arr = value.split(',');
    }

    return arr;
};

const checkValueInArray = (array, value) => {
    return array.includes(value);
};

const getCurrentRoute = () => {
    const pathname = window.location.pathname;

    return pathname.replace('/', '');
};

const isNull = value => {
    return typeof value === 'undefined' || value === null;
};

/**
 * Convert array to object with array values as object keys
 * @param {Array<String>} arr Array to converted into object
 * @param {any} [value] Value to be assigned to each key. Defaults to key
 * @returns {object} Returns generated object
 */
const arrayToObj = (arr, value) => {
    if (Array.isArray(arr) && arr.length) {
        const obj = arr.reduce((accumulator, currentValue) => {
            accumulator[currentValue] = value ? value : currentValue;

            return accumulator;
        }, {});

        return obj;
    }

    return {};
};

const antiSpamPixel = cb => {
    if (IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.ASA_PIXEL)) {
        return;
    }
    const cs_id = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);
    const source = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);
    const campaign = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN);
    const term = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);
    const medium = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM);
    const svid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SURVEY_ID);
    const uuid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UUID);
    const sid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SESSION_ID);
    const ps = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUBLISHER_SUBID);
    const pss = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUBLISHER_SUB_SOURCE);
    const route = window.location.pathname;
    const hash = window.location.hash;
    let templateId;

    if (route === Constants.PAGE_ENDPOINTS.LANDING) {
        if (hash === '#0') {
            templateId = Constants.ANTI_SPAM_TEMPLATE_ID['/landing']['lander'];
        } else {
            templateId = Constants.ANTI_SPAM_TEMPLATE_ID['/landing']['registration'];
        }
    } else {
        templateId = Constants.ANTI_SPAM_TEMPLATE_ID[route];
    }

    Utils.addScript(
        {
            type: 'text/javascript',
            id: 'antisp',
        },
        `try{var saScript=document.createElement("script");saScript.src="https://pxlrtpge-a.akamaihd.net/javascripts/browserfp.min.js?templateId=${templateId}",saScript.async="async",saScript.onload=function(){try{var t=new browserfp,a={template_id:${templateId},sid:"${sid}",uuid:"${uuid}",ps:"${ps}",pss:"${pss}",svid:"${svid}",utm_campaign:"${campaign}",utm_medium:"${medium}",utm_term:"${term}",utm_source:"${source}",cs_id:"${cs_id}",test:${
            Utils.isTestEnv() ? 1 : 0
        }};t.setPageAttr(a),t.sendViewData(); ${
            cb && cb(route)
        }}catch(t){}},document.body.appendChild(saScript)}catch(t){}
        `,
        (() => {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.ASA_PIXEL, 'success');
        })(),
    );
};

export { parseValueToArray, checkValueInArray, getCurrentRoute, isNull, arrayToObj, antiSpamPixel };
