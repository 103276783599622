import React from 'react';
import JobsSearchView from './jobsSearchView';
import JobSearchDataSource from 'datasource/jobsSearchDataSource';
import Utils from 'common/utils';
import Constants from 'common/constants';
import RoutePrefixHandler from 'common/routePrefixHandler';
import { IdStore } from 'dataStore';
import UserDataStore from 'components/landing/userDataStore';
import Pagination from 'react-paginating';
import { withRouter } from 'react-router-dom';
import InterstitialAds from '../../common/interstititalAdsCarousel';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import { UserInfoInterstitial } from './userInfoInterstitial';
import AbManager from 'abManager/abTest';
import eventManager from 'eventManager/eventManager';
import { ConfigProvider, FeatureMap } from 'providers';
import { AbTestHelper } from 'helpers';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import FbGoogleFbGoogleEmailRegistration from './fbGoogleInterstitialEmail';
import EmailPopupToggleContainer from 'components/common/EmailPopupToggle';
import TargetingManager from 'targetingManager';
import AdsDataSource from 'datasource/adsDataSource';
import memoryStore from 'dataStore/memoryStore';
import memoryStoreKeys from 'common/memoryStoreKeys';
import { withStyles } from '@material-ui/core/styles';
import { FeatureValueHelper } from 'helpers/index';

import {
    fetchJTJobs,
    pickAdvertisersDataForJobResults,
    setupAdSourceForSingleSource,
    setupAdSourcesForJTJobsAdvertisers,
} from 'helpers/jobs/jobSourceHelpers';
import OfferManager from 'components/ads/offerManager';

class JobsSearchContainer extends React.Component {
    constructor(props) {
        super(props);

        this.isListingsInBg = AbTestHelper.listingsInBgFlow();
        const GoogleDisplayKwTest = ConfigProvider.Features.getFeature(FeatureMap.GoogleDisplayKw);
        this.isGoogleDisplayKwTest =
            GoogleDisplayKwTest && GoogleDisplayKwTest.value === 'google_kw_flow';
        this.mergedJobFeed = AbManager.getAbt7() === 'merged-job-feed';
        this.isListingsInBgWithZip = AbTestHelper.listingsInBgWithZip();
        const [term, queryTerm, location] = this.findParams();
        const selectedAdvertiser = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_ADS_SOURCE);
        const medium = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM);
        this.isEmailOrSMSMedium =
            !Utils.isEmptyStr(medium) &&
            (medium.toLowerCase().includes('sms') || medium.toLowerCase().includes('email'));
        const listingsJobDedup = ConfigProvider.Features.getFeature(FeatureMap.ListingsJobDedup);
        this.isJobImpDedup = listingsJobDedup ? listingsJobDedup.value === 'job-imp-dedup' : false;
        this.isJobClickDedup = listingsJobDedup
            ? listingsJobDedup.value === 'job-click-dedup'
            : false;
        const initPageNum = this.getInitPageNum(
            this.isEmailOrSMSMedium,
            this.isJobImpDedup,
            term,
            selectedAdvertiser,
        );
        this.initPageNum = initPageNum;
        this.state = {
            searchTerm: term,
            queryTerm: queryTerm,
            isLoadingJobs: true,
            jobsList: [],
            jtJobsList: [],
            resultTitle: '',
            currentPageNum: initPageNum,
            jobLoc: '',
            totalJobs: 0,
            pageSize: Constants.JOBS_SEARCH.DEFAULT_JOB_RESULTS_PER_PAGE,
            location: location,
            shouldSendEvents: this.props.sendEvents,
            shouldScroll: false,
            showInterstitialAds: true,
            selectedAdvertiser: selectedAdvertiser,
            defaultJobBrand: this.getDefaultJobBrand(),
            showDialog: true,
            showFbGoogleInterstitial: false,
            backButtonClick: 0,
            showXmlPopup: false,
            showNeuvooWidget: false,
            nuevooWidgetLoading: true,
        };

        !AbTestHelper.adWorksExternalNotificationPushEnabled() &&
            !AbTestHelper.disableBackButtonBehaviour() &&
            !AbTestHelper.disableBackButtonListings() &&
            this.props.history.push({
                pathname: window.location.pathname,
                search: window.location.search,
                state: {
                    index: initPageNum,
                },
                hash: window.location.hash,
            });

        // this.showInHouseAds = !isMediumSMS
        this.escFunction = this.escFunction.bind(this);
        this.onPopState = this.onPopState.bind(this);
        const FbGoogleEmailInterstitial = AbTestHelper.fbGoogleEmailInterstitial();
        this.showFbGoogleInterstitial =
            FbGoogleEmailInterstitial &&
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.EMAIL_SUB) !== 'true';
        this.showFbGoogleInterstitialUrl = null;
        this.newFulfillmentPage = AbTestHelper.isFulfillmentPageNewDesign();
        this.isFulfillmentDesignPhaseTwo = AbTestHelper.isFulfillmentDesignPhaseTwo();
        const lrdrFromUrl = Utils.getValForKeyFromCurrentUrl('lrdr');
        this.isbackButtonClicked = !Utils.isNull(lrdrFromUrl) && !Utils.isEmptyStr(lrdrFromUrl);
        this.isNeuvooWidgetTestActive = AbTestHelper.neuvooWidgetTest();
        this.neuvooWidgetAdData = null;
        this.jtAdSourcePromise = setupAdSourcesForJTJobsAdvertisers();

        const adSourceIds = memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS) || [];

        if (!adSourceIds.length) {
            setupAdSourceForSingleSource(Constants.ADVERTISERS.UPWARD);
        }
    }

    getInitPageNum(isEmailOrSMSMedium, isJobDedup, searchTerm, selectedAdvertiser) {
        let initPageNum = 1;

        if (isEmailOrSMSMedium) {
            if (isJobDedup) {
                const cookieData = Utils.getCookie('listingJobImpDedup');

                if (!Utils.isEmptyStr(cookieData)) {
                    const parsedCookieData = JSON.parse(cookieData);

                    if (parsedCookieData && parsedCookieData.length === 3) {
                        const [term, advertiser, pageNo] = parsedCookieData;

                        if (
                            (term && term.toLowerCase()) ===
                                (searchTerm && searchTerm.toLowerCase()) &&
                            advertiser === selectedAdvertiser &&
                            pageNo === 1
                        ) {
                            initPageNum = 2;
                        }
                    }
                }
            }

            // Set cookie with expiry - 60min * 24hour * 7days = 10080 minutes
            Utils.setCookie(
                'listingJobImpDedup',
                JSON.stringify([searchTerm, selectedAdvertiser, initPageNum]),
                60 * 24 * 7,
            );
        }

        return initPageNum;
    }

    handleOrganicAdClick = () => {
        this.setState({
            showXmlPopup: true,
        });
        const eventData = {
            cat: 'email',
        };
        eventManager.sendEvent(Constants.EVENT.HNP_IMP, eventData, Constants.EVENT_TYPE.DATA);
    };

    handleCloseSubmit = () => {
        this.setState({
            showXmlPopup: false,
        });
    };

    handleCloseIcon = () => {
        this.setState({
            showXmlPopup: false,
        });
        eventManager.sendEvent(
            Constants.EVENT.INTERSTITIAL_CLOSE_EMAIL,
            null,
            Constants.EVENT_TYPE.GRAITIFICATION,
        );
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.XML_VISITED, '1');
    };

    getDefaultJobBrand() {
        return Constants.DEFAULT_JOB_TYPE_ICONS[
            Math.floor(Math.random() * Constants.DEFAULT_JOB_TYPE_ICONS.length)
        ];
    }

    checkAndScrollTop() {
        // Scrolls to top if top bar is not completely in view port
        if (
            this.isListingsInBg &&
            !this.props.location.pathname.includes(
                RoutePrefixHandler.getRouteTemplateForPath(Constants.PAGE_ENDPOINTS.GRATIFICATION),
            )
        ) {
            const mjcTopBar = document.querySelector('.non-fixed-content-wrapper .MuiGrid-root');

            if (mjcTopBar) {
                const rect = mjcTopBar.getBoundingClientRect();

                if (rect && rect.y < 0) {
                    mjcTopBar.scrollIntoView();
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.isListingsInBg &&
            !this.props.location.pathname.includes(
                RoutePrefixHandler.getRouteTemplateForPath(Constants.PAGE_ENDPOINTS.GRATIFICATION),
            )
        ) {
            return;
        }

        if (prevProps.location.hash !== this.props.location.hash) {
            if (this.props.location.state) {
                this.setState({
                    currPageNumber: this.props.location.state.index,
                });
            } else {
                this.setState({
                    currPageNumber: 1,
                });
                this.props.history.push({
                    pathname: window.location.pathname,
                    search: this.props.history.location.search,
                    state: {
                        index: 1,
                    },
                    hash: `${1}`,
                });
            }
        }
    }

    async componentDidMount() {
        this.checkAndScrollTop();
        window.addEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED,
            this.sessionInitializedEventHandler,
        );
        document.addEventListener('keydown', this.escFunction, false);
        !AbTestHelper.disableBackButtonListings() &&
            window.addEventListener('popstate', this.onPopState);

        if (this.isbackButtonClicked && this.isNeuvooWidgetTestActive) {
            const neuvooWidgetAdUnitFeature = ConfigProvider.Features.getFeature(
                FeatureMap.NeuvooWidgetAdUnit,
            );
            const neuvooWidgetAdUnit =
                neuvooWidgetAdUnitFeature && neuvooWidgetAdUnitFeature.value
                    ? neuvooWidgetAdUnitFeature.value
                    : '';

            if (neuvooWidgetAdUnit) {
                try {
                    const targetingParams = TargetingManager.fetchTargetingParams();
                    const adsUnitData = await AdsDataSource.fetchAdsForAdUnit(
                        targetingParams,
                        neuvooWidgetAdUnit,
                    );
                    let adClickEventData;

                    if (adsUnitData && adsUnitData.ad) {
                        this.setState(
                            {
                                nuevooWidgetLoading: false,
                                showNeuvooWidget: true,
                            },
                            () => {
                                adClickEventData = {
                                    advid: adsUnitData.ad.advId,
                                    advnm: adsUnitData.ad.advertiserName,
                                    cmpid: adsUnitData.ad.campaignId,
                                    cmpnm: adsUnitData.ad.campaignName,
                                };
                                this.neuvooWidgetAdData = adClickEventData;
                                document.getElementById('jobsense1') &&
                                    document
                                        .getElementById('jobsense1')
                                        .addEventListener('click', this.jobSenseEventListener);
                                this.pushNeuvooWidgetScriptDynamically();
                            },
                        );
                    } else {
                        this.setState({
                            nuevooWidgetLoading: false,
                        });
                    }
                } catch (e) {
                    console.error('Error fetching Neuvoo Widget AdUnit');
                    this.setState({
                        nuevooWidgetLoading: false,
                    });
                }
            } else {
                this.setState({
                    nuevooWidgetLoading: false,
                });
            }
        }
    }

    jobSenseEventListener = e => {
        const parentElement =
            e.srcElement && e.srcElement.parentElement ? e.srcElement.parentElement : null;

        if (parentElement) {
            let title = parentElement.getElementsByClassName('t-alternate-titles-label');

            if (title && title.length > 0) {
                title = title[0].innerText;
            }

            let employerName = parentElement.getElementsByClassName(
                't-alternate-titles-salary-empname',
            );

            if (employerName && employerName.length > 0) {
                employerName = employerName[0].innerText;
            }

            eventManager.sendEvent(
                Constants.EVENT.AD_CLICK,
                {
                    ...this.neuvooWidgetAdData,
                    adt: title,
                    adst: employerName,
                    cat: '2click',
                    job_redirect_url: 'inaccessible',
                },
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
        }
    };

    pushNeuvooWidgetScriptDynamically = () => {
        const sid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SESSION_ID);
        const city = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_CITY);
        const kw =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);
        const country = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_COUNTRY);
        const NeuvooWidgetCPCFloorFeature = ConfigProvider.Features.getFeature(
            FeatureMap.NeuvooWidgetCPCFloor,
        );
        const cpcFloor =
            NeuvooWidgetCPCFloorFeature && NeuvooWidgetCPCFloorFeature.value
                ? NeuvooWidgetCPCFloorFeature.value
                : '';
        Utils.addScript(
            {
                type: 'text/javascript',
                src: `https://www.talent.com/jobsense/assets/jobsense.js.php?k=${kw}&l=${city}&country=${country}&salary=0&direct=1&contenttype=all&size=15&publisher=cd12f831&chnl1=Widget&chnl2=${sid}&chnl3=default&cpcfloor=${
                    cpcFloor || '0'
                }`,
            },
            '',
            () => {
                eventManager.sendEvent(
                    Constants.EVENT.BACK_BTN_IMPRESSION,
                    {
                        ...this.neuvooWidgetAdData,
                    },
                    Constants.EVENT_TYPE.GRAITIFICATION,
                );
            },
        );
    };

    escFunction(event) {
        if (event.keyCode === 27) {
            this.setState({ showDialog: false });
        }
    }

    async onPopState(e) {
        this.setState({ showDialog: false });
        this.setState(
            {
                backButtonClick: this.state.backButtonClick + 1,
            },
            async () => {
                let lrdrFromUrl = Utils.getValForKeyFromCurrentUrl('lrdr');

                if (AbTestHelper.enableBack2ListingsMidPathOffer()) {
                    if (
                        !Utils.isNull(lrdrFromUrl) &&
                        !Utils.isEmptyStr(lrdrFromUrl) &&
                        ((lrdrFromUrl.includes('landing') && this.state.backButtonClick === 2) ||
                            (lrdrFromUrl.includes('survey') && this.state.backButtonClick === 1))
                    ) {
                        const enableListingsToMidPathOffer = ConfigProvider.Features.getFeature(
                            FeatureMap.Back2ListingsMidPathRedirection,
                        );
                        IdStore.storeIdForKey(
                            Constants.ID_STORE_KEYS.AB_TEST_5,
                            enableListingsToMidPathOffer.value,
                        );
                        eventManager.sendEvent(
                            Constants.EVENT.PAGE_BACK,
                            { responsedata: 'back2ListingsMidPathOffer' },
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );

                        if (
                            !Utils.isNull(lrdrFromUrl) &&
                            !Utils.isEmptyStr(lrdrFromUrl) &&
                            (lrdrFromUrl.includes('landing') || lrdrFromUrl.includes('survey'))
                        ) {
                            this.props.history.push({
                                pathname: RoutePrefixHandler.generateActualPathname(
                                    Constants.PAGE_ENDPOINTS.ADS,
                                ),
                                search: window.location.search,
                            });
                        }
                    }
                } else if (
                    AbTestHelper.motiveInteractiveTest() &&
                    !Utils.isNull(lrdrFromUrl) &&
                    !Utils.isEmptyStr(lrdrFromUrl) &&
                    this.state.backButtonClick === 1
                ) {
                    const clId = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UUID);
                    let url;
                    const adUnitId = Utils.isTestEnv()
                        ? Constants.AD_UNIT_ID.MOTIVE_INTERACTIVE_TEST.STAG
                        : Constants.AD_UNIT_ID.MOTIVE_INTERACTIVE_TEST.PROD;

                    try {
                        const targetingParams = TargetingManager.fetchTargetingParams();
                        const adsUnitData = await AdsDataSource.fetchAdsForAdUnit(
                            targetingParams,
                            adUnitId,
                        );
                        let adClickEventData;

                        if (adsUnitData && adsUnitData.ad) {
                            adClickEventData = {
                                advid: adsUnitData.ad.advId,
                                advnm: adsUnitData.ad.advertiserName,
                                cmpid: adsUnitData.ad.campaignId,
                                cmpnm: adsUnitData.ad.campaignName,
                            };
                        }

                        if (
                            adsUnitData &&
                            adsUnitData.ad &&
                            AbTestHelper.motiveInteractiveTestAndroid()
                        ) {
                            url = `https://dailystash.com/6365/how-to-boost-your-income-with-these-money-making-apps-14/?affid=70030&s2=${clId}`;
                            await eventManager.sendEvent(
                                Constants.EVENT.MOTIVE_BACK_LINK,
                                null,
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                            await eventManager.sendEvent(
                                Constants.EVENT.AD_CLICK,
                                { ...adClickEventData, job_redirect_url: url, cat: '2click' },
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                            window.setTimeout(() => {
                                window.open(url, '_self');
                            }, 500);
                        } else if (
                            adsUnitData &&
                            adsUnitData.ad &&
                            AbTestHelper.motiveInteractiveTestIos()
                        ) {
                            url = `https://dailystash.com/6368/how-to-boost-your-income-with-these-money-making-apps-15/?affid=70030&s2=${clId}`;
                            await eventManager.sendEvent(
                                Constants.EVENT.MOTIVE_BACK_LINK,
                                null,
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                            await eventManager.sendEvent(
                                Constants.EVENT.AD_CLICK,
                                { ...adClickEventData, job_redirect_url: url, cat: '2click' },
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                            window.setTimeout(() => {
                                window.open(url, '_self');
                            }, 500);
                        } else {
                            eventManager.sendEvent(
                                Constants.EVENT.AD_FAILED,
                                null,
                                Constants.EVENT_TYPE.GRAITIFICATION,
                            );
                            this.props.history.push({
                                pathname: window.location.pathname,
                                search: this.props.history.location.search,
                                state: {
                                    index: this.initPageNum,
                                },
                                hash: window.location.hash,
                            });
                        }
                    } catch (err) {
                        console.error(
                            `Error while fetching AdUnit for Motive Interactive Test - `,
                            err,
                        );
                        eventManager.sendEvent(
                            Constants.EVENT.AD_FAILED,
                            null,
                            Constants.EVENT_TYPE.GRAITIFICATION,
                        );
                        this.props.history.push({
                            pathname: window.location.pathname,
                            search: this.props.history.location.search,
                            state: {
                                index: this.initPageNum,
                            },
                            hash: window.location.hash,
                        });
                    }
                } else if (
                    window.location.pathname.includes(Constants.PAGE_ENDPOINTS.GRATIFICATION) &&
                    !AbTestHelper.adWorksExternalNotificationPushEnabled()
                ) {
                    this.props.history.push({
                        pathname: window.location.pathname,
                        search: this.props.history.location.search,
                        state: {
                            index: this.initPageNum,
                        },
                        hash: window.location.hash,
                    });
                }
            },
        );
    }

    sessionInitializedEventHandler = () => {
        this.loadJobsData();
    };

    isQueryChangeRequiredForAdvertiser = adSourceAusDetails => {
        const list = FeatureValueHelper.getQueryParamChangeAdvertisers();

        if (adSourceAusDetails && adSourceAusDetails.length > 0 && list && list.length > 0) {
            for (
                let adSourceIndex = 0;
                adSourceIndex < adSourceAusDetails.length;
                adSourceIndex++
            ) {
                const advertiser =
                    adSourceAusDetails[adSourceIndex] &&
                    adSourceAusDetails[adSourceIndex].advertiserName
                        ? adSourceAusDetails[adSourceIndex].advertiserName.split(' ')
                        : '';

                for (let index = 0; index < advertiser.length; index++) {
                    if (list.includes(advertiser[index].toLowerCase())) {
                        return true;
                    }
                }
            }
        }

        return false;
    };

    getInitialSearchTerm() {
        // Resolved from URL PARAMS
        let { jobType } = Utils.getJobType();
        let medium = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_MEDIUM);
        let keyword =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PREFERENCE_JOB_TYPE) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);

        if (medium && medium.toLowerCase() === Constants.REDIRECT_MEDIUM.SMS) {
            return Utils.isNull(keyword) || Utils.isEmptyStr(keyword) ? 'Jobs near me' : keyword;
        } else if (!Utils.isEmptyStr(keyword)) return keyword;
        else if (!Utils.isEmptyStr(jobType)) return jobType;

        jobType = IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL);

        if (!Utils.isEmptyStr(keyword)) return keyword;

        if (!Utils.isEmptyStr(jobType)) return jobType;

        jobType = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

        if (!Utils.isEmptyStr(jobType)) return jobType;

        return 'Jobs near me';
    }

    getQueryTerm() {
        // Resolved from URL PARAMS
        let { jobType } = Utils.getJobType();
        let medium = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_MEDIUM);
        let keyword =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PREFERENCE_JOB_TYPE) ||
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);

        const adSourceAus = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU);
        const adSourceAusDetails = !Utils.isNull(adSourceAus) && Object.values(adSourceAus);
        const isQueryChangeRequired = this.isQueryChangeRequiredForAdvertiser(adSourceAusDetails);

        if (medium && medium.toLowerCase() === Constants.REDIRECT_MEDIUM.SMS) {
            return Utils.isNull(keyword) || Utils.isEmptyStr(keyword) ? 'Jobs near me' : keyword;
        } else {
            if (isQueryChangeRequired) {
                let jobTypeFromStorage =
                    IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL) ||
                    IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

                if (!Utils.isNull(jobTypeFromStorage)) {
                    if (jobTypeFromStorage === Constants.DEFAULT_KW && !Utils.isEmptyStr(keyword)) {
                        jobTypeFromStorage = '';
                    } else {
                        jobTypeFromStorage = jobTypeFromStorage.toLowerCase();
                    }
                }

                if (!Utils.isNull(keyword)) {
                    keyword = keyword.toLowerCase();
                }

                if (!Utils.isEmptyStr(keyword) && Utils.isEmptyStr(jobTypeFromStorage))
                    return keyword;
                else if (!Utils.isEmptyStr(jobTypeFromStorage) && Utils.isEmptyStr(keyword))
                    return jobTypeFromStorage;
                else if (
                    !Utils.isEmptyStr(keyword) &&
                    !Utils.isEmptyStr(jobTypeFromStorage) &&
                    jobTypeFromStorage === keyword
                )
                    return jobTypeFromStorage;
                else if (
                    !Utils.isEmptyStr(keyword) &&
                    !Utils.isEmptyStr(jobTypeFromStorage) &&
                    jobTypeFromStorage !== keyword
                )
                    return `${jobTypeFromStorage} ${keyword}`;
            } else {
                if (!Utils.isEmptyStr(keyword)) return keyword;
                else if (!Utils.isEmptyStr(jobType)) return jobType;
            }
        }
        jobType = IdStore.fetchIdForKey(Constants.USER.EMPLOYER_TYPE_VAL);

        if (!Utils.isEmptyStr(keyword)) return keyword;

        if (!Utils.isEmptyStr(jobType)) return jobType;

        jobType = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

        if (!Utils.isEmptyStr(jobType)) return jobType;

        return 'Jobs near me';
    }

    findParams() {
        let term = this.getInitialSearchTerm();
        let queryTerm = this.getQueryTerm();
        let location = Utils.getValForKeyFromCurrentUrl('z');
        let user = UserDataStore.fetchUserDetailsFromStore();

        const prefPageLocation = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PREFERENCE_ZIP_CODE);

        if (prefPageLocation) {
            location = prefPageLocation;
        }

        if (!location || Utils.isEmptyStr(location) || isNaN(location) || location.length !== 5) {
            location = user ? user['zip_code'] : '';
        }

        if (Utils.isEmptyStr(location)) {
            location = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE);
        }

        return [term, queryTerm, location];
    }

    loadJobsData() {
        const [term, queryTerm, location] = this.findParams();
        this.setState({
            searchTerm: term,
            isLoadingJobs: true,
            location: location,
            queryTerm: queryTerm,
        });
        let jobsQuery = JobSearchDataSource.createJobsQueryForTerm({
            term: queryTerm,
            pageNum: this.state.currentPageNum,
            pageSize: null,
            location,
        });

        // let sponsoredAdsQuery = this.showInHouseAds ? SponsoredAdsDataSource.createSponsoreJobsQueryForTerm(term) : null;
        setTimeout(this.updateJobsDataForQuery(jobsQuery), 0);
    }

    updateAdvertiser = () => {
        const currAdvertiser = this.state.selectedAdvertiser;

        if (AbManager.getAbt7() !== 'rotate_advtser') return;
        let adSource = '';
        let r;

        while (true) {
            r = Math.random();

            if (r < 0.2 && currAdvertiser !== Constants.ADVERTISERS.APPCAST) {
                adSource = Constants.ADVERTISERS.APPCAST;
                break;
            } else if (r < 0.5 && currAdvertiser !== Constants.ADVERTISERS.UPWARD) {
                adSource = Constants.ADVERTISERS.UPWARD;
                break;
            } else if (currAdvertiser !== Constants.ADVERTISERS.TALROO) {
                adSource = Constants.ADVERTISERS.TALROO;
                break;
            }
        }

        this.setState({
            selectedAdvertiser: adSource,
        });
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_ADS_SOURCE, adSource);
        let jobsQuery = JobSearchDataSource.createJobsQueryForTerm({
            term: this.state.queryTerm,
            pageNume: this.state.currentPageNum,
            pageSize: null,
            location: this.state.location,
        });
        this.updateJobsDataForQuery(jobsQuery);
    };

    shouldMakeEmptySearchQuery(totalJobs) {
        console.log('Should make empty search query ', totalJobs);

        if (totalJobs === 0) {
            this.updateJobsDataForEmptySearchQuery();
        }
    }

    dispatchAdImpEvents(jobs) {
        jobs.forEach((job, index) => {
            const { advid, advnm, title, description, company } = job;
            let shortDesc = '';

            if (description && !Utils.isNull(description)) {
                shortDesc = description.substring(0, 200);
            }
            eventManager.sendEvent(
                Constants.EVENT.ADS_IMP,
                { adt: title, adst: company, adst2: shortDesc, advid, advnm, spotno: `${index}` },
                Constants.EVENT_TYPE.GRAITIFICATION,
            );
        });
    }

    triggerGratificationEvents = (jobResults, errors, extraData = {}) => {
        const hrefStr = Utils.getObjVal(window, ['location', 'href']) || '';

        if (!hrefStr.includes('listings')) return false;

        const showPopup = IdStore.fetchIdForKey('showPopup') === 'true';

        console.log(`showing popup? ${showPopup}`);
        const lrdrFromUrl = Utils.getValForKeyFromCurrentUrl('lrdr');
        const isbackButtonClicked = !Utils.isNull(lrdrFromUrl) && !Utils.isEmptyStr(lrdrFromUrl);

        if (isbackButtonClicked && AbTestHelper.neuvooWidgetTest()) return;

        const resultAdSourceIds = jobResults.reduce((acc, curr) => {
            if (curr && curr.advid) {
                acc[curr.advid] = true;
            }

            return acc;
        }, {});

        const adSourceAus = memoryStore.getItem(memoryStoreKeys.JOB_AD_AU);
        const adSourceUUidMap = memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCES_UUID_MAP) || {};
        Object.keys(resultAdSourceIds).forEach(advId => {
            const au = adSourceAus[advId];
            au &&
                eventManager.sendEvent(
                    Constants.EVENT.GRATIFICATION_PAGE_IMP,
                    {
                        val: showPopup ? 1 : 0,
                        advid: advId,
                        advnm: au.advertiserName,
                        cmpnm: au.campaignName,
                        cmpid: au.campaignId,
                        uuid: adSourceUUidMap[advId],
                        ...extraData,
                    },
                    Constants.EVENT_TYPE.GRAITIFICATION,
                );
        });

        Object.keys(errors).forEach(errAdvId => {
            const au = adSourceAus[errAdvId];
            au &&
                eventManager.sendEvent(
                    Constants.EVENT.GRATIFICATION_PAGE_FAIL,
                    {
                        val: showPopup ? 1 : 0,
                        advid: errAdvId,
                        advnm: au.advertiserName,
                        cmpnm: au.campaignName,
                        cmpid: au.campaignId,
                        uuid: adSourceUUidMap[errAdvId],
                        error: errors[errAdvId],
                        ...extraData,
                    },
                    Constants.EVENT_TYPE.GRAITIFICATION,
                );
        });

        jobResults.forEach((job, index) => {
            const jobAdvId = job.advid;
            const { title, description, company, bid, cpa, cpc, max_filters } = job;
            let shortDesc = '';

            if (description && !Utils.isNull(description)) {
                shortDesc = description.substring(0, 200);
            }

            const au = adSourceAus[jobAdvId];

            if (au) {
                eventManager.sendEvent(
                    Constants.EVENT.SPOT_GRAT_IMP,
                    {
                        ervn: bid,
                        advid: jobAdvId,
                        advnm: au.advertiserName,
                        cmpnm: au.campaignName,
                        cmpid: au.campaignId,
                        uuid: adSourceUUidMap[jobAdvId],
                        adt: title,
                        adst: company,
                        adst2: shortDesc,
                        spotno: `${index}`,
                        cpa,
                        cpc,
                        max_filters,
                        ...extraData,
                    },
                    Constants.EVENT_TYPE.GRAITIFICATION,
                );
                OfferManager.fireTrackingPixelsList(au.trackingPixels);
            }
        });
    };

    triggerGratificationCallFailure = e => {
        console.log(
            'JOBS: ERR: could not fetch the data - ',
            e,
            memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS),
        );
        const errorObj = (memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS) || []).reduce(
            (acc, curr) => {
                acc[curr] = `Exception: Failed to fetch Jobs API`;

                return acc;
            },
            {},
        );

        this.triggerGratificationEvents([], errorObj);
    };

    updateJobsDataForEmptySearchQuery() {
        console.log('Making empty search query');

        this.setState({
            isLoadingJobs: true,
        });

        let jobsQuery = JobSearchDataSource.createJobsQueryForTerm({
            term: '',
        });
        delete jobsQuery.search;
        jobsQuery = {
            ...jobsQuery,
            advertisers: pickAdvertisersDataForJobResults(
                memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS),
            ),
        };
        console.log('Jobs arguments ', jobsQuery);

        JobSearchDataSource.fetchJobsDataForQueryMultiSource(jobsQuery)
            .then(async jobsData => {
                console.log('JOBS: got jobs data - ', jobsData);
                const jtJobsArr = await this.getJtJobs();

                let { totalCount: totalJobs, error } = jobsData;

                if (Utils.isNull(totalJobs)) {
                    totalJobs = 0;
                }
                /*
                 * let { jobType, isJobTypeAllowed } = Utils.getJobType();
                 * var userId = JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_ID))
                 * let key = isJobTypeAllowed ? jobType : userId.job_type
                 */
                let { jobs: jobsList } = jobsData;
                let resultTitle = '';

                if (Utils.isEmptyArr(jobsList)) {
                    jobsList = [];
                }

                if (jobsList.length > 0) {
                    resultTitle =
                        this.newFulfillmentPage || this.isFulfillmentDesignPhaseTwo
                            ? `${totalJobs} Results for ${
                                  this.state.searchTerm !== 'Jobs near me'
                                      ? `${Utils.getCapitalizeStr(this.state.searchTerm)} `
                                      : ''
                              } jobs ${this.state.location ? 'near' : ''} ${this.state.location}`
                            : `
                    <div class="result-title-wrapper">
                        <div class="result-title">
                            <span class="nrml-txt">Search Results for <span class="highlight">${
                                this.state.searchTerm !== 'Jobs near me'
                                    ? `${Utils.getCapitalizeStr(
                                          Utils.cleanKwDisplay(this.state.searchTerm),
                                      )} `
                                    : ''
                            }Jobs</span> Near <span class="highlight">${
                                  this.state.location
                              }</span></span>
                        </div>
                    </div>`;

                    if (
                        this.mergedJobFeed &&
                        !(
                            AbTestHelper.listingsInBgFlow() &&
                            !this.props.location.pathname.includes(
                                RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.GRATIFICATION,
                                ),
                            )
                        )
                    ) {
                        this.dispatchAdImpEvents(jobsList);
                    }
                }
                this.triggerGratificationEvents(jobsList, error || {});

                if (
                    AbTestHelper.listingsInBgFlow() &&
                    !this.props.location.pathname.includes(
                        RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.GRATIFICATION,
                        ),
                    ) &&
                    jobsList.length > 0
                ) {
                    IdStore.storeIdForKey('listings-jb', JSON.stringify(jobsList));
                } else {
                    IdStore.removeIdForKey('listings-jb');
                }

                this.setState({
                    isLoadingJobs: false,
                    jobsList: Utils.mergePriorArray(jtJobsArr, jobsList).filter(item => !!item),
                    resultTitle,
                    totalJobs,
                    shouldScroll: false,
                });
            })
            .catch(e => {
                this.triggerGratificationCallFailure(e);
            });
    }

    getJtJobs = async () => {
        await this.jtAdSourcePromise;
        let { jobType } = Utils.getJobType();

        jobType = !Utils.isEmptyStr(jobType)
            ? jobType
            : IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

        if (Utils.isEmptyStr(jobType)) {
            return [];
        } else {
            const [jtJobsArr = [], jobJobsError = {}] = await fetchJTJobs(
                jobType,
                this.state.location,
            );
            this.triggerGratificationEvents(jtJobsArr, jobJobsError, { cat: 'jt' });

            return jtJobsArr;
        }
    };

    updateJobsDataForQuery(jobsQuery) {
        this.setState({
            isLoadingJobs: true,
        });
        const query = {
            ...jobsQuery,
            advertisers: pickAdvertisersDataForJobResults(
                memoryStore.getItem(memoryStoreKeys.JOB_AD_SOURCE_IDS),
            ),
        };
        JobSearchDataSource.fetchJobsDataForQueryMultiSource(query)
            .then(async jobsData => {
                console.log('JOBS: got jobs data - ', jobsData);

                const jtJobsArr = await this.getJtJobs();

                let { totalCount: totalJobs, error } = jobsData;

                if (Utils.isNull(totalJobs)) {
                    totalJobs = 0;
                }
                let { jobs } = jobsData;
                let jobsList = jobs.filter(
                    job =>
                        job && job.company && !job.company.toLowerCase().includes('myjobscorner'),
                );
                let resultTitle = '';

                if (Utils.isEmptyArr(jobsList)) {
                    jobsList = [];
                }

                if (jobsList.length > 0) {
                    resultTitle =
                        this.newFulfillmentPage || this.isFulfillmentDesignPhaseTwo
                            ? `${totalJobs} Results for ${
                                  this.state.searchTerm !== 'Jobs near me'
                                      ? `${Utils.getCapitalizeStr(
                                            Utils.cleanKwDisplay(this.state.searchTerm),
                                        )} `
                                      : ''
                              } jobs ${this.state.location ? 'near' : ''} ${this.state.location}`
                            : `<div class="result-title-wrapper">
                    <div class="result-title">
                        <span class="nrml-txt">Search Results for <span class="highlight">${
                            this.state.searchTerm !== 'Jobs near me'
                                ? `${Utils.getCapitalizeStr(
                                      Utils.cleanKwDisplay(this.state.searchTerm),
                                  )} `
                                : ''
                        }Jobs</span> Near <span class="highlight">${
                                  this.state.location
                              }</span></span>
                        </div>
                    </div>`;

                    if (
                        this.mergedJobFeed &&
                        !(
                            AbTestHelper.listingsInBgFlow() &&
                            !this.props.location.pathname.includes(
                                RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.GRATIFICATION,
                                ),
                            )
                        )
                    ) {
                        this.dispatchAdImpEvents(jobsList);
                    }
                }

                this.triggerGratificationEvents(jobsList, error || {});

                if (
                    AbTestHelper.listingsInBgFlow() &&
                    !this.props.location.pathname.includes(
                        RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.GRATIFICATION,
                        ),
                    ) &&
                    jobsList.length > 0
                ) {
                    IdStore.storeIdForKey('listings-jb', JSON.stringify(jobsList));
                }

                this.setState(
                    {
                        isLoadingJobs: false,
                        jobsList: Utils.mergePriorArray(jtJobsArr, jobsList).filter(item => !!item),
                        resultTitle,
                        totalJobs,
                        shouldScroll: false,
                    },
                    () => {
                        this.shouldMakeEmptySearchQuery(totalJobs);
                    },
                );
            })
            .catch(e => {
                this.triggerGratificationCallFailure(e);
            });
    }

    shouldComponentUpdate() {
        if (
            AbTestHelper.listingsInBgFlow() &&
            !this.props.location.pathname.includes(
                RoutePrefixHandler.getRouteTemplateForPath(Constants.PAGE_ENDPOINTS.GRATIFICATION),
            ) &&
            this.state.jobsList &&
            this.state.jobsList.length > 0
        ) {
            return false;
        }

        return true;
    }

    componentWillReceiveProps(nextProps) {
        let jobsList = [];

        try {
            jobsList = JSON.parse(IdStore.fetchIdForKey('listings-jb'));
        } catch (e) {
            console.log("Couldn't parse job listings", e);
        }

        if (
            this.state.jobsList &&
            this.state.jobsList.length <= 0 &&
            jobsList &&
            jobsList.length > 0
        ) {
            this.setState({
                isLoadingJobs: false,
                jobsList: jobsList,
            });

            return;
        }

        if (
            AbTestHelper.listingsInBgFlow() &&
            !this.props.location.pathname.includes(
                RoutePrefixHandler.getRouteTemplateForPath(Constants.PAGE_ENDPOINTS.GRATIFICATION),
            ) &&
            this.state.jobsList &&
            this.state.jobsList.length > 0
        ) {
            return;
        }

        if (
            false === Utils.isNull(nextProps.history) &&
            false === Utils.isNull(nextProps.history.location) &&
            false === Utils.isNull(nextProps.history.location.state) &&
            false === Utils.isNull(nextProps.history.location.state.index) &&
            this.state.currentPageNum !== nextProps.history.location.state.index
        ) {
            console.log('PAGE: Did receive props - ', nextProps.history.location.state.index);
            this.handlePageChange(nextProps.history.location.state.index);
        }
    }

    handlePageChange = pageNumber => {
        console.log(`active page is ${pageNumber}`);
        this.updateJobsDataForQuery(
            JobSearchDataSource.createJobsQueryForTerm({
                term: this.state.queryTerm,
                pageNum: pageNumber,
                pageSize: null,
                location: this.state.location,
            }),
        );
        this.setState(
            {
                shouldScroll: true,
                activePage: pageNumber,
                currentPageNum: pageNumber,
            },
            () => {
                if (
                    this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.index &&
                    this.props.history.location.state.index !== pageNumber
                ) {
                    this.props.history.push({
                        pathname: window.location.pathname,
                        search: this.props.history.location.search,
                        state: {
                            index: pageNumber,
                        },
                        hash: `${pageNumber}`,
                    });
                }
            },
        );
    };

    handleClose = e => {
        this.setState({
            showInterstitialAds: false,
        });
    };

    handlePopClose = e => {
        this.setState({ showDialog: false });
    };

    searchHandler = term => {
        if (Utils.isEmptyObj(term)) {
            console.log('JOBS: Not searching for an empty string ');

            return;
        }
        const initPageNum = this.getInitPageNum(
            this.isEmailOrSMSMedium,
            this.isJobImpDedup,
            term.searchTerm,
            this.state.selectedAdvertiser,
        );

        this.setState({
            searchTerm: term.searchTerm,
            location: term.location,
            currentPageNum: initPageNum,
            queryTerm: term.searchTerm,
        });

        this.props.history.push({
            pathname: window.location.pathname,
            search: this.props.history.location.search,
            state: {
                index: initPageNum,
            },
            hash: `${initPageNum}`,
        });

        // let sponsoredAdsQuery = this.showInHouseAds ? SponsoredAdsDataSource.createSponsoreJobsQueryForTerm(term.searchTerm) : null;
        this.updateJobsDataForQuery(
            JobSearchDataSource.createJobsQueryForTerm({
                term: term.searchTerm,
                pageNum: 1,
                pageSize: null,
                location: term.location,
            }),
        );
    };

    handleFbGoogleInterstitialClick = applyUrl => {
        this.setState({
            showFbGoogleInterstitial: true,
        });
        this.showFbGoogleInterstitialUrl = applyUrl;
    };

    handleFbGoogleInterstitialCrossClick = (continueClick = false) => {
        this.setState({
            showFbGoogleInterstitial: false,
        });
        window.open(this.showFbGoogleInterstitialUrl, '_blank');
        this.updateAdvertiser();

        if (continueClick) {
            this.showFbGoogleInterstitial = false;
        }
    };

    componentWillUnmount() {
        window.removeEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED,
            this.sessionInitializedEventHandler,
        );

        window.removeEventListener('popstate', this.onPopState);
        const jobSense = document.getElementById('jobsense1');

        if (jobSense) {
            jobSense.removeEventListener('click', this.jobSenseEventListener);
        }
    }

    getBackgroundImages = () => {
        let listingsBgXmlImages = {
            mobile: '',
            tablet: '',
            web: '',
        };
        const ListingsBgXMLTestMobileImage = ConfigProvider.Features.getFeature(
            FeatureMap.ListingsBgXmlTestMobileImage,
        );
        const listingsBgXMLTestMobileImage =
            ListingsBgXMLTestMobileImage && ListingsBgXMLTestMobileImage.value
                ? decodeURIComponent(ListingsBgXMLTestMobileImage.value)
                : '';

        const ListingsBgXMLTestTabletImage = ConfigProvider.Features.getFeature(
            FeatureMap.ListingsBgXmlTestTabletImage,
        );
        const listingsBgXMLTestTabletImage =
            ListingsBgXMLTestTabletImage && ListingsBgXMLTestTabletImage.value
                ? decodeURIComponent(ListingsBgXMLTestTabletImage.value)
                : '';

        const ListingsBgXMLTestWebImage = ConfigProvider.Features.getFeature(
            FeatureMap.ListingsBgXmlTestWebImage,
        );
        const listingsBgXMLTestWebImage =
            ListingsBgXMLTestWebImage && ListingsBgXMLTestWebImage.value
                ? decodeURIComponent(ListingsBgXMLTestWebImage.value)
                : '';

        listingsBgXmlImages.mobile = listingsBgXMLTestMobileImage;
        listingsBgXmlImages.tablet = listingsBgXMLTestTabletImage;
        listingsBgXmlImages.web = listingsBgXMLTestWebImage;

        return listingsBgXmlImages;
    };

    setTint = () => {
        let imageContainer = document.querySelector('.listings-bg-dialog .MuiDialog-scrollPaper');

        if (imageContainer.classList) {
            imageContainer.classList.add('image-in-bg');
        }
    };

    render() {
        const { classes } = this.props;

        const sid = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.SURVEY_ID);
        const source = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);
        const MJCHomeSitePath = source === 'mjc' && sid === '57';
        const phoneSubbed = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PHONE_SUB) === 'true';
        const emailSubbed = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.EMAIL_SUB) === 'true';
        const { jobType } = Utils.getJobType();
        const showAmazonJobsImage =
            AbTestHelper.showListingsBgXMLTestV2() &&
            [
                Constants.PAGE_ENDPOINTS.LANDING,
                Constants.PAGE_ENDPOINTS.SURVEY,
                Constants.PAGE_ENDPOINTS.ADS,
            ].includes(this.props.match.path);
        const showStaticBgImg =
            AbTestHelper.staticImgListingsBgTest() &&
            [Constants.PAGE_ENDPOINTS.LANDING].includes(this.props.match.path);

        const { mobile = '', tablet = '', web = '' } =
            (showAmazonJobsImage && this.getBackgroundImages()) ||
            (showStaticBgImg && AbTestHelper.getStaticListingsBgImgs());

        if (
            this.isbackButtonClicked &&
            this.isNeuvooWidgetTestActive &&
            (this.state.nuevooWidgetLoading || this.state.showNeuvooWidget)
        ) {
            if (this.state.nuevooWidgetLoading) {
                return (
                    <div
                        style={{
                            display: 'flex',
                            height: 'calc(100vh - 80px)',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <CircularProgress />
                    </div>
                );
            } else {
                return <div id="jobsense1" style={{ width: '100%' }}></div>;
            }
        } else {
            return showAmazonJobsImage || showStaticBgImg ? (
                <>
                    <div className={classes.bgImageContainer}>
                        <LazyLoadComponent afterLoad={showStaticBgImg ? () => {} : this.setTint}>
                            <picture className={classes.picture}>
                                <source media="(min-width:1280px)" srcSet={web} />
                                <source media="(min-width:600px)" srcSet={tablet} />
                                <img
                                    alt={`${Utils.getCapitalizeStr(jobType)} Jobs`}
                                    className={classes.bgImg}
                                    src={mobile}
                                />
                            </picture>
                        </LazyLoadComponent>
                    </div>
                </>
            ) : (
                <React.Fragment>
                    {this.props.showAds ? (
                        <InterstitialAds
                            handleClose={this.handleClose}
                            jobs={this.state.jobsList}
                            open={
                                this.state.jobsList &&
                                this.state.jobsList.length > 0 &&
                                this.props.showAds &&
                                this.state.showInterstitialAds
                            }
                        />
                    ) : null}
                    <JobsSearchView
                        activePage={this.state.activePage}
                        adsData={this.state.adsData}
                        currPageNumber={this.state.pageNumber}
                        defaultJobBrand={this.state.defaultJobBrand}
                        handleCloseIcon={this.handleCloseIcon}
                        handleCloseSubmit={this.handleCloseSubmit}
                        handleFbGoogleInterstitialClick={this.handleFbGoogleInterstitialClick}
                        isEmailOrSMSMedium={this.isEmailOrSMSMedium}
                        isFulfillmentDesignPhaseTwo={this.isFulfillmentDesignPhaseTwo}
                        isJobDedup={this.isJobClickDedup}
                        isLoadingJobs={this.state.isLoadingJobs}
                        jobsList={this.state.jobsList}
                        location={this.state.location}
                        mergedJobFeed={this.mergedJobFeed}
                        newFulfillmentPage={this.newFulfillmentPage}
                        onSubmitClick={this.handleOrganicAdClick}
                        open={this.state.showXmlPopup}
                        queryTerm={this.state.queryTerm}
                        resultTitle={this.state.resultTitle}
                        scrollPageOnPagination={this.state.shouldScroll}
                        searchCb={this.searchHandler.bind(this)}
                        searchParamChange={this.handleSearchParamChange}
                        searchTerm={this.state.searchTerm}
                        selectedAdvertiser={this.state.selectedAdvertiser}
                        shouldSendEvents={this.state.shouldSendEvents}
                        // showInHouseAds={this.showInHouseAds}
                        showFbGoogleInterstitial={this.showFbGoogleInterstitial}
                        updateAdvertiser={this.updateAdvertiser}
                    />
                    <div className="pagination-wrapper">
                        <Pagination
                            currentPage={this.state.currentPageNum}
                            limit={this.state.pageSize}
                            pageCount={5}
                            total={Number(this.state.totalJobs)}>
                            {({
                                pages,
                                currentPage,
                                hasNextPage,
                                hasPreviousPage,
                                previousPage,
                                nextPage,
                                totalPages,
                                getPageItemProps,
                            }) => (
                                <div className="pagination-inner-wrapper">
                                    {hasPreviousPage && (
                                        <button
                                            {...getPageItemProps({
                                                pageValue: previousPage,
                                                onPageChange: this.handlePageChange,
                                            })}>
                                            {'<'}
                                        </button>
                                    )}

                                    {pages.map(page => {
                                        let activePage = null;

                                        if (currentPage === page) {
                                            activePage = {
                                                backgroundColor: '#4a90e2',
                                                color: '#fff',
                                            };
                                        }

                                        return (
                                            <button
                                                {...getPageItemProps({
                                                    pageValue: page,
                                                    key: page,
                                                    style: activePage,
                                                    onPageChange: this.handlePageChange,
                                                })}>
                                                {page}
                                            </button>
                                        );
                                    })}

                                    {hasNextPage && (
                                        <button
                                            {...getPageItemProps({
                                                pageValue: nextPage,
                                                onPageChange: this.handlePageChange,
                                            })}>
                                            {'>'}
                                        </button>
                                    )}
                                </div>
                            )}
                        </Pagination>
                    </div>
                    {AbTestHelper.emailAlertsCTA() && <EmailPopupToggleContainer />}
                    {MJCHomeSitePath && (!emailSubbed || !phoneSubbed) && (
                        <Dialog className="mjc-pop-main-wrapper" open={this.state.showDialog}>
                            <DialogContent>
                                <UserInfoInterstitial
                                    emailSubbed={emailSubbed}
                                    handlePopClose={this.handlePopClose}
                                    phoneSubbed={phoneSubbed}
                                />
                            </DialogContent>
                        </Dialog>
                    )}
                    {this.showFbGoogleInterstitial && this.state.showFbGoogleInterstitial && (
                        <Dialog open={this.state.showFbGoogleInterstitial}>
                            <DialogContent style={{ padding: 0 }}>
                                <FbGoogleFbGoogleEmailRegistration
                                    handleFbGoogleInterstitialCrossClick={
                                        this.handleFbGoogleInterstitialCrossClick
                                    }
                                />
                            </DialogContent>
                        </Dialog>
                    )}
                </React.Fragment>
            );
        }
    }
}
const styles = () => ({
    bgImageContainer: {
        ...(AbTestHelper.staticImgListingsBgTest() && {
            height: '100%',
        }),
    },
    picture: {
        ...(AbTestHelper.staticImgListingsBgTest() && {
            width: '100%',
            height: '100%',
            display: 'flex',
        }),
    },
    bgImg: {
        ...(AbTestHelper.staticImgListingsBgTest()
            ? { width: '100%', height: 'auto', objectFit: 'cover' }
            : { width: '100%', height: 'auto' }),
    },
});
export default withStyles(styles)(withRouter(JobsSearchContainer));
