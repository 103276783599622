export const UPWARD_SEMI_DYNAMIC = (window.CNXBucket &&
    window.CNXBucket.UPWARD_SEMI_DYNAMIC &&
    (Object.keys(window.CNXBucket.UPWARD_SEMI_DYNAMIC).length > 0
        ? window.CNXBucket.UPWARD_SEMI_DYNAMIC
        : null)) || {
    transportation: ['dhl', 'fedex', 'usps', 'ups', 'walmart'],
    retail: ['walmart', 'target', 'costco', 'best buy', 'ikea'],
    'customer service': ['costco', 'coca cola', 'target', 'fedex'],
    healthcare: ['maxim healthcare', 'cvs health', 'united healthcare', 'medtronic'],
    'part time': ['amazon', 'walgreens', 'pepsi', 'goodwill'],
    hourly: ['fedex', 'pepsi', 'usps', 'kohler'],
    warehouse: ['pepsi', 'amazon', 'walmart'],
};

export const MULTI_BRAND_KW = (window.CNXBucket &&
    window.CNXBucket.MULTI_BRAND_KW &&
    (Object.keys(window.CNXBucket.MULTI_BRAND_KW).length > 0
        ? window.CNXBucket.MULTI_BRAND_KW
        : null)) || {
    airport: ['american airlines', 'delta airlines', 'jetblue airways'],
    warehouse: ['amazon', 'fedex', 'pepsi'],
    'mail clerk': ['usps', 'ups', 'fedex'],
    'postal worker': ['usps', 'ups', 'fedex'],
    'customer service': ['cvs health', 'amazon', 'kelly services'],
    'grocery store': {
        USA: ['traderjoes', 'aldi', 'sams club'],
        TX: ['heb_logo', 'traderjoes', 'aldi'],
        FL: ['publix', 'traderjoes', 'SGrocersLogo'],
        CA: ['walmart', 'albertsons', 'kroger'],
        NY: ['WegmansLogo', 'traderjoes', 'shoprite'],
    },
    sales: ['amazon', 'pepsi', 'best buy'],
    dockworker: ['xpo logistics', 'ups', 'old dominion freight line'],
    'dock worker': ['xpo logistics', 'ups', 'old dominion freight line'],
    'full time jobs': ['amazon', 'walmart', 'fedex', 'costco'],
    'american airlines': ['delta airlines', 'american airlines', 'jetblue airways'],
};

export const NEW_KW_ICON = (window.CNXBucket &&
    window.CNXBucket.NEW_KW_ICON &&
    (Object.keys(window.CNXBucket.NEW_KW_ICON).length > 0
        ? window.CNXBucket.NEW_KW_ICON
        : null)) || {
    'customer service jobs': 'customer_service_jobs',
    'hospital front desk': 'hospital_front_desk',
    'data entry from home': 'entry_from_home',
    'early morning postal worker': 'early_morning_postal_worker',
    'dog walker': 'dog_walker',
    tester: 'product_tester',
    'personal shopper': 'personal_shopper',
    airport: 'airport_worker',
    'assistant investigator': 'assistant_investigator',
    'police records specialist': 'police',
    'amazon delivery': '',
    'grocery store': 'grocery_store',
    warehouse: 'warehouse',
    'at home customer service': 'customer_service_jobs',
    'at home assistant': 'customer_service_jobs',
    dockworker: 'dockworker',
    'park ranger': 'park_ranger',
    'full time postal worker': 'early_morning_postal_worker',
    assembler: 'assembler',
    'airport worker': 'airport_worker',
    'product tester': 'product_tester',
    'customer service': 'customer_service_jobs',
    packer: 'packer',

    tsa: 'tsa',
    'under the table': 'under_the_table',
    'design & construction': 'design_construction',
    'design&construction': 'design_construction',
    'maintenance & operations': 'maintenance_operation',
    'maintenance&operations': 'maintenance_operation',
    'forestry & environment': 'forestry_environment',
    'forestry&environment': 'forestry_environment',
    security: 'security',
    'recreation & programming': 'recreation_programming',
    'recreation&programming': 'recreation_programming',
    administration: 'administration',
};
