import React, { useState } from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import RouterPrefixHandler from 'common/routePrefixHandler';
import EmailRegistrationForm from './regCTAEmailSubForm';

const RegCTAButtonContainer = () => {
    const [showInt, setShowInt] = useState(false);
    const classes = useStyles();

    return (
        <>
            <img
                alt={'icon'}
                className={`${classes.logo}`}
                onClick={() => setShowInt(true)}
                src={RouterPrefixHandler.getImagePath('/images/email-sub.svg')}
            />
            {showInt && (
                <Dialog
                    classes={{
                        paper: classes.popOverDialog,
                        scrollPaper: classes.customScrollPaper,
                    }}
                    open={true}>
                    <EmailRegistrationForm handleClose={() => setShowInt(false)} open={showInt} />
                </Dialog>
            )}
        </>
    );
};

const useStyles = makeStyles(theme => ({
    logo: {
        width: 32,
        height: 32,
        position: 'absolute',
        right: 0,
        borderRadius: '50%',
        border: '2px red rgba(41, 73, 100, 0.05)',
        animation: '$pulse 2s infinite',
        transform: 'translateX(-50%)',
        cursor: 'pointer',
    },

    '@keyframes pulse': {
        '0%': { boxShadow: '0 0 0 0 rgba(31,56,81, 0.4)' },
        '70%': { boxShadow: '0 0 0 10px rgba(172,183,194, 0)' },
        '100%': { boxShadow: '0 0 0 0 rgba(255,0,0, 0)' },
    },
    popOverDialog: {
        borderRadius: 10,
        top: 36,
        margin: 16,
    },
    customScrollPaper: {
        alignItems: 'flex-start',
    },
}));

export default RegCTAButtonContainer;
