import Analytics from 'analytics';
import Utils from 'common/utils';
import { EVENT_CONSTANTS } from './common';
import Constants from 'common/constants';

class AnalyticsManager {
    sendEventToAnalytics(event) {
        // var analytics = Analytics.getAnalyticsInstance()
        if (Utils.isEmptyObj(event)) {
            return;
        }

        // Find the category and the action for the event
        let eventName = event[EVENT_CONSTANTS.EVENT_ID_KEY];

        if (Utils.isEmptyStr(eventName)) {
            console.log('ERR: Could not fetch the event-name');

            return;
        }

        let eventCategory = this.getCategoryForEventName(eventName);
        window.gtag('event', eventName, {
            event_category: eventCategory,
        });
        /*
         * analytics.event({
         *     category: eventCategory,
         *     action: eventName,
         * })
         */
    }

    pageView(title, path) {
        window.gtag('config', Analytics.ANALYTICS_ID, {
            page_title: title,
            page_path: path,
        });
    }

    setDataVariable(key, value) {
        window.gtag({ key: value });
    }

    getCategoryForEventName(eventName) {
        let categoryVal = EVENT_CONSTANTS.EVENT_TO_CATEGORY_MAP[eventName];

        if (Utils.isEmptyStr(categoryVal)) {
            categoryVal = Constants.ANALYTICS.CATEGORY.MISC;
        }

        return categoryVal;
    }
}

let analyticsManager = new AnalyticsManager();
export default analyticsManager;
