import Utils from 'common/utils';
import Constants from 'common/constants';
import { IdStore } from 'dataStore';
import { AbTestHelper, FeatureValueHelper } from 'helpers/index';
import UserDataStore from 'components/landing/userDataStore';
import PhoneValidator from 'common/PhoneValidator';
import eventManager from 'eventManager/eventManager';
import UserDetails from 'models/userDetails';
import { isAPIRepeatUser } from 'helpers/session/sessionHelpers';

const _getUserFromCache = () => {
    const userDetailsCache =
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS) &&
        JSON.parse(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_DETAILS));

    return new UserDetails(userDetailsCache);
};

export const checkAndRemoveUTMTerm = () => {
    const kw = (IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM) || '').toLowerCase();
    const isKwBrand = Utils.isJobTypeAllowed(kw);

    if (AbTestHelper.upwardJtAsUtmTerm() || AbTestHelper.kwJtConfigIntegration()) return false;

    if ((AbTestHelper.listingsInBgPrelanderKw() || AbTestHelper.rgKeywordTest()) && !isKwBrand)
        return false;

    return true;
};

export const updateEmployerSelection = selectedEmployer => {
    if (!Utils.isEmptyStr(selectedEmployer)) {
        IdStore.storeIdForKey(Constants.USER.EMPLOYER_TYPE_VAL, selectedEmployer);
    } else {
        IdStore.removeIdForKey(Constants.USER.EMPLOYER_TYPE_VAL);
    }
};

const handleKwAsBrand = jtOrKeyWordLower => {
    const storeKw = IdStore.fetchIdForKey(Constants.UTM_TERM);
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL, jtOrKeyWordLower);

    if (storeKw && storeKw.toLowerCase && Utils.isJobTypeAllowed(storeKw.toLowerCase())) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JT_KW_MISMATCH, '1');
        checkAndRemoveUTMTerm();
    }
};

export const storeJobsInfoForForm = (jtOrKeyWord, selectedEmployer) => {
    const lkw = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_KEYWORD);
    const jobType = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_TYPE_VAL);

    const jtOrKeyWordLower = jtOrKeyWord && jtOrKeyWord.toLowerCase && jtOrKeyWord.toLowerCase();
    const lkwLower = lkw && lkw.toLowerCase();
    const jobTypeLower = jobType && jobType.toLowerCase();
    const utmTermChanged =
        lkwLower !== jtOrKeyWordLower &&
        jtOrKeyWordLower !== Constants.DEFAULT_KW.toLowerCase() &&
        jtOrKeyWordLower !== jobTypeLower;

    if (Utils.isJobTypeAllowed(jtOrKeyWordLower)) {
        handleKwAsBrand(jtOrKeyWordLower);
    } else if (!Utils.isEmptyStr(jtOrKeyWordLower) && utmTermChanged) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UTM_TERM, jtOrKeyWordLower);
    }

    updateEmployerSelection(selectedEmployer);
};

export const walkFormElements = formRef => {
    try {
        const formWalker = Utils.getObjVal(formRef, ['current', 'walk']) || Utils.noop();
        const formChilds = Utils.getObjVal(formRef, ['current', 'childs']) || [];
        formWalker(formChilds);
    } catch (e) {
        console.error('Form: Error walking form elements');
    }
};

export const skipReg2PageAPIRepeatUser = () => {
    return (
        (AbTestHelper.regThreeFlowV1() && isAPIRepeatUser(true)) ||
        (AbTestHelper.listingBgRegThreeTest() && isAPIRepeatUser(true))
    );
};

export const skipReg2Page = () => {
    if (AbTestHelper.skipReg2Page()) {
        return true;
    } else if (isAPIRepeatUser()) {
        return skipReg2PageAPIRepeatUser();
    }

    return false;
};

export const getUserPhoneNumber = () => {
    const oldUser = UserDataStore.fetchUserDetailsFromStore();
    const urlPhone = Utils.filterInvalidUrlInput(
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE) || '',
    );
    const userPhone = Utils.getObjVal(oldUser, ['phone_number']) || '';
    const maskedPhone = Utils.getObjVal(oldUser, ['meta', 'maskedPhoneNumber']);

    if (!Utils.isEmptyStr(urlPhone)) {
        return PhoneValidator.formatPhoneNumber(urlPhone);
    }

    if (AbTestHelper.repeatUserAPIFlowV2() || !oldUser.phone_verification_status) {
        return '';
    }

    if (isAPIRepeatUser() && !Utils.isEmptyStr(maskedPhone)) {
        return PhoneValidator.formatPhoneNumber(maskedPhone, true);
    }

    return PhoneValidator.formatPhoneNumber(userPhone);
};

export const checkListingsReg2Skipped = formIndex => {
    if (formIndex === 2)
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.LISTING_BG_REG2_SKIPPED, 'skipped');
};

export const isLeadBlackListMail = (email = '') => {
    const leadBlackListEmailSuffixes = FeatureValueHelper.getLeadBlackListMailSuffixes();

    return !!leadBlackListEmailSuffixes.find(suffix => email.endsWith(suffix));
};

export const filterInvalidEvsPvs = evs => {
    const isEmailBlackListed = IdStore.storeIdForKey(Constants.ID_STORE_KEYS.IS_EMAIL_BLACK_LISTED);

    if (isEmailBlackListed) return undefined;

    return evs;
};

export const checkAndFireEmailLead = (email, eventData, type) => {
    if (isLeadBlackListMail(email)) return;
    eventManager.sendEvent(Constants.EVENT.HNP_LEAD, eventData, type);
};

export const fireHnpEmailOnlyHnpLead = (data = {}, type = Constants.EVENT_TYPE.DATA) => {
    const userDetails = _getUserFromCache();
    const eventData = {
        cat: 'email',
        email: userDetails.emailId,
        ...data,
    };
    checkAndFireEmailLead(userDetails.emailId, eventData, type);
};

export const updateFormDataWithJbDetails = (details, formData) => {
    if (details) {
        if (details.trafficCategory) {
            formData['trafficCategory'] = details.trafficCategory;
        }

        if (details.val) {
            formData['val'] = details.val;
        }

        if (details.usePubVal) {
            formData['usePubVal'] = details.usePubVal;
        }
    }

    return formData;
};
