import Utils from './utils';
import { Container } from 'typedi';
import DateUtils from './DateUtils';

export default class UserDataValidator {
    /**
     * @param {string} email
     * @returns {string|null}
     */
    static isValidEmail(email) {
        let emailValue = email ? email.trim() : '';

        if (Utils.isEmptyStr(emailValue)) {
            return 'Email cannot be empty';
        }

        if (
            !(
                /^[^\s@]+@[^\s@]+\.[^\s@]{2,5}$/.test(emailValue) &&
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(emailValue)
            )
        ) {
            return 'Invalid email';
        }

        return null;
    }

    /**
     * @param {string} firstName
     * @returns {string|null}
     */
    static isValidFirstName(firstName) {
        let fName = firstName ? firstName.trim() : '';

        if (!Utils.minLength(fName, 1)) {
            return 'First Name has to be greater than 1 character';
        }

        return null;
    }

    static isValidJobType(val) {
        let jt = val ? val.trim() : '';

        if (false === Utils.minLength(jt, 1)) {
            return 'Invalid Job type';
        }

        return null;
    }

    /**
     * @param {string} lastName
     * @returns {string|null}
     */
    static isValidLastName(lastName) {
        let lName = lastName ? lastName.trim() : '';

        if (!Utils.minLength(lName, 1)) {
            return 'Last Name has to be greater than 1 character';
        }

        return null;
    }

    /**
     *
     * @param {Object} address
     * @returns {string|null}
     */

    static isValidAddressLine(address) {
        let add = address ? address.trim() : '';

        if (Utils.isEmptyStr(add)) {
            return 'Address cannot be empty';
        }

        return null;

        // let add = address;

        // if (
        //     false === Utils.minLength(add, 1) ||
        //     Utils.isNull(add.match(/([0-9])/)) ||
        //     Utils.isNull(add.match(/(\s)/)) ||
        //     !Utils.isNull(add.match(/^\s.*/)) ||
        //     !Utils.isNull(add.match(/.*\s$/))
        // ) {
        //     return 'Street name has to be greater than 1 character and should contain digit and space and not start or end with space';
        // }

        // return null;
    }

    /**
     * @param {string} gender
     * @returns {string|null}
     */
    static isValidGender(gender) {
        if (Utils.isEmptyStr(gender)) {
            return 'Gender cannot be empty!';
        } else if (-1 === ['1', '2', '3'].indexOf(gender)) {
            return 'Invalid Gender!';
        }

        return null;
    }

    /**
     * @param {Object} location
     * @returns {string|null}
     */
    static isValidLocation(location) {
        if (Utils.isEmptyObj(location)) {
            return 'Location cannot be empty!';
        }

        return null;
    }

    /**
     * @param {string, string} dob, format
     * @returns {string|null}
     */
    static isValidDob(dob, format) {
        if (Utils.isEmptyStr(dob)) {
            return 'Dob cannot be empty!';
        }

        if (!DateUtils.isValidDateStringForFormat(dob, format)) {
            return 'Invalid DOB';
        }

        return null;
    }

    /**
     * @param {string} zipCode
     * @returns {string|null}
     */
    static isValidZipCode(zipCode) {
        if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode)) {
            return 'Invalid zip-code. Zip-code needs to be 5 digits';
        }
    }

    /**
     * @param {string} text
     * @returns {string|null}
     */
    static hasProfanity(text) {
        const textValue = text ? text.trim() : '';

        if (typeof text === 'undefined' || (textValue && textValue.length <= 0)) return null;
        const filter = Container.get('profanityFilter');
        const cleanText = filter.clean(textValue);

        if (cleanText !== textValue) {
            return cleanText;
        }

        return null;
    }
}
