export const composedStylesGenerator = (...styleEntities) => theme => {
    
    const styles =  styleEntities.reduce((acc, entity) => {
        if (typeof entity === 'function') {
            return { ...acc, ...entity(theme) };
        }

        return { ...acc, ...entity };
    }, {});
    
    return styles;
};