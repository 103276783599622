import { IdStore } from 'dataStore/index';
import Constants from 'common/constants';
import { Publishers } from 'common/publishers';
import { NetSpendExclusionZipList } from 'common/netSpendExclusionZips';
import { AbTestHelper } from 'helpers';

class ABTestManager {
    genTest() {
        IdStore.removeIdForKey(Constants.ID_STORE_KEYS.AB_TEST);
        IdStore.removeIdForKey(Constants.ID_STORE_KEYS.AB_TEST_6);
        IdStore.removeIdForKey(Constants.ID_STORE_KEYS.AB_TEST_7);
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.BACK_INTERSTITIAL, '1');
        this.genRgNonBillableTest();
    }

    genRgNonBillableTest() {
        const source = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE);
        const campaign = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN);

        if (source === 'rg' || source === Publishers.RG) {
            IdStore.removeIdForKey(Constants.ID_STORE_KEYS.AB_TEST_6);
            const key = `${source}-${campaign}=1`;

            if (document.cookie.includes(key)) {
                IdStore.storeIdForKey(
                    Constants.ID_STORE_KEYS.AB_TEST_6,
                    'rg_nonbillable_skip_offers',
                );
            } else {
                document.cookie = `${key}; max-age=${Math.floor(
                    (3600000 - (new Date().getTime() % 3600000)) / 1000,
                )}`;
            }
        }
    }

    genNetSpendTest() {
        const zipCodeFed = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE);

        if (
            AbTestHelper.netSpendZipExclusionBoth() &&
            NetSpendExclusionZipList.includes(zipCodeFed)
        ) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.AB_TEST_8, 'null');
            IdStore.sync();
        }
    }

    getAbt30() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_30);
    }

    getAbt29() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_29);
    }

    getAbt28() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_28);
    }

    getAbt27() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_27);
    }

    getAbt26() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_26);
    }

    getAbt25() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_25);
    }

    getAbt24() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_24);
    }

    getAbt23() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_23);
    }

    getAbt22() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_22);
    }

    getAbt21() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_21);
    }

    getAbt20() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_20);
    }

    getAbt19() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_19);
    }

    getAbt18() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_18);
    }

    getAbt17() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_17);
    }

    getAbt16() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_16);
    }

    getAbt15() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_15);
    }

    getAbt14() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_14);
    }

    getAbt13() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_13);
    }

    getAbt12() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_12);
    }

    getAbt10() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_10);
    }

    getAbt11() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_11);
    }

    getAbt9() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_9);
    }

    getAbt7() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_7);
    }

    getAbt5() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_5);
    }

    getAbt6() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_6);
    }

    getAbt8() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_8);
    }

    getAbt4() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_4);
    }

    getAbt3() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_3);
    }

    getAbt2() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_2);
    }

    getAbt1() {
        return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.AB_TEST_1);
    }
}

const abManger = new ABTestManager();
export default abManger;
