import React from 'react';
import { Popover, makeStyles, List, ListItem, Typography } from '@material-ui/core';
import { ConfigProvider, FeatureMap } from 'providers/index';
import Utils from 'common/utils';
import { LABELS } from 'strings';
import ReactHtmlParser from 'react-html-parser';
import constants from 'flavors/newJobScanner/constants';

const MarketingPartnersPopOver = () => {
    const classes = useStyles();
    const managePartners = [];
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const tcpaMarketingPartnersFeature = ConfigProvider.Features.getFeature(
        FeatureMap.TCPAMarketingPartners,
    );
    const tcpMarketingPartnersExtraFeature = ConfigProvider.Features.getFeature(
        FeatureMap.TCPMarketingPartnersExtra,
    );

    const tcpaMarketingPartners =
        tcpaMarketingPartnersFeature && tcpaMarketingPartnersFeature.value
            ? tcpaMarketingPartnersFeature.value.split(',')
            : constants.MARKETING_PARTNERS_LIST;

    managePartners.push(...tcpaMarketingPartners);
    const tcpMarketingPartnersExtra =
        tcpMarketingPartnersExtraFeature && tcpMarketingPartnersExtraFeature.value
            ? tcpMarketingPartnersExtraFeature.value.split(',')
            : [];

    if (Utils.isLiveSurvey()) {
        managePartners.push(...tcpMarketingPartnersExtra);
    }

    const open = Boolean(anchorEl);

    return (
        <>
            <span
                className={classes.marketingPartners}
                color="primary"
                onClick={handleClick}
                variant="contained">
                {LABELS.MARKETING_PARTNERS}
            </span>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={handleClose}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                {
                    <List>
                        {managePartners.map(partner => (
                            <ListItem key={partner}>
                                <Typography variant="h3">
                                    {ReactHtmlParser(decodeURIComponent(partner))}
                                </Typography>
                            </ListItem>
                        ))}
                    </List>
                }
            </Popover>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    marketingPartners: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));

export default MarketingPartnersPopOver;
