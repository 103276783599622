import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'styles/common.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Utils from 'common/utils';

class LoaderView extends Component {
    render() {
        const { classes, loaderType } = this.props;
        let loaderText = '';

        if (false === Utils.isEmptyStr(this.props.loadingText)) {
            loaderText = this.props.loadingText;
        }

        return (
            <div className={`loader-wrapper ${classes[loaderType]}`}>
                <CircularProgress className={classes.progress} />
                <span className="loader-text">{loaderText}</span>
            </div>
        );
    }
}

LoaderView.defaultProps = {
    loaderType: 'pageLoader',
};

LoaderView.propTypes = {
    loadingText: PropTypes.string,
};

const styles = theme => ({
    pageLoader: {
        height: '85vh',
        width: '100%',
    },
    containerLoader: {
        height: 'auto',
        width: '100%',
    },
    inlineLoader: {
        margin: 0,
    },
});
export default withStyles(styles)(LoaderView);
