import React, { lazy } from 'react';
import TopBar from 'components/common/topBar';
import AppCommon from './appCommon';
import Utils from 'common/utils';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { GreenTheme } from 'flavors/layout-theme/themes/default';
import 'styles/app.css';
import { IdStore } from 'dataStore';
import Constants from 'common/constants';
import flavors, { getFlavor } from 'flavors.macro';
import PageVisibility from 'react-page-visibility';
import eventManager from 'eventManager/eventManager';
import AbManager from '../abManager/abTest';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { SessionHelper, AbTestHelper, StringHelper } from 'helpers';
import { ConfigProvider, FeatureMap } from 'providers';
import { RecaptchaChallenge } from 'components/common/recaptchaChallenge';
import { getCurrentPageScope, isScopeDataLoaded } from 'common/scopeUtils';
import LoaderView from 'components/loader';
import classNames from 'classnames';

import JobSearch from '../components/gratification/jobsSearch';

flavors();

const uuidv4 = require('uuid/v4');
const publicIp = require('public-ip');

const flavor = getFlavor('layout-theme');

const browserSpecificCssCls =
    Utils.getIOSVersion() && Utils.browserType() === 'safari' ? 'ios-safari' : '';
const Footer = lazy(() => import(`components/common/footer`));
const DynamicHeader = lazy(() => import('components/dynamicXMLHeader'));
const ExitInterstitial = lazy(() => import('../components/common/exitInterstitial'));

const parseAndStoreDetails = details => {
    try {
        const decodeStr = Utils.decodeFromBase64(StringHelper.decodeURLSafeBase64(details));

        if (Utils.isEmptyStr(decodeStr)) return;
        const det = JSON.parse(decodeStr);
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.JOB_DETAILS, JSON.stringify(det));
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.DYNAMIC_XML_FEED, 1);

        return det;
    } catch (e) {
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.DYNAMIC_XML_FEED, 0);
        console.log('Parsing error', e);

        return false;
    }
};

const allowedRoutesForDynamicHeader = [
    Constants.PAGE_ENDPOINTS.LANDING,
    /*
     * Constants.PAGE_ENDPOINTS.SURVEY,
     * Constants.PAGE_ENDPOINTS.ADS
     */
];

const allowedRoutesForListingsBg = [
    Constants.PAGE_ENDPOINTS.LANDING,
    Constants.PAGE_ENDPOINTS.SURVEY,
    Constants.PAGE_ENDPOINTS.ADS,
    Constants.PAGE_ENDPOINTS.EMAIL_PREFERENCE,
];

const ListingsBgFlow = props => {
    const {
        browserSpecificCssCls,
        isMobile,
        bottomAlignedClass,
        staticImagesClass,
        isBottomAlignedInterstitial,
    } = props;

    return (
        <div className="listings-bg-container">
            <JobSearch sendEvents={true} showAds={false} />
            <Dialog
                BackdropProps={{
                    classes: {
                        root: classNames({
                            'dialog-paper-tint':
                                AbTestHelper.staticImgListingsBgTest() &&
                                [Constants.PAGE_ENDPOINTS.LANDING].includes(
                                    window.location.pathname,
                                ),
                        }),
                    },
                }}
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                className={`listings-bg-dialog ${
                    AbTestHelper.listingsBGModelChangeDesktop() && 'listings-bg-dialog-model-change'
                } ${browserSpecificCssCls} ${
                    isMobile ? 'mb' : 'dsk'
                } listings-bg-offer-path ${bottomAlignedClass} ${staticImagesClass} ${
                    AbTestHelper.emailPreferencePageTest() && 'email-preference-dialog'
                }`}
                classes={{
                    paper: classNames({
                        'dialog-paper-rounded': AbTestHelper.regThreeUIV2(),
                    }),
                }}
                open={true}>
                <div className={`non-fixed-container`}>
                    <DialogContent
                        style={
                            isBottomAlignedInterstitial
                                ? {
                                      borderRadius: '12px 12px 0 0',
                                  }
                                : {}
                        }>
                        {props.children}
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    );
};

const ControlFlow = props => {
    const { hocProps } = props;

    return (
        <div
            className={`non-fixed-container ${
                hocProps && hocProps.scrollCls ? hocProps.scrollCls : ''
            }`}>
            {props.children}
            <Footer showSubFooter={hocProps && hocProps.showSubFooter} />
        </div>
    );
};

/**
 * This is a HOC that wraps across all the apps at the highest level.
 * All common operations to be performed across all the pages needs to be
 * collected here.
 * @param {React.Component} AppComponent
 */

export default function CommonAppWrapper(AppComponent, hocProps) {
    return class CommonAppWrapperHOC extends React.Component {
        constructor() {
            super();
            /*
             * These are being queued since they need to be performed
             * only once in the lifetime of the app.
             */
            this.onMountQueue = [AppCommon.fireOnAppMounted];
            this.onRenderQueue = [AppCommon.fireOnAppRendered];
            this.getEventType = this.getEventType.bind(this);
            this.onPopState = this.onPopState.bind(this);
            this.onUnload = this.onUnload.bind(this);
            this.keyword = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_TERM);
            this.reCaptchaTryCount = 0;
            let jobDetails = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.JOB_DETAILS);

            if (!Utils.isEmptyStr(jobDetails)) {
                parseAndStoreDetails(jobDetails);
            }
            AppCommon.sessionStoreUpdate();
            SessionHelper.logVisit();
            this.state = {
                showRecaptcha: Utils.getCookie('GRC_CHALLENGE') === 'failed' ? true : false,
                loadingConfig: this.initScopeConfig(),
            };
        }

        componentWillMount() {
            let uuid = uuidv4().replace(/-/g, '');
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.UUID, uuid);
        }

        initScopeConfig() {
            if (!isScopeDataLoaded() && getCurrentPageScope()) {
                ConfigProvider.fetchScopeConfig([getCurrentPageScope()]).finally(() => {
                    this.setState({
                        loadingConfig: false,
                    });
                });

                return true;
            }

            return false;
        }

        onUnload(event) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.PAGE_REFRESH, true);
        }

        onPopState(event) {
            const eventType = this.getEventType();
            eventManager.sendEvent(
                Constants.EVENT.PAGE_BACK,
                { responsedata: window.location.href },
                eventType,
            );
        }

        handleReCaptchaVisibility = () => {
            const reCaptchaElement = document.querySelector('.grecaptcha-badge');
            const showCaptcha =
                !AbTestHelper.showListingsBgXMLTestV2() &&
                (!AbTestHelper.listingsInBgLandingFlow() || AbTestHelper.upwardRevampTest());

            if (reCaptchaElement && showCaptcha) {
                reCaptchaElement.style && (reCaptchaElement.style.visibility = 'visible');
            }
        };

        componentDidMount() {
            if (window.isLh) return;

            this.processCommonInitialization();
            window.addEventListener('beforeunload', this.onUnload);
            window.addEventListener('popstate', this.onPopState);
            const pgRefresh = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PAGE_REFRESH);

            if (pgRefresh === 'true') {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.PAGE_REFRESH, false);
                const eventType = this.getEventType();
                eventManager.sendEvent(Constants.EVENT.PAGE_REFRESH, {}, eventType);
            }
            document.addEventListener(
                Constants.DOM_EVENTS.RE_CAPTCHA_LOAD,
                this.handleReCaptchaVisibility,
            );
        }

        componentDidUpdate() {
            this.handleReCaptchaVisibility();
        }

        componentWillUnmount() {
            window.removeEventListener('beforeunload', this.onUnload);
            window.removeEventListener('popstate', this.onUnload);
        }

        getEventType() {
            const { EVENT_TYPE, PAGE_ENDPOINTS } = Constants;
            let eventType = null;
            const { pathname } = this.props.location;

            switch (pathname) {
                case PAGE_ENDPOINTS.LANDING:
                    eventType = EVENT_TYPE.LANDING;
                    break;
                case PAGE_ENDPOINTS.SURVEY:
                    eventType = EVENT_TYPE.SURVEY;
                    break;
                case PAGE_ENDPOINTS.ADS:
                    eventType = EVENT_TYPE.ADS;
                    break;
                case PAGE_ENDPOINTS.GRATIFICATION:
                    eventType = EVENT_TYPE.GRAITIFICATION;
                    break;
                default:
                    eventType = null;
            }

            return eventType;
        }

        async processCommonInitialization() {
            if (window.isLh) return;
            // Validate the component
            this.isValidRoute = this.validateRoute(this.props.match.path);

            if (true === this.isValidRoute) {
                // Store the flavor and lander-id from the url
                let match = this.props.match;

                if (false === Utils.isNull(match)) {
                    let params = match.params;

                    if (false === Utils.isNull(params)) {
                        // Fetch the lander-id and the flavor ids
                        let landerName = params[Constants.PATH_ARGS.LANDER_NAME];

                        if (false === Utils.isEmptyStr(landerName)) {
                            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.LANDER_NAME, landerName);
                        }

                        const etid = Utils.getValForKeyFromCurrentUrl(
                            Constants.URL_ARGS.EVERFLOW_TRANSACTION_ID,
                        );

                        if (false === Utils.isEmptyStr(etid)) {
                            IdStore.storeIdForKey(
                                Constants.ID_STORE_KEYS.EVERFLOW_TRANSACTION_ID,
                                etid,
                            );
                        }

                        // Store the flavor-name
                        let flavorName = getFlavor('layout-theme');

                        if (false === Utils.isEmptyStr(flavorName)) {
                            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.FLAVOR_NAME, flavorName);
                        }

                        const lander = ConfigProvider.Lander.getLander();

                        if (lander && lander.id && lander.name) {
                            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.LANDER_ID, lander.id);
                            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.LANDER_NAME, lander.name);
                        }

                        let flavorPathName = params[Constants.PATH_ARGS.LANDER_PATH_NAME];

                        if (false === Utils.isEmptyStr(flavorPathName)) {
                            IdStore.storeIdForKey(
                                Constants.ID_STORE_KEYS.LANDER_PATH_NAME,
                                flavorPathName,
                            );
                        }
                    }
                }
            }
            this.getPublicIP();

            setTimeout(() => IdStore.setSync(), 10000);
        }

        generateCsId() {
            if (window.isLh) {
                return `csid_fixed_for_test`;
            }

            return `${Utils.generateUUID()}`.replace(/-/g, '');
        }

        componentWillReceiveProps(newProps) {
            if (window.isLh) return;
        }

        async getPublicIP() {
            try {
                if (!Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PUBLIC_IP))) {
                    return;
                }
                let ipv4 = await publicIp.v4();
                console.log(ipv4);
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.PUBLIC_IP, ipv4);
            } catch (err) {
                console.log(err);
            }
        }

        fetchDynamicHeaderDetails() {
            const showheader = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.DYNAMIC_XML_FEED);

            if (showheader && allowedRoutesForDynamicHeader.includes(this.props.match.path)) {
                try {
                    let details = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.JOB_DETAILS);

                    if (!Utils.isEmptyStr(details)) {
                        details = JSON.parse(details);

                        if (
                            !details ||
                            !details.Location ||
                            !details.Title ||
                            !details.JobProvider ||
                            !details.Description
                        )
                            return false;

                        return details;
                    }
                } catch (e) {
                    console.log('error while parsing data', e);

                    return false;
                }
            }

            return false;
        }

        fetchTheme = () => {
            return GreenTheme;
        };

        exitInterstitial = () => {
            const sid = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.SURVEY_ID);
            const isPreviewMode = !!Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PREVIEW);
            const disableExitInterstitialTest =
                Constants.PAGE_ENDPOINTS.LANDING === this.props.match.path &&
                AbTestHelper.disableExitInterstitial();

            return sid !== '46' &&
                !(
                    AbTestHelper.pushOptInPlacementTest() &&
                    Constants.PAGE_ENDPOINTS.LANDING === this.props.match.path
                ) &&
                !isPreviewMode &&
                !disableExitInterstitialTest &&
                !AbTestHelper.removeExitInterstitial() ? (
                <ExitInterstitial
                    isMobile={Utils.checkMobileDevice()}
                    location={this.props.location}
                />
            ) : (
                <></>
            );
        };

        onRecaptchaChallengeRendered = () => {
            eventManager.sendEvent(
                Constants.EVENT.RECAPTCHA_CHALLENGE_SHOWN,
                {},
                Constants.EVENT_TYPE.ADS,
            );
        };

        recaptchaSuccessfulResponse = response => {
            eventManager.sendEvent(
                Constants.EVENT.RECAPTCHA_CHALLENGE_SUCCESS,
                {},
                Constants.EVENT_TYPE.ADS,
            );
            this.setState({
                showRecaptcha: false,
            });
        };

        render() {
            this.fireQueue(this.onRenderQueue);

            /*
             * If the component is invalid, redirect it to the default-redirect path
             * if (false === this.isValidRoute) {
             *     console.log("COMP_VALID: Got invalid component!", AppComponent.name, "Redirecting - ",
             *         RoutePrefixHandler.getDefaultRedirectPathForLocation(this.props.location)
             *     )
             *     return (
             *         <PageVisibility onChange={this.handleVisibilityChange}>
             *             <Redirect
             *                 to={{
             *                     pathname: RoutePrefixHandler.getDefaultRedirectPathForLocation(this.props.location),
             *                     search: this.props.location.search,
             *                 }}
             *             />
             *         </PageVisibility>
             *     )
             * }
             */
            const details = this.fetchDynamicHeaderDetails();
            const { pathname, hash } = this.props.location;
            const campaign = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN);
            const channel = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_MEDIUM);
            const isStaticBgFlow =
                IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_SOURCE) === 'xup_static';
            const isSemiDynamic =
                campaign &&
                campaign.includes('nationwide_') &&
                ('dxml_static' === channel || AbTestHelper.isDXMLMedium());
            const isUpwardSemiDynamicLander =
                isSemiDynamic && Utils.getUpwardSemiDynamicLogos(this.keyword);
            const isMobile = Utils.checkMobileDevice();
            const whiteBg =
                !(
                    AbTestHelper.verizonLander() &&
                    Utils.checkMobileDevice() &&
                    pathname.includes('landing') &&
                    hash === '#0'
                ) &&
                (AbTestHelper.isListingsInBGWithWhiteBGTest() ||
                    AbTestHelper.isShowPremiumJobProgressBar() ||
                    AbTestHelper.isFulfillmentDesignPhaseTwo() ||
                    AbTestHelper.googleKWSelectorLandingPage() ||
                    (details &&
                        !(isUpwardSemiDynamicLander && !isStaticBgFlow) &&
                        pathname.includes('landing') &&
                        hash === '#0') ||
                    IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.LANDING_FLOW) ===
                        'default_flow' ||
                    (isUpwardSemiDynamicLander && isStaticBgFlow) ||
                    AbTestHelper.allInboxSMSLander() ||
                    AbTestHelper.zipAndEmailLandingTest() ||
                    AbTestHelper.brandedOfferAmazonTest() ||
                    AbTestHelper.kwBenefitsLander() ||
                    AbTestHelper.brandedOfferFedExTest() ||
                    AbTestHelper.brandedOfferTest() ||
                    AbTestHelper.warmUpQuestionTest() ||
                    !AbTestHelper.listingsInBgFlow())
                    ? 'white-bg'
                    : '';
            const FbBgFlowTest = ConfigProvider.Features.getFeature(FeatureMap.FbBgFlow);
            const enableFbBgFlow =
                FbBgFlowTest && FbBgFlowTest.value === 'fb-ui' && !pathname.includes('listings');
            const enableListingsBgFlow = AbTestHelper.listingsInBgFlow();
            const noSkipToOffersListingsInBg = AbTestHelper.noSkipToOffersListingsInBg();
            const showAmazonJobsImage =
                AbTestHelper.showListingsBgXMLTestV2() &&
                [
                    Constants.PAGE_ENDPOINTS.LANDING,
                    Constants.PAGE_ENDPOINTS.SURVEY,
                    Constants.PAGE_ENDPOINTS.ADS,
                ].includes(this.props.match.path);

            const staticImagesClass =
                AbTestHelper.rgStaticKwImages() && hash === '#1' && pathname.includes('landing')
                    ? 'rg-static-images'
                    : '';

            const isBottomAlignedInterstitial =
                AbTestHelper.isUpwardBottomAlignedInterstitial() &&
                this.props.location &&
                this.props.location.pathname === Constants.PAGE_ENDPOINTS.LANDING &&
                Utils.checkMobileDevice();
            const bottomAlignedClass = isBottomAlignedInterstitial
                ? 'bottom-aligned-interstitial'
                : '';

            const verizonLanderMobile =
                AbTestHelper.verizonLander() &&
                Utils.checkMobileDevice() &&
                pathname.includes('landing') &&
                hash === '#0';

            if (this.state.loadingConfig) {
                return <LoaderView />;
            }

            return (
                <PageVisibility onChange={this.handleVisibilityChange}>
                    <React.Fragment>
                        <MuiThemeProvider theme={this.fetchTheme()}>
                            <div
                                className={`root-app-wrapper ${flavor} ${
                                    AbManager.getAbt3() === 'old_audience_flow' ? 'old-audi-tp' : ''
                                }${whiteBg} ${verizonLanderMobile && 'verizon-grey'} ${
                                    enableFbBgFlow ? 'fb-bg-main-warpper' : ''
                                } ${browserSpecificCssCls}`}>
                                <div
                                    className={`non-fixed-content-wrapper ${
                                        hocProps && hocProps.lndScrollCls
                                            ? hocProps.lndScrollCls
                                            : ''
                                    }`}>
                                    {details &&
                                    (isUpwardSemiDynamicLander || isSemiDynamic) &&
                                    !isStaticBgFlow &&
                                    !noSkipToOffersListingsInBg ? (
                                        <DynamicHeader details={details} />
                                    ) : (
                                        !(
                                            showAmazonJobsImage ||
                                            AbTestHelper.staticImgListingsBgTest()
                                        ) && <TopBar enableFbBgFlow={enableFbBgFlow} />
                                    )}
                                    {this.exitInterstitial()}
                                    {enableListingsBgFlow &&
                                    allowedRoutesForListingsBg.includes(this.props.match.path) ? (
                                        this.props.match.path === Constants.PAGE_ENDPOINTS.ADS &&
                                        this.state.showRecaptcha ? (
                                            <RecaptchaChallenge
                                                onMount={this.onRecaptchaChallengeRendered}
                                                recaptchaSuccessfulResponse={
                                                    this.recaptchaSuccessfulResponse
                                                }
                                            />
                                        ) : (AbTestHelper.brandedOfferAmazonTest() ||
                                              AbTestHelper.brandedOfferFedExTest() ||
                                              AbTestHelper.brandedOfferTest() ||
                                              AbTestHelper.kwBenefitsLander()) &&
                                          this.props.location.pathname ===
                                              Constants.PAGE_ENDPOINTS.LANDING &&
                                          this.props.location.hash === '#0' ? (
                                            <ControlFlow hocProps={hocProps}>
                                                <AppComponent
                                                    dynFeedDetails={details}
                                                    fireQueueOnMount={this.fireQueueOnMount.bind(
                                                        this,
                                                    )}
                                                    key={this.props.location.search}
                                                />
                                            </ControlFlow>
                                        ) : (
                                            <ListingsBgFlow
                                                bottomAlignedClass={bottomAlignedClass}
                                                browserSpecificCssCls={browserSpecificCssCls}
                                                isBottomAlignedInterstitial={
                                                    isBottomAlignedInterstitial
                                                }
                                                isMobile={isMobile}
                                                staticImagesClass={staticImagesClass}>
                                                <AppComponent
                                                    fireQueueOnMount={this.fireQueueOnMount.bind(
                                                        this,
                                                    )}
                                                    key={this.props.location.search}
                                                />
                                            </ListingsBgFlow>
                                        )
                                    ) : (
                                        <ControlFlow hocProps={hocProps}>
                                            <AppComponent
                                                dynFeedDetails={details}
                                                fireQueueOnMount={this.fireQueueOnMount.bind(this)}
                                                key={this.props.location.search}
                                            />
                                        </ControlFlow>
                                    )}
                                </div>
                            </div>
                        </MuiThemeProvider>
                    </React.Fragment>
                </PageVisibility>
            );
        }

        handleVisibilityChange = isVisible => {
            if (isVisible) {
                eventManager.fireOnVisible();
            }

            if (
                window.location.pathname === Constants.PAGE_ENDPOINTS.ADS &&
                Utils.isFacebookInAppBrowser()
            ) {
                const iframeSrcDoc = IdStore.fetchIdForKey(
                    Constants.ID_STORE_KEYS.OFFER_SRC_DOC_FACEBOOK,
                );
                let iframe = document.getElementById('offer-path-iframe');
                iframe.srcdoc = iframeSrcDoc;
            }
        };

        // PRAGMA MARK :- Helpers

        /**
         * Pops from the front of the queue and executes them.
         * @param {list} queue
         */
        async fireQueue(queue) {
            if (Utils.isNull(queue)) {
                return;
            }

            let qLen = queue.length;

            for (let i = 0; i < qLen; i++) {
                let method = queue.shift();

                if (false === Utils.isNull(method)) {
                    method();
                }
            }
        }

        async fireQueueOnMount() {
            this.fireQueue(this.onMountQueue);
        }

        /**
         * Returns if the component is valid for the current route
         * @param {Component Function} Component
         * @returns {bool}
         */
        validateRoute(routePath) {
            if (true === Utils.isNull(routePath)) {
                return false;
            }

            // Remove everything but the last entry in the route
            let routesList = routePath.split('/');
            let route = `/${routesList[routesList.length - 1]}`;

            console.log('COMP_VALID: Route name - ', route);

            // Add the logic for validating the route
            let isValid = false;

            switch (route) {
                case Constants.PAGE_ENDPOINTS.LANDING:
                    isValid = true;
                    break;
                case Constants.PAGE_ENDPOINTS.SURVEY:
                    isValid = this.isAppReadyForSurvey();
                    break;
                case Constants.PAGE_ENDPOINTS.ADS:
                    isValid = this.isAppReadyForAds();
                    break;
                case Constants.PAGE_ENDPOINTS.GRATIFICATION:
                    isValid = this.isAppReadyForGratification();
                    break;
                default:
                    console.log('COMP_VALID: Unknown route - ', route);
            }

            if (false === isValid) {
                console.log('COMP_VALID: Got invalid for ', route);
            }

            return isValid;
        }

        isAppReadyForSurvey() {
            return this.isCustomSessionPresent();
        }

        isAppReadyForAds() {
            return this.isCustomSessionPresent();
        }

        isAppReadyForGratification() {
            return this.isCustomSessionPresent() || window.location.search.search('op=1') > 0;
        }

        isCustomSessionPresent() {
            let sessionVal = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CS_ID);

            return false === Utils.isEmptyStr(sessionVal);
        }
    };
}
