import Utils from 'common/utils';
import TrackingMacroManager from './trackingMacroManager';
import Constants from 'common/constants';
import EventManager from 'eventManager/eventManager';
import UrlManager from 'common/urlManager';

class OfferManager {
    /**
     * Performs the following tasks on the offer-path loading completion event -
     * - Fires tracking pixels present in the .trackingPixels list
     * - If the revenue type in the offer-data is CPM, send the revenue event
     */
    processImpressionForOfferData(offerData) {
        if (Utils.isNull(offerData)) {
            console.log('ADS: cannot process impressions since offer-data is empty!');

            return;
        }

        // Fire the tracking impression pixels
        console.log('ADS: Firing impression pixels');
        this.fireTrackingPixelsList(offerData.trackingPixels);

        // Send revenue event only on CPM
        if (offerData[Constants.ADS.RESP_KEYS.REVENUE_TYPE] === Constants.ADS.REVENUE_TYPE.CPM) {
            this.sendRevenueEventForOfferData(offerData);
        }
    }

    /**
     * Performs the following tasks on the offer-path click event -
     * - Send the ad-clicked event
     * - Fires tracking pixels present in the .onClickTrackingPixels list
     * - If the revenue type in the offer-data is CPC, send the revenue event
     */
    processAdClickedForOfferData(offerData, adUrl, extraData = {}) {
        if (Utils.isNull(offerData)) {
            console.log('ADS: cannot process ad-clicked since offer-data is empty!');

            return;
        }

        // Send event
        console.log('ADS: sending event ad-click');

        if (offerData.onClickTrackingPixels && offerData.onClickTrackingPixels.length > 0) {
            const adsData = this.getAdsData(offerData);
            const eventUrl = EventManager.createAndGetEventUrl(
                Constants.EVENT.MAX_CLICK,
                adsData,
                Constants.EVENT_TYPE.ADS,
            );
            const clickParams = {
                url: encodeURIComponent(offerData.onClickTrackingPixels[0]),
                eventUrl: encodeURIComponent(eventUrl),
            };
            const img = new Image();
            img.src = `${UrlManager.getAdClickUrl()}?${Utils.encodeObjectToUrlArgs(clickParams)}`;
        }
        console.log('[ADS: Url', adUrl);
        this.sendEventForOfferData(
            Constants.EVENT.AD_CLICK,
            adUrl ? { ...offerData, job_redirect_url: adUrl } : offerData,
            undefined,
            extraData,
        );
        this.sendEventForOfferData(Constants.EVENT.AD_PROG, offerData, true);

        // Send revenue event only on CPC
        if (offerData[Constants.ADS.RESP_KEYS.REVENUE_TYPE] === Constants.ADS.REVENUE_TYPE.CPC) {
            this.sendRevenueEventForOfferData(offerData);
        }

        // Fire logging pixels
        console.log('ADS: Firing on-click pixels');
        // this.fireTrackingPixelsList(offerData.onClickTrackingPixels)
    }

    /**
     * Fire the tracking-pixels for the given list
     * @param {*} trackingPixels
     */
    fireTrackingPixelsList(trackingPixels) {
        if (Utils.isNull(trackingPixels) || trackingPixels.length === 0) {
            return;
        }

        trackingPixels.forEach(pixel => {
            let url = TrackingMacroManager.processMacrosInUrl(pixel);
            console.log('ADS: pixel-url - ', url);
            let img = new Image();
            img.src = url;
        });
    }

    /**
     * Sends the event with the given event-name, after performing the required manipulations on
     * the event data.
     * @param {string} eventName
     * @param {object} offerData
     */
    sendEventForOfferData(eventName, offerData, fireWhenVisible, extraData = {}) {
        console.log('OFFER_EVENT: Sending offer-path event');

        // Fetch the data to be sent
        const adData = this.getAdsData(offerData, extraData);

        console.log(`ADS: Sending ${eventName} with data -`, adData);

        if (fireWhenVisible) {
            EventManager.sendEventWhenVisible(eventName, adData, Constants.EVENT_TYPE.ADS);
        } else {
            EventManager.sendEvent(eventName, adData, Constants.EVENT_TYPE.ADS);
        }
    }

    getAdsData(offerData, extraData) {
        let adData = {};

        // These are the only keys that are going to be sent to the event -
        let adKeysMap = {
            [Constants.ADS.RESP_KEYS.OFFERPATH_ID]: Constants.ADS.EVENT_BODY_KEYS.OFFERPATH_ID,
            [Constants.ADS.RESP_KEYS.AD_ID]: Constants.ADS.EVENT_BODY_KEYS.AD_ID,
            [Constants.ADS.RESP_KEYS.ADVERTISER_NAME]:
                Constants.ADS.EVENT_BODY_KEYS.ADVERTISER_NAME,
            [Constants.ADS.RESP_KEYS.CAMPAIGN_ID]: Constants.ADS.EVENT_BODY_KEYS.CAMPAIGN_ID,
            [Constants.ADS.RESP_KEYS.CAMPAIGN_NAME]: Constants.ADS.EVENT_BODY_KEYS.CAMPAIGN_NAME,
            [Constants.ADS.RESP_KEYS.CREATIVE_ID]: Constants.ADS.EVENT_BODY_KEYS.CREATIVE_ID,
            [Constants.ADS.RESP_KEYS.REVENUE_TYPE]: Constants.ADS.EVENT_BODY_KEYS.REVENUE_TYPE,
            [Constants.ADS.RESP_KEYS.REVENUE]: Constants.ADS.EVENT_BODY_KEYS.REVENUE,
            [Constants.ADS.EVENT_BODY_KEYS.EFFECTIVE_REVENUE]:
                Constants.ADS.EVENT_BODY_KEYS.EFFECTIVE_REVENUE,
            error: 'error',
            uuid: 'uuid',
            spotno: 'spotno',
            adTitle: 'adt',
            adSubTitle: 'adst',
            adSubTitle2: 'adst2',
            cpa: 'cpa',
            responseData: 'responsedata',
            offerid: 'offerid',
            max_deal_id: 'max_deal_id',
            abt_exp: 'abt_exp',
            job_redirect_url: 'job_redirect_url',
            cpc: 'cpc',
            max_filters: 'max_filters',
        };

        for (let key in adKeysMap) {
            if (false === adKeysMap.hasOwnProperty(key)) {
                continue;
            }
            let replacementKey = adKeysMap[key];
            let val = offerData[key];

            if (false === Utils.isEmptyStr(val)) {
                adData[replacementKey] = val;
            }
        }

        return { ...adData, ...extraData };
    }

    /**
     * Performs the necessary revenue calculations and send the revenue event
     * @param {object} offerData
     */
    sendRevenueEventForOfferData(offerData) {
        if (Utils.isNull(offerData)) {
            console.log(
                'ADS: Could not send the revenue-event since the offer-path data is empty!',
            );

            return;
        }

        let revType = offerData[Constants.ADS.RESP_KEYS.REVENUE_TYPE];

        if (Utils.isEmptyStr(revType)) {
            console.log('ADS: Could not send the revenue-event since the revenue type is empty!');

            return;
        }

        let revenueVal = offerData[Constants.ADS.RESP_KEYS.REVENUE];

        if (false === Utils.isNull(revenueVal) && typeof revenueVal !== 'number') {
            revenueVal = 0;
        }

        // Divide the cpm value by 1000
        if (revType === Constants.ADS.REVENUE_TYPE.CPM) {
            revenueVal = revenueVal / 1000;
        }

        // Cloning it to not make unnecessary changes on the original offer-path-data
        let clonedOfferData = JSON.parse(JSON.stringify(offerData));
        clonedOfferData[Constants.ADS.EVENT_BODY_KEYS.EFFECTIVE_REVENUE] = revenueVal;
        offerData = clonedOfferData;

        this.sendEventForOfferData(Constants.EVENT.REVENUE, offerData);
    }
}

let offerManager = new OfferManager();
export default offerManager;
