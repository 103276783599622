import React from 'react';
import PropTypes from 'prop-types';
import Utils from 'common/utils';
import { AbTestHelper } from 'helpers';
import TargetingManager from 'targetingManager';
import * as Constants from 'common/constants';
import { IdStore } from 'dataStore/index';
import AdsDataSource from 'datasource/adsDataSource';
import LoaderView from 'components/loader/index';

const FormHandler = React.lazy(() => import('./formHandler'));
const ListingsBgFormHandler = React.lazy(() => import('./listingsBgFormHandler'));
export default class LandingView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reqErr: null,
        };
        this.getQSAutoAdUnit();
        this.getQSHealthAdUnit();
    }

    getQSAutoAdUnit = async () => {
        try {
            const targetingParams = TargetingManager.fetchTargetingParams();
            const adUnitId = Utils.isTestEnv()
                ? Constants.AD_UNIT_ID.QS_AUTO.STAG
                : Constants.AD_UNIT_ID.QS_AUTO.PROD;
            targetingParams.extras['survey_car_insurance'] = '1';
            const adsUnitData = await AdsDataSource.fetchAdsForAdUnit(targetingParams, adUnitId);

            if (adsUnitData && adsUnitData.ad && !Utils.isEmptyObj(adsUnitData.ad)) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.QS_AUTO, 'success');
            }
        } catch (err) {
            console.error(`Error while fetching AdUnit for QS Auto - `, err);
        }
    };

    getQSHealthAdUnit = async () => {
        try {
            const targetingParams = TargetingManager.fetchTargetingParams();
            const adUnitId = Utils.isTestEnv()
                ? Constants.AD_UNIT_ID.QS_HEALTH.STAG
                : Constants.AD_UNIT_ID.QS_HEALTH.PROD;
            targetingParams.extras['survey_reduce_health_cost'] = '1';
            const adsUnitData = await AdsDataSource.fetchAdsForAdUnit(targetingParams, adUnitId);

            if (adsUnitData && adsUnitData.ad && !Utils.isEmptyObj(adsUnitData.ad)) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.QS_HEALTH, 'success');
            }
        } catch (err) {
            console.error(`Error while fetching AdUnit for QS Health - `, err);
        }
    };

    render() {
        if (AbTestHelper.listingsInBgLandingFlow() || AbTestHelper.showListingsBgXMLTestV2()) {
            return (
                <React.Suspense fallback={<LoaderView />}>
                    <ListingsBgFormHandler
                        dynFeedDetails={this.props.dynFeedDetails}
                        formCompletedCb={this.handleFormCompletion.bind(this)}
                        history={this.props.history}
                        registerUser={this.props.registerUser}
                        selectedSkipToTabAdUnit={this.state.selectedSkipToTabAdUnit}
                        skipFormCompletionCb={this.handleFormSkip.bind(this)}
                        submitErrCb={this.state.reqErr}
                    />
                </React.Suspense>
            );
        }

        return (
            <FormHandler
                dynFeedDetails={this.props.dynFeedDetails}
                formCompletedCb={this.handleFormCompletion.bind(this)}
                history={this.props.history}
                registerUser={this.props.registerUser}
                selectedSkipToTabAdUnit={this.state.selectedSkipToTabAdUnit}
                skipFormCompletionCb={this.handleFormSkip.bind(this)}
                submitErrCb={this.state.reqErr}
            />
        );
    }

    handleFormCompletion(userDataObj) {
        this.props.pageCompleteCb(userDataObj, err => {
            if (!Utils.isNull(err)) {
                console.log('ERR: Got error when sending user-details - ', err);
                /*
                 * this.setState({
                 *     reqErr: err,
                 * });
                 */
            }
        });
    }

    handleFormSkip(userDataObj) {
        this.props.skipUserDetailsCb(userDataObj);
    }

    /**
     * This emulated user-content from the html.
     */
    getDefaultUserContent() {
        let randomVal = Utils.getRandomInt(100000);

        return {
            email: `dummy-user${randomVal}@email.com`,
            first_name: 'dummy-first-name',
            last_name: 'dummy-last-name',
            dob: '11/09/2018',
            gender: 1,
            interests: {
                cars: true,
            },
            zip_code: '411021',
            phone_number: '(123)-123-1234',
        };
    }
}

LandingView.propTypes = {
    pageCompleteCb: PropTypes.func.isRequired,
    registerUser: PropTypes.func.isRequired,
    skipUserDetailsCb: PropTypes.func.isRequired,
};
